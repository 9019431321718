<template>
  <div class="expand-holder" v-if="relatedLexemes.length > 0 > 0">
    <p  v-bind:class="expanded ? 'active' : ''" class="expand-search f black" @click="expanded = !expanded"><span
        v-if="!hebrew">Include synonyms</span> <span v-if="hebrew">כלול מילים נרדפות</span></p>
    <transition name="slide-fade">
      <ul v-if="expanded" class="expand-area">
        <li class="expand-item" v-for="related in relatedLexemes" :key="related._id">
          <label class="switch"
                 :class="{'no-results': related.count === 0}">
            <input type="checkbox"
                   :checked="relatedLexemesSelected[related.relatedLexeme]"
                   @change="$emit('changeRelated', related.relatedLexeme)"
            >
            <span class="slider round"></span>
            <p class="switch-text f-narkis">{{related.relatedBaseText}}</p>
          </label>
        </li>
      </ul>
    </transition>
  </div>
</template>
<script>
import sidebarMixin from '../../mixins/sidebarMixin'

export default {
  name: 'SynonymsMobile',
  mixins: [sidebarMixin],
  props: ['relatedLexemes', 'relatedLexemesSelected'],
  data () {
    return {
      expanded: false
    }
  }
}
</script>
<style scoped>
   .expand-search{
    margin: 0;
    position: relative;
  }
   .expand-search:after{
    width: 10px;
    height: 18px;
    position: absolute;
    top:50%;
    right: 1px;
    content: '';
    margin: -5px 0 0;
    background: url("../../assets/Triangle-down.png") no-repeat;
  }
   .expand-search.active:after{
    background: url("../../assets/Triangle-up.png") no-repeat;
  }
   .he .expand-search:after{
    right:auto;
    left: 1px;
    background: url("../../assets/Triangle-down.png") no-repeat;
  }
  .he .expand-search.active:after{
    background: url("../../assets/Triangle-up.png") no-repeat;
  }
 .expand-holder{
    margin: -1px 0 0;
    padding: 20px 14px;
    min-height: 64px;
    display: block;
    position: relative;
    background-color: #ffffff;
    border: solid 1px #c8c8c8;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
  }
  .expand-holder:before{
    top: -2px;
    left: 0;
    right: 0;
    content: '';
    width: 100%;
    height: 4px;
    position: absolute;
    background-color: #ffffff;
  }
   .expand-holder:after{
    top: 0;
    left: 4%;
    right: 4%;
    content: '';
    width: 92%;
    height: 1px;
    position: absolute;
    background-color: #c8c8c8;
  }
   .expand-area{
    padding: 0;
    margin: 20px 0 0 !important;
    list-style: none;
  }
   .expand-item {
     margin-bottom: 0 !important;
     padding: 8px 0;
   }
   .switch {
     position: relative;
     display: block;
     width: 100%;
     margin: 0;
     font-weight: 400;
     padding-left: 60px;
     padding-top: 3px;
   }
   .he .switch{
     padding-left: 0;
     padding-right: 60px;
    padding-top: 3px;
  }
   .switch-text{
     margin: 0;
     color: #333;
     font-size: 18px;
     line-height: 21px;
   }
   .switch input {
     display:none;
   }
   .slider {
     position: absolute;
     cursor: pointer;
     top: 0;
     left: 1px;
     bottom: 0;
     -webkit-transition: .4s;
     transition: .4s;
     float: right;
     width: 44px;
     height: 26px;
     border-radius: 8px;
     background-color: #cdcdcd;
   }
   .he .slider {
     left: auto;
     right: 1px;
     background-color: #b5b5b5;
   }
   .slider:before {
     position: absolute;
     content: "";
     -webkit-transition: .4s;
     transition: .4s;
     width: 22px;
     height: 22px;
     left: 2.5px;
     bottom: 2.5px;
     box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px 0 rgba(0, 0, 0, 0.05);
     background-color: #ffffff;
     border: solid 0.5px rgba(0, 0, 0, 0.1);
   }
   .he .slider:before {
     left: auto;
     right: 2px;
   }
   input:checked + .slider {
     background-color: #007ee5;
   }
   .he input:checked + .slider {
     background-color: #007ee5;
   }
   input:focus + .slider {
     box-shadow: 0 0 1px #2196F3;
   }
   input:checked + .slider:before {
     -webkit-transform: translateX(16px);
     -ms-transform: translateX(16px);
     transform: translateX(16px);
     background-color: #ffffff;
   }
   .he input:checked + .slider:before {
     -webkit-transform: translateX(-18px);
     -ms-transform: translateX(-18px);
     transform: translateX(-18px);
     background-color: #ffffff;
   }
   /* Rounded sliders */
   .slider.round {
     border-radius: 34px;
   }
   .slider.round:before {
     border-radius: 50%;
   }
</style>
