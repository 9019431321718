import axios from 'axios'
import config from '../../config'
import _ from 'lodash'
import sidebarMixin from '../../mixins/sidebarMixin'
import { makeQuery } from '../../queries'

export default {
  name: 'WordformsBase',
  data() {
    return {
      wordForms: {},
      wordFormsSelected: [],
      mobileWordFormsSelected: [],
      wordFormChildVisible: [],
      wordformsFailed: false,
      wordformsFirstFailed: false,
      loading: false
    }
  },
  mixins: [sidebarMixin],
  computed: {
    wordFormsTotal() {
      return _.sumBy(this.wordForms, data => data.wordForms.length)
    }
  },
  methods: {
    getData(firstRun) {
      if (this.query === '' ||
        this.searchStateUpdates.lastUpdateType === 'wordForms' ||
        this.searchStateUpdates.lastUpdateType === 'resultsPerPage'
      ) {
        return
      }
      if (this.searchStateUpdates.lastUpdateType === 'query') {
        firstRun = true
        this.wordForms = {}
        this.wordFormsSelected = []
        this.mobileWordFormsSelected = []
        this.wordFormChildVisible = []
      }
      this.wordformsFailed = false
      this.$emit('wordformsFailed', false)
      this.loading = true
      this.wordformsFirstFailed = false
      this.$emit('wordformsFirstFailed', false)
      axios.post(config.SERVER + '/wordforms', makeQuery())
        .then(response => {
          this.wordForms = response.data
          for (const wordData of this.wordForms) {
            // sort descending
            wordData.wordForms.sort((form1, form2) => form2.count - form1.count)
          }
          if (firstRun) {
            this.wordFormsSelected = this.wordForms
              .map(word => ({
                term: word.term,
                selections: _.fromPairs(word.wordForms.map(form => [form.wordForm, true]))
              }))
            this.mobileWordFormsSelected = _.cloneDeep(this.wordFormsSelected)
            // eslint-disable-next-line no-unused-vars
            this.wordFormChildVisible = this.wordForms.map(_ => false)
          }
          this.loading = false
        })
        .catch(() => {
          (firstRun) ? this.wordformsFirstFailed = true : this.wordformsFailed = true
          this.$emit('wordformsFirstFailed', this.wordformsFirstFailed)
          this.$emit('wordformsFailed', this.wordformsFailed)
          this.loading = false
        })
    },
    updateState() {
      this.setWordForms(this.wordFormsSelected)
    },
    toggleOneSetWordForms(wordFormSelections) {
      const newValue = !this.allChecked(wordFormSelections)
      Object.keys(wordFormSelections).forEach(wordForm => {
        wordFormSelections[wordForm] = newValue
      })
      this.setWordForms(this.wordFormsSelected)
    },
    toggleAllWordForms() {
      const newValue = !this.allChecked(this.wordFormsSelected)
      this.wordFormsSelected.forEach(termData => {
        Object.keys(termData.selections).forEach(wordForm => {
          termData.selections[wordForm] = newValue
        })
      })
      this.setWordForms(this.wordFormsSelected)
    }
  },
  watch: {
    'searchStateUpdates.counter'() {
      this.getData(false)
    }
  },
  mounted() {
    this.getData(true)
  }
}
