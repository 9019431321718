<template>
  <div id="app" :class="{ he: $settings.hebrew }">
    <div class="main-text">
      <!--<div v-if="!this.$router.currentRoute.name.includes('hebrew')"><common-header></common-header></div>
      <div v-if="this.$router.currentRoute.name.includes('hebrew')"><hebrew-header></hebrew-header></div>-->
      <common-header></common-header>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import commonHeader from './components/commonHeader.vue'
// import Loader from './components/commonLoader'
import config from './config'
let vm

function debugHandler(e) {
  if (e.altKey && e.ctrlKey && e.shiftKey && e.code === 'KeyD') {
    vm.$settings.debug = !vm.$settings.debug
  }
}
export default {
  name: 'App',
  components: { commonHeader, /* Loader */ },
  data() {
    return {
      queryText: '',
      iOS: false
    }
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    }
  },
  created: function () {
    this.$set(this.$settings, 'debug', false)
    vm = this
    window.addEventListener('keydown', debugHandler)
    const urlParams = new URLSearchParams(window.location.search)
    const lang = urlParams.get('lang')
    if (config.DISABLE_ENGLISH_UI) {
      this.$settings.hebrew = true
    } else if (this.$cookies.get('DICTA_USE_HEBREW') !== null) {
      this.$settings.hebrew = (this.$cookies.get('DICTA_USE_HEBREW') === 'true')
    } else if (lang !== null) {
      this.$settings.hebrew = lang.includes('he')
    } else {
      this.$settings.hebrew = true
    }
    if (this.$settings.hebrew) {
      document.body.classList.add('he')
    } else {
      document.body.classList.remove('he')
    }
  },
  watch: {
    $route: {
      handler(to, from) {
        const body = document.getElementsByTagName('body')[0]
        if (from !== undefined) {
          body.classList.remove('page--' + from.name.toLowerCase())
        }
        body.classList.add('page--' + to.name.toLowerCase())
      },
      immediate: true
    }
  },
  methods: {
    aboutPopup() {
      if (this.hebrew) {
        this.showAbout = true
      } else {
        this.showAboutEng = true
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', debugHandler)
  }
}
</script>

<style lang="scss">
@media (min-width: 769px) and (max-width: 1140px) {
  .container {
    max-width: 100% !important;
    width: 100%;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: 100%;
  direction: ltr;
  font-size: 16px;
}

.he {
  direction: rtl;
}

.noScroll {
  overflow: hidden;
}

@font-face {
  font-family: 'mft_narkisclassic';
  src: url('assets/fonts/mft_narkisclassic-regular.eot');
  src: url('assets/fonts/mft_narkisclassic-regular.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/mft_narkisclassic-regular.woff') format('woff'),
    url('assets/fonts/mft_narkisclassic-regular.ttf') format('truetype'),
    url('assets/fonts/mft_narkisclassic-regular.svg#mftnarkisclassicregular') format('svg');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: mft_narkisclassic;
  src: url('assets/fonts/MFTW_NarkisClassicBold.woff') format('woff');
  font-style: normal;
  font-weight: bold;
}

.f-narkis {
  font-family: Roboto, mft_narkisclassic, sans-serif;
  line-height: 1.35;
}

body {
  margin: 0;

  /* line-height: 17px;*/
  color: #000;
  height: 100%;
  min-height: 100%;
  font-family: 'Roboto', Arimo, sans-serif;
  /*font-size: 16px;*/
  overflow-y: auto;
}

.page--result {
  background: #f6f6f6;
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%
}

.progress-circular {
  margin: 1rem;
  position: relative;
  display: inline-flex;
}

.progress-circular--indeterminate .progress-circular__overlay {
  animation: f 1.4s ease-in-out infinite;
  stroke-linecap: round;
  stroke-dasharray: 80, 200;
  stroke-dashoffset: 0;
}

.progress-circular__overlay {
  stroke: currentColor;
  z-index: 2;
  transition: all .6s ease-in-out;
}

svg:not(:root) {
  overflow: hidden;
}

svg {
  transform: rotate(0deg);
}

.progress-circular--indeterminate svg {
  -webkit-animation: g 1.4s linear infinite;
  animation: g 1.4s linear infinite;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all .2s ease-in-out;
  z-index: 0;
}

.progress-circular__info {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#wrapper {
  overflow: hidden;
  min-height: 100%;
  padding: 0 0 45px;
}

.main-contant {
  padding: 16px 0 16px;
  min-height: 500px;
  position: relative;
}

.f.gray {
  color: #656565;
}

.f.white {
  color: #fff;
}

.f.black {
  color: #000;
}

.f.blue {
  color: #007EE5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #000;
}

/**========== Title ==========**/
h1 {
  font-size: 25px;
  line-height: 25px;
  text-transform: uppercase;
}

h1.light {
  font-weight: 300;
}

h1.medium {}

h1.bold {}

h2 {
  font-size: 20px;
  line-height: 23px;
  font-weight: 400;
}

h2.light {}

h2.medium {}

h2.bold {}

h3 {
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  margin: 0 0 18px;
}

h3.light {}

h3.medium {}

h3.bold {}

a {
  color: #000;
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
  ;
}

a:hover {
  text-decoration: none;
  color: #000;
}

.search-form,
.home-search-form {

  input[type="text"],
  input[type="search"],
  input[type="submit"],
  select,
  textarea,
  .form-control {
    height: 52px;
    border-radius: 2px;
    border: 0;
    background-color: #ffffff;
    margin: 0;
    color: #000;
    font-weight: 400;
    box-shadow: none;
    padding: 5px;
    font-size: 20px;
    line-height: 21px;
    outline: none;
    width: 100%;
    font-family: Roboto, "mft_narkisclassic", sans-serif;
  }

  .form-control:focus {
    border-color: #dcdcdc;
    outline: 0;
    box-shadow: none;
  }

  .form-group>.form-control {
    margin-bottom: 0;
  }

  textarea {
    resize: none;
    min-height: 50px;
  }

  input[type="text"]:hover,
  input[type="search"]:hover,
  input[type="submit"]:hover,
  select:hover,
  textarea:hover {
    background-color: #fff;

  }
}

#header.white input[type=search]:visited,
#header.white input[type=search]:active,
#header.white input[type=search]:focus,
#header.white input[type=search] {
  background-color: #fff;
}

::-webkit-input-placeholder {
  color: #000 !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #000 !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #000 !important;
}

:-ms-input-placeholder {
  color: #000 !important;
}

.list-holder:after {
  display: block;
  content: '';
  clear: both;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.border {
  padding: 0;
  border-bottom: 1px solid #007EE5;
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters>[class^="col-"],
.row.no-gutters>[class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

.modal-backdrop.show {
  filter: alpha(opacity=50);
  opacity: .5;
}

body {
  .pagination {
    position: relative;
    margin: 30px 0;
    display: block;
    padding: 0;
    text-align: center;
  }

  .pagination .pagination__navigation {
    padding: 5px;
    line-height: 25px;
    min-width: 86px;
    height: 35px;
    border-radius: 2px;
    background-color: #E3E3E3;
    font-size: 15px;
    border: 0;
    cursor: pointer;
  }

  .pagination li {
    display: inline-block;
    margin: 0 5px;
    vertical-align: middle;
  }

  .pagination .pagination__item {
    padding: 0;
    border: none;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    background: none;
    color: #000;
  }

  .pagination li .pagination__item.pagination__item--active {
    color: #808080;
    text-decoration: underline;
  }

  .pagination>li>a,
  .pagination>li>span {
    float: none;
  }

  .pagination>li>span {
    background: none;
    border: none;
  }

  .pagination li:first-child .pagination__navigation:after {
    content: "Prev Page";
    color: #000;
    font-size: 14px;
  }

  .pagination li:last-child .pagination__navigation:after {
    content: "Next Page";
    color: #000;
    font-size: 14px;
  }

  .pagination li .pagination__navigation--disabled {
    display: none !important;
  }

  .he .pagination li:first-child .pagination__navigation:after {
    content: "לעמוד הקודם";
  }

  .he .pagination li:last-child .pagination__navigation:after {
    content: "לעמוד הבא";
  }
}

.button {
  overflow: hidden;
  z-index: 0;
  cursor: pointer;
  position: relative;
  -moz-transition: all 0.1s;
  -o-transition: all 0.1s;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}

.anim {
  -moz-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
}

.anim:before {
  position: relative;
  content: '';
  display: block;
  margin-top: 100%;
}

.anim:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
}

.fade.in {
  opacity: 1 !important;
}

body.modal-open {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hoverable {
  overflow: hidden
}

.hoverable:hover .anim {
  -moz-animation: anim-out 0.75s;
  -webkit-animation: anim-out 0.75s;
  animation: anim-out 0.75s;
}

.hoverable:hover .anim:after {
  -moz-animation: anim-out-pseudo 0.75s;
  -webkit-animation: anim-out-pseudo 0.75s;
  animation: anim-out-pseudo 0.75s;
}

@-webkit-keyframes anim-in {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@-moz-keyframes anim-in {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@-ms-keyframes anim-in {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes anim-in {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@-webkit-keyframes anim-in-pseudo {
  0% {
    background: rgba(0, 0, 0, 0.45);
  }

  100% {
    background: transparent;
  }
}

@-moz-keyframes anim-in-pseudo {
  0% {
    background: rgba(0, 0, 0, 0.45);
  }

  100% {
    background: transparent;
  }
}

@-ms-keyframes anim-in-pseudo {
  0% {
    background: rgba(0, 0, 0, 0.45);
  }

  100% {
    background: transparent;
  }
}

@keyframes anim-in-pseudo {
  0% {
    background: rgba(0, 0, 0, 0.45);
  }

  100% {
    background: transparent;
  }
}

@-webkit-keyframes anim-out {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@-moz-keyframes anim-out {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@-ms-keyframes anim-out {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes anim-out {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@-webkit-keyframes anim-out-pseudo {
  0% {
    background: rgba(0, 0, 0, 0.45);
  }

  100% {
    background: transparent;
  }
}

@-moz-keyframes anim-out-pseudo {
  0% {
    background: rgba(0, 0, 0, 0.45);
  }

  100% {
    background: transparent;
  }
}

@-ms-keyframes anim-out-pseudo {
  0% {
    background: rgba(0, 0, 0, 0.45);
  }

  100% {
    background: transparent;
  }
}

@keyframes anim-out-pseudo {
  0% {
    background: rgba(0, 0, 0, 0.45);
  }

  100% {
    background: transparent;
  }
}

#app {
  min-height: 100%;
  margin-bottom: -45px;
}

.main-text {
  min-height: 95vh;
}

.show-mobile-view {
  display: none;
}

.common-close-btn {
  margin: 0;
  padding: 2px 4px;
  float: left;
  border: 0;
  border-radius: 2px;
  font-size: 13px;
  color: #000;
  box-shadow: none;
  cursor: pointer;
  background: #ffffff;
}

.common-close-btn:hover,
.common-close-btn:focus {
  background: #E3E3E3;
  outline: none;
}

.close-btn-icon {
  line-height: 16px;
  margin: 0 0 0 4px;
  vertical-align: middle;
}

.close-btn-img-icon {
  position: relative;
  top: -1px;
  left: 5px;
}

.mobile-close-button {
  display: none;
  float: left;
  padding: 0;
  position: relative;
  top: 0;
  background: none;
  border: none;
  box-shadow: none;
}

.morebtn {
  cursor: pointer;
  font-size: 13px;
}

.more-btn-title {
  margin-bottom: 0;
}

.more-btn-icon {
  position: relative;
  top: 1.3px;
  left: 8px;
}

.he .more-btn-icon {
  right: 8px;
}

.divider {
  border-top: 1.5px solid #D8D8D8;
}

.divider-dots {
  border-top: 1.5px dashed #D8D8D8;
}

.bg-half-white {
  background: #fbfbfb !important;
}

.p-x-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.no-results {
  opacity: 0.5;
}

/*  start mobile  */
@media screen and (max-width: 575px) {
  .hidden-mobile-view {
    display: none;
  }

  .show-mobile-view {
    display: inline-block;
  }

  .container {
    max-width: 100%;
    width: 100%;
  }

  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="time"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"],
  input[type="submit"],
  select,
  textarea,
  .form-control {
    height: 56px;
  }

  .main-contant {
    padding: 0;
    min-height: inherit;
  }

}

@media (min-width: 768px) and (max-width: 1024px) {
  .modal-dialog {
    max-width: 700px;
  }
}

footer {
  margin-top: 0 !important;
}

@media screen and (max-width: 767px) {
  .modal {
    -webkit-overflow-scrolling: touch;
  }

  .modal-header {
    border-radius: 0 !important;
    width: 100%;
    left: 0;
    position: fixed;
    top: -1px;
    z-index: 2;
  }

  .modal-dialog {
    margin: 0 !important;
  }

  .modal-content {
    border-radius: 0 !important;
    height: 100vh;
    overflow: hidden;
    background: white;
  }

  #app {
    margin-bottom: -90px;
  }

  .main-text {
    min-height: 85.6vh;
  }

  .main-text-for-result {
    min-height: 78vh;
  }

  .rest #app {
    margin-bottom: -142px;
  }

  body {
    .pagination li {
      margin: 0;
    }

    .pagination .pagination__navigation {
      min-width: 105px;
      height: 48px;
      border: none;
      background: #fff url("./assets/ic_next_mobile.png") no-repeat 50% 50%;
      box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.37);
    }

    .he .pagination li .pagination__navigation {
      background: #fff url("./assets/ic_prev_mobile.png") no-repeat 50% 50%;
    }

    .he .pagination li:first-child .pagination__navigation {
      background: #fff url("./assets/ic_next_mobile.png") no-repeat 50% 50%;
    }

    .pagination li:first-child .pagination__navigation {
      background: #fff url("./assets/ic_prev_mobile.png") no-repeat 50% 50%;
    }

    .pagination li:first-child .pagination__navigation:after,
    .pagination li:last-child .pagination__navigation:after {
      content: "";
    }

    .he .pagination li:first-child .pagination__navigation:after,
    .he .pagination li:last-child .pagination__navigation:after {
      content: "";
    }

    .pagination li .pagination__item {
      display: none;
      font-size: 16px;
      line-height: 19px;
      color: #000;
    }

    .pagination li .pagination__item.pagination__item--active {
      color: #007EE5;
    }

    .pagination-holder {
      position: relative;
      overflow: hidden;
      padding: 30px 12px;
    }

    .pagination {
      margin: 0;
    }

    .pagination li:first-child {
      float: left;
    }

    .pagination li:last-child {
      float: right;
    }

    .he .pagination li:first-child {
      float: right;
    }

    .he .pagination li:last-child {
      float: left;
    }

    .page-style {
      width: 65px;
      margin: -21px auto 0;
      text-align: center;
    }

    .he .page-style {
      width: 70px;
    }

    .modal-backdrop {
      display: none !important;
    }

    .pagination li .pagination__navigation--disabled {
      display: block !important;
      opacity: 0.55;
    }

    .pagination li .pagination__more {
      display: none !important;
    }
  }
}
</style>
