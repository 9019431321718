<template>
  <div class="inner-header" v-bind:class="{ devmode: devMode }">
    <div class="container">
      <div class="row">
        <div :class="dictaLibraryCorpus ? 'col-sm-8' : 'col-md-3 col-sm-4'">
          <div class="logo inner-header-logo text-white">
            <router-link class="logo-holder" to="/">
              <span v-if="SearchType.Talmud">
                <img src="../assets/Logo Talmud_Search_Blue_Circle.png" class="inner-header-logo-img">
                <div class="d-inline-block mx-2">
                  <span class="title inner-header-logo-title">
                    {{ hebrew ? 'חיפוש בתלמוד' : 'Talmud search' }}</span>
                  <small class="d-block dicta">DICTA</small>
                </div>
              </span>
              <div v-else-if="SearchType.Bible">
                <img src="../assets/Logo Bible_Search_Blue_Circle.png" class="inner-header-logo-img">
                <div class="d-inline-block mx-2">
                  <span class="title inner-header-logo-title">
                    {{ hebrew ? 'חיפוש בתנ"ך' : 'Bible Search' }}
                  </span>
                  <small class="d-block dicta">DICTA</small>
                </div>
              </div>
              <span v-else-if="dictaLibraryCorpus">
                <h2 class="inner-header-logo-img bg-white rounded-circle text-primary text-center m-0 pt-1">
                  <strong>ד</strong>
                </h2>
                <div class="d-inline-block mr-2">
                  <span class="title inner-header-logo-title pt-2" style="font-size: 1rem">
                    {{ hebrew ? 'הספריה של דיקטה' : 'The Dicta Library' }}
                  </span>
                </div>
              </span>
              <span v-else>
                <img src="../assets/Logo Bible_Search_Blue_Circle.png" class="inner-header-logo-img">
                <div class="d-inline-block mx-2">
                  <span class="title inner-header-logo-title">
                    {{ hebrew ? 'חיפוש כללי' : 'Generic Search' }}
                  </span>
                  <small class="d-block dicta">DICTA</small>
                </div>
              </span>
            </router-link>
          </div>
        </div>
        <div v-if="!hideSearch" class="search-form-wrap"
          :class="dictaLibraryCorpus ? hebrew ? 'col pl-md-0' : 'col pr-md-0' : 'col-sm-7'">
          <div :class="{ 'library-search': dictaLibraryCorpus }">
            <small class="py-2 translate-msg position-absolute d-none d-md-block" style="z-index:99;top:42px;"
              :style="hebrew ? 'right:15px;' : 'left:15px;'" v-if="translatingEnglishKeys">
              {{ hebrew ? 'תרגום המקלדת מאנגלית לעברית פעיל.' : 'Auto translating English keys to Hebrew.' }}
              <a href class="text-primary" @click.prevent="stopTranslating">{{ hebrew ? 'הפסק' : 'Stop' }}</a>
            </small>
            <form class="search-form" @submit.prevent="newQuery()">
              <div :class="{ 'input-group': dictaLibraryCorpus }">
                <input @focus="inputFocused" @blur="inputFocusedOut" type="search" dir="rtl" ref="searchbox"
                  class="form-control search-form-control" :class="{ 'bg-white': queryText && queryText.length > 0 }"
                  v-model="queryText" @keydown="processKey" />
                <button v-if="queryText && queryText.length > 0" class="search-input-clear d-none" type="button"
                  @click="clearText()">
                  <img src="../assets/ic_cancel.png" />
                </button>
                <button class="search-bt bt mr-auto button raised  d-none d-md-block"
                  :class="{ 'hoverable': !dictaLibraryCorpus }" type="button" @click="newQuery()">
                  <div class="anim"></div>
                  <i class="fa fa-search text-primary mt-1" aria-hidden="true"></i>
                </button>
                <button class="bt search-bt mr-auto button raised hoverable d-block d-md-none" type="button"
                  @click="newQuery()">
                  <div class="anim"></div>
                  <i class="fa fa-search" aria-hidden="true"></i>
                </button>
              </div>
            </form>
            <div v-bind:class="[hebrew ? 'text-left' : 'text-right', 'd-block d-sm-none my-2']">
              <a id="sidebar-btn" href="#" class="btn-sidebar d-inline-block text-primary"
                @click="$emit('slideMobileSidebar')">
                <i class="fas fa-plus-square"></i>
                {{ hebrew ? 'חיפוש מתקדם' : 'Advanced search' }}
              </a>
            </div>
          </div>
        </div>
        <div class="d-block d-md-none position-absolute mobile-menu-wrap mt-1">
          <mobile-menu ref="mobileMenu" :englishSupported="true"
            @showHowDialog="hebrew ? showAboutHeb = true : showAbout = true" :howDialog="true">
          </mobile-menu>
        </div>
        <div v-if="!hebrew" :class="dictaLibraryCorpus ? 'col pl-0' : 'col-md-2'" class=" d-none d-md-block">
          <a @click="showAbout = true" :class="{ 'library-abt': dictaLibraryCorpus }"
            class="btn btn-outline-light btn-primary btn-md btn-header" href="#">
            <div class="anim"></div>How to use
          </a>
        </div>

        <div v-if="hebrew" :class="dictaLibraryCorpus ? 'col pr-0' : 'col-md-2'" class=" d-none d-md-block">
          <a @click="showAboutHeb = true" :class="{ 'library-abt': dictaLibraryCorpus }"
            class="btn btn-outline-light btn-primary btn-md btn-header" href="#">
            <div class="anim"></div>איך זה עובד
          </a>
        </div>
      </div>
    </div>
    <how-popup v-model="showAbout"></how-popup>
    <how-popup-hebrew v-model="showAboutHeb"></how-popup-hebrew>
  </div>
</template>
<script>
// import Sidebar from './result/sidebar.vue'
import HowPopupHebrew from './howPopupHebrew.vue'
import HowPopup from './howPopup.vue'
import { SearchType } from '../searchConfig.js'
import config from '../config'
import { isThisAppInDevMode } from '../devMode'
import englishToHebrewTexMixin from '@/mixins/englishToHebrewTexMixin'

let vm
function keyhandler(e) {
  if (e.code === 'Slash') {
    vm.$refs.searchbox.focus()
  }
}

export default {
  name: 'searchHeader',
  components: {
    HowPopup,
    HowPopupHebrew,
    // Sidebar
  },
  props: ['updatedQueryText', 'hideSearch'],
  mixins: [englishToHebrewTexMixin],
  data() {
    return {
      queryText: '',
      originalQuery: '',
      flag: false,
      showAbout: false,
      showAboutHeb: false
    }
  },
  computed: {
    devMode() {
      return isThisAppInDevMode('search')
    },
    SearchType() {
      return SearchType
    },
    dictaLibraryCorpus() {
      return config.corpusType === 'dicta-library'
    },
    hebrew() {
      // return this.$route.name.includes('hebrew')
      return this.$settings.hebrew
    }
  },
  methods: {
    inputFocused() {
      this.$refs.searchbox.classList.add('bg-white')
    },
    inputFocusedOut() {
      if (this.queryText && this.queryText.length === 0)
        this.$refs.searchbox.classList.remove('bg-white')
    },
    newQuery() {
      if (this.queryText === this.originalQuery) {
        this.$emit('retry')
      } else {
        this.originalQuery = this.queryText
        this.$router.push({ name: 'Result', query: { text: this.queryText } })
      }
    },
    clearText() {
      this.queryText = ''
    },
    processKey(keyEvt) {
      if (keyEvt.key === 'Enter') {
        keyEvt.preventDefault()
        // on mobile, the change event doesn't fire until the input loses focus
        // so queryText may not be updated
        this.queryText = this.$refs.searchbox.value
        this.newQuery()
      } else {
        this.englishKeyEventToHebrew(keyEvt, this.$refs.searchbox)
      }
    }
  },
  mounted() {
    this.originalQuery = this.queryText = this.$route.query.text
    this.newQuery()
    vm = this
    document.addEventListener('keyup', keyhandler)
  },
  beforeDestroy() {
    document.removeEventListener('keyup', keyhandler)
  },
  watch: {
    $route() {
      this.queryText = this.$route.query.text
      this.originalQuery = this.queryText
    },
    updatedQueryText(val, oldVal) {
      this.queryText = val
      if (oldVal !== null) // excludes original DEFINITION, only time searchQuery not set to "text"
        this.$router.push({ name: 'Result', query: { text: this.queryText } }) // note: order not kept
    }
  },
}
</script>

<style scoped lang="scss">
.inner-header {
  padding: 6px 0 3px;
  background-color: #007ee5;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.32);
  z-index: 1000;
  height: 50px;
}

.inner-header.devmode {
  background-color: red;
}

.search-bt {
  position: absolute;
  top: 1px;
  z-index: 100;
  width: 40px;
  height: 36px;
  background-color: #fff;
  box-shadow: none;
  border: 0;
  outline: none;
  left: 15px;
  border-radius: 2px;
}

.he .search-bt {
  left: 0;
}

.search-bt-icon {
  display: block;
  margin: 0 auto;
}

.inner-header-logo {
  width: 173px;
  height: 46px;
}

.inner-header-logo-img {
  width: 36px;
  height: 36px;
  display: inline-block;
  vertical-align: top;
}

.inner-header-logo-title {
  color: #fff;
  font-weight: 100;
  font-size: 18px;
  line-height: 20px;
  display: inline-block;
  vertical-align: middle;
  text-transform: capitalize;
}

.search-form {
  margin: 1px 0 0;
  overflow: hidden;
  height: 36px;
  background-color: #ffffff;

  border-radius: 2px;
}

.search-form-control[type="search"] {
  text-align: right;
  position: relative;
  height: 100%;

}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

.mobile-menu-wrap {
  right: 15px;
  top: 9px;
}

.he .mobile-menu-wrap {
  right: auto;
  left: 15px;
}

.btn-header {
  float: right;
  margin-top: 3px;
}

.he .btn-header {
  float: left;
}

.dicta {
  line-height: 14px;
  color: #fff;
  opacity: .7;
  font-family: Arimo;
}

@media screen and (max-width: 575px) {
  .search-form-wrap {
    background: #bfe1fe;
  }

  .inner-header {
    padding: 0;
    height: auto;
    box-shadow: none;
  }

  .inner-header-logo {
    display: flex;
    align-items: center;
  }

  .inner-header-logo-img {
    width: 32px;
    height: 32px;
    margin-top: 4px
  }

  .search-bt {
    height: 48px;
    width: 50px;
    left: 0;
    z-index: 0;
    padding: 0;
    top: 2px;

    i {
      font-size: 22px;
      color: #007ee5;
    }
  }

  .search-form {
    height: 48px;
    position: relative;
    border-radius: 6px;
    margin-top: 12px;
  }

  .search-form-control[type="search"] {
    font-size: 32px;
    padding: 2px 13px 0px 12px;
  }

  .search-input-clear {
    height: 18px;
    width: 18px;
    right: 14px;
    top: 18px;
    position: absolute;
    background: none;
    border: none;
    outline: none;
    padding: 0;
    display: none;
  }

  .he .search-input-clear {
    right: 5px;
  }

}

@media screen and (min-width: 576px) {
  .library-search {
    width: 240px;
    height: 36px;

    .search-form {
      border-radius: 4px;
      overflow: hidden;
      transition: 0.6s;

      &:not(.bg-white) {
        background: rgba(255, 255, 255, 0.5);
      }

      &:hover {
        //background: white;
        opacity: 1;
      }

      .input-group {
        height: 36px;

        .form-control {
          font-size: 22px;
          background-color: transparent;
        }

        button {
          font-size: 18px;
          background-color: transparent;
        }
      }

    }
  }
}

.library-abt {
  width: 100px;
  padding-right: 0;
  padding-left: 0;
  font-size: 16px;
  height: 36px;
  margin-top: 0;
  line-height: 28px;
}
</style>
