import axios from 'axios'
import config from '../../config'
import _ from 'lodash'
import sidebarMixin from '../../mixins/sidebarMixin'
import Checkbox from './Checkbox'
import ExpandableList from './ExpandableList'
import Synonyms from './Synonyms'
import { makeQuery } from '../../queries'

export default {
  name: 'MeaningsBase',
  components: { Synonyms, ExpandableList, Checkbox },
  props: ['expanded'],
  mixins: [sidebarMixin],
  data() {
    return {
      lexemeData: [],
      lexemesSelected: [],
      mobileLexemesSelected: [],
      meaningChildVisible: [],
      loading: true,
      updating: false,
      synonymData: [],
      synonymsSelected: [],
      mobileSynonymsSelected: [],
      relatedFailed: false,
      lexemesFailed: false
    }
  },
  methods: {
    // this function is called when the data is initially loaded, and there are no checkboxes selected or deselected
    // it therefore only sends the query text itself
    loadLexemeData() {
      if (!(this.$settings.debug || process.env.VUE_APP_SEARCH_TYPE !== 'Talmud')) {
        return
      }
      const reset = {
        lexemeData: [],
        lexemesSelected: [],
        mobileLexemesSelected: [],
        meaningChildVisible: [],
        synonymData: [],
        synonymsSelected: [],
        mobileSynonymsSelected: []
      }
      for (let k in reset) {
        this[k] = reset[k]
      }
      this.loading = true
      this.lexemesFailed = false
      this.$emit('lexemesFailed', false)
      const lexemePromise = axios.post(config.SERVER + '/lexemes', {
        query: this.$route.query.text
      }).then(response => {
        // the raw data is stored in 'lexemeData'
        // 'lexemesSelected' corresponds to the checkboxes, and will be sent back to the server when it changes
        this.lexemeData = response.data
        // the structure is an array, one element for each term
        // each element is an object with lexemes for keys and true or false for the value
        this.lexemesSelected = this.lexemeData
          .map(word => ({
            term: word.term,
            selections: _.fromPairs(word.allLexemes.map(lex => [lex.lexeme, true]))
          }))
        this.mobileLexemesSelected = this.lexemeData
          .map(word => ({
            term: word.term,
            selections: _.fromPairs(word.allLexemes.map(lex => [lex.lexeme, true]))
          }))
        // eslint-disable-next-line no-unused-vars
        this.meaningChildVisible = this.lexemeData.map(_ => false)
      })
        .catch(() => {
          this.lexemesFailed = true
          this.$emit('lexemesFailed', true)
        })
      const relatedPromise = axios.post(config.SERVER + '/related', {
        query: this.$route.query.text
      }).then(response => {
        this.relatedFailed = false
        this.$emit('relatedFailed', false)
        // the raw data is stored in 'synonymData'
        // 'synonymsSelected' corresponds to the switches/checkboxes, and will be sent back the server when it changes
        this.synonymData = response.data
        // the structure is an array, one element for each term
        // each element is an object with lexemes for keys and true or false for the value
        this.synonymsSelected = this.synonymData.map(
          termData => {
            const termSelections = {}
            termData.lexemes.forEach(lexemeData => {
              lexemeData.related.forEach(synonymData => {
                termSelections[synonymData.relatedLexeme] = false
              })
            })
            return {
              term: termData.term,
              selections: termSelections
            }
          }
        )
        this.mobileSynonymsSelected = _.cloneDeep(this.synonymsSelected)
      }).catch(() => {
        this.relatedFailed = true
        this.$emit('relatedFailed', true)
      })
      Promise.all([lexemePromise, relatedPromise])
        .then(() => {
          this.loading = false
        })
    },
    // when the user changes wordForms, books, or adds synonyms,
    // the counts of lexemes that match may change
    // if the user adds synonyms, there is also the possibility it may allow new meanings of the other words
    updateLexemeData() {
      if (!(this.$settings.debug || process.env.VUE_APP_SEARCH_TYPE !== 'Talmud')) {
        return
      }
      if (this.noResultsPossible || this.searchStateUpdates.lastUpdateType === 'query') {
        return
      }
      this.updating = true
      const lexemePromise = axios.post(config.SERVER + '/lexemes', makeQuery()).then(response => {
        this.lexemesFailed = false
        this.$emit('lexemesFailed', false)
        for (const word of this.lexemeData) {
          const newWordData = response.data.shift()
          const oldLexemes = new Map(word.allLexemes.map(w => [w.lexeme, w]))
          const newLexemes = new Map(newWordData.allLexemes.map(w => [w.lexeme, w]))
          for (const lexemeData of oldLexemes.values()) {
            lexemeData.count = newLexemes.get(lexemeData.lexeme).count
          }
        }
      }).catch(() => {
        this.lexemesFailed = true
        this.$mit('lexemesFailed', true)
      })
      const relatedPromise = axios.post(config.SERVER + '/related', makeQuery())
        .then(response => {
          this.relatedFailed = false
          this.$emit('relatedFailed', false)
          for (const word of this.synonymData) {
            const newWordData = response.data.shift()
            const oldLexemes = new Map(word.lexemes.map(w => [w.lexeme, w]))
            const newLexemes = new Map(newWordData.lexemes.map(w => [w.lexeme, w]))
            for (const lexemeData of oldLexemes.values()) {
              const newRelated = new Map(newLexemes.get(lexemeData.lexeme).related.map(w => [w.relatedLexeme, w]))
              for (const relatedData of lexemeData.related) {
                relatedData.count = newRelated.get(relatedData.relatedLexeme).count
              }
            }
          }
        }).catch(() => {
          this.relatedFailed = true
          this.$emit('relatedFailed', true)
        })
      Promise.all([relatedPromise, lexemePromise])
        .then(() => {
          this.updating = false
        })
    },
    getRelatedLexemes(term, lexeme) {
      const termData = this.synonymData.find(termData => termData.term === term)
      const lexemeData = termData.lexemes.find(lexemeData => lexemeData.lexeme === lexeme)
      return lexemeData.related
    },
    toggleLexeme(selections, lexeme, val) {
      selections[lexeme] = val
      this.setLexemes(this.lexemesSelected)
    },
    toggleLexemeGroup(selections) {
      const newVal = !this.allChecked(selections)
      Object.keys(selections).forEach(key => { selections[key] = newVal })
      this.setLexemes(this.lexemesSelected)
    },
    toggleSynonym(synonymList, synonym) {
      synonymList[synonym] = !synonymList[synonym]
      this.setRelated(this.synonymsSelected)
    }
  },
  watch: {
    '$route.query.text'() {
      this.loadLexemeData()
    },
    'searchStateUpdates.counter'() {
      if (this.query === '' ||
        // no reason to run an update if we just ran loadLexemeData()
        this.searchStateUpdates.lastUpdateType === 'query' ||
        this.searchStateUpdates.lastUpdateType === 'resultsPerPage'
      ) {
        return
      }
      this.updateLexemeData()
    },
    '$settings.debug'() {
      if (this.$settings.debug) {
        this.loadLexemeData()
      }
    }
  },
  mounted() {
    this.loadLexemeData()
  }
}
