<template>
  <div class="morebtn">
    <a class="f gray" @click="$emit('toggle')">
      <p class="more-btn-title" v-if="!expanded">
        <span class="more-btn-text" dir="ltr" v-if="!hebrew">More<i class="more-btn-icon fa fa-caret-down"></i></span>
        <span class="more-btn-text" dir="rtl" v-if="hebrew">עוד<i class="more-btn-icon fa fa-caret-down"></i></span>
      </p>
      <p class="more-btn-title" v-else>
        <span class="more-btn-text" dir="ltr" v-if="!hebrew">Less<i class="more-btn-icon fa fa-caret-up"></i></span>
        <span class="more-btn-text" dir="rtl" v-if="hebrew">פחות<i class="more-btn-icon fa fa-caret-up"></i></span>
      </p>
    </a>
  </div>
</template>
<script>
import sidebarMixin from '../../mixins/sidebarMixin'

export default {
  name: 'MoreButton',
  mixins: [sidebarMixin],
  props: {
    expanded: {}
  }
}
</script>
