<template>
    <li class="accordion-li" v-if="$settings.debug || !(SearchType.Talmud)">
        <a id="meanings_and_synonyms" class="f black link" :class="{active: expanded}" @click="$emit('toggle')">
          <span v-if="!hebrew">Meanings and synonyms</span><span v-if="hebrew">משמעויות ומילים נרדפות</span>
        </a>
        <p v-if="loading" class="new-label">{{ hebrew ? 'טוען נתונים' : 'Loading'}}...</p>
        <span v-if="!relatedFailed && !lexemesFailed">
          <p id="new-label-meaning" v-if="!expanded && !hebrew" dir="ltr" class="f gray new-label"> {{countSelected(lexemesSelected)}} Meanings selected </p>
          <p id="new-label-meaning-he" v-if="!expanded && hebrew" dir="rtl" class="f gray new-label"> {{countSelected(lexemesSelected)}} משמעויות נבחרו </p>
        </span>
        <transition name="slide-fade">
            <div v-if="expanded" class="slide">
                <ul class="inner-accordion">
                    <li class="inner-accordion-li inner-accordion-result-alert"
                        v-if="!loading && lexemeData[0].allLexemes.length < 1">
                      <span v-if="!hebrew">No Results</span>
                      <span v-if="hebrew">לא נמצאו תוצאות</span>
                    </li>
                    <li class="inner-accordion-li" v-if="!loading && lexemeData[0].allLexemes">
                        <ul class="inner-ul" v-for="(word, index) in lexemeData" :key="word._id">
                            <li>
                              <!-- header for a single term, hidden if there is only one term -->
                                <a class="inner-accordion-link"
                                   v-if="lexemeData.length > 1"
                                   :class="{ selected: meaningChildVisible[index] }"
                                   @click="$set(meaningChildVisible, index, !meaningChildVisible[index])">
                                  <span v-if="!hebrew" dir="rtl" class="f black inner-accordion-text"> <span>{{ word.term }}</span> Meanings of</span>
                                  <span v-if="hebrew" dir="rtl" class="f black inner-accordion-text"> משמעויות של <span>{{ word.term }}</span></span>
                                </a>
                               <div class="slide">
                                <transition name="slide-fade">
                                  <!-- data for a single term-->
                                  <!-- shown if term is expanded or there's only one term -->
                                  <div v-if="meaningChildVisible[index]
                                         || lexemeData.length === 1"
                                       :class="lexemeData.length === 1 ? 'inner-accordion-content-single' : 'inner-accordion-content'">
                                    <SelectAll id="m_s_checkbox"
                                               v-if="word.allLexemes.length > 1"
                                               :checked="allChecked(lexemesSelected[index].selections)"
                                               :partial="partialChecked(lexemesSelected[index].selections)"
                                               @change="toggleLexemeGroup(lexemesSelected[index].selections)"></SelectAll>
                                    <ExpandableList
                                      data-id="m_s_ul"
                                      :class="{single: (lexemeData.length === 0 || word.allLexemes.length === 0)}"
                                      :list-items="word.allLexemes"
                                    >
                                      <template slot-scope="{ listitem }">
                                        <Checkbox v-if="word.allLexemes.length > 1"
                                                  :checked="lexemesSelected[index].selections[listitem.lexeme]"
                                                  @change="val => toggleLexeme(lexemesSelected[index].selections, listitem.lexeme, val)"
                                                  :class="{'no-results': listitem.count === 0}"
                                                  narkis-offset="true"
                                        >
                                          <p class="f gray check-text"
                                             :class="{'no-results': listitem.count === 0}">
                                            <span class="f-narkis">{{ listitem.description.substr(0, listitem.description.indexOf('(')) }}</span>
                                            <span :dir="hebrew ? 'ltr' : null">{{ listitem.description.substr(listitem.description.indexOf('(')) }}</span>
                                            <span class="text-numbers">({{updating ? '' : listitem.count}}<i
                                                v-if="updating" class="fa fa-circle-o-notch fa-spin"></i>)</span>
                                          </p>
                                        </Checkbox>
                                        <!-- no checkbox if there's only one meaning -->
                                        <p v-else
                                           class="f gray check-text single-meaning"
                                           :class="{'no-results': listitem.count === 0}">
                                          <span class="f-narkis">{{ listitem.description.substr(0, listitem.description.indexOf('(')) }}</span>
                                          <span :dir="hebrew ? 'ltr' : null">{{ listitem.description.substr(listitem.description.indexOf('(')) }}</span>
                                          <span class="text-numbers">({{updating ? '' : listitem.count}}<i
                                              v-if="updating" class="fa fa-circle-o-notch fa-spin"></i>)</span>
                                        </p>
                                        <Synonyms :class="{'align-with-checkbox': word.allLexemes.length > 1}"
                                                  :related-lexemes="getRelatedLexemes(word.term, listitem.lexeme)"
                                                  :related-lexemes-selected="synonymsSelected.find(wordData => wordData.term === word.term).selections"
                                                  @changeRelated="synonym => toggleSynonym(synonymsSelected.find(wordData => wordData.term === word.term).selections, synonym)"
                                        ></Synonyms>
                                      </template>
                                    </ExpandableList>
                                  </div>
                                </transition>
                              </div>
                            </li>

                        </ul>
                    </li>
                </ul>
            </div>
        </transition>
        <div class="retry text-muted px-3" v-if="lexemesFailed || relatedFailed">
          <div>{{hebrew ? 'אופס יש לנו בעיה!' : 'Meanings search failed'}}</div>
          <b-btn variant="link" class="text-muted bg-transparent border-0 p-0" @click="loadLexemeData()">
            <i class="fas fa-redo-alt"></i>
            <span class="text-underline mx-1" style="font-size:16px;">{{hebrew ? 'נסו שוב' : 'Try again'}}</span>
          </b-btn>
        </div>
      </li>

</template>

<script>
import Checkbox from './Checkbox'
import ExpandableList from './ExpandableList'
import Synonyms from './Synonyms'
import MeaningsBase from './MeaningsBase'
import SelectAll from './SelectAll'
import {SearchType} from '../../searchConfig.js'

export default {
  name: 'MeaningsDesktop',
  components: {SelectAll, Synonyms, ExpandableList, Checkbox},
  props: ['expanded'],
  mixins: [MeaningsBase],
  computed: {
    SearchType: {
      cache: false,
      get () {
        return SearchType
      }
    }
  }
}
</script>
<style scoped src="../../assets/style/common-desktop-sidebar.css"></style>
<style scoped>
  .f-narkis{
    font-size: 18px;
  }
  .inner-ul{
    padding: 0;
    margin-top:10px;
    list-style-type: none;
  }
  .single-meaning {
    margin-top: 5px;
  }
  .align-with-checkbox {
    margin-left: 22px;
  }
  .he .align-with-checkbox {
    margin: 0 22px 0 0;
  }
</style>
