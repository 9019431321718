import axios from 'axios'
import config from '../../config'
import _ from 'lodash'
import sidebarMixin from '../../mixins/sidebarMixin'
import { makeQuery } from '../../queries'

function orderedGroupBy(list, iteratee) {
  return list.reduce((acc, current) => {
    if (acc.length === 0 || _.last(acc).category !== iteratee(current)) {
      acc.push({
        category: iteratee(current),
        data: []
      })
    }
    _.last(acc).data.push(current)
    return acc
  }, [])
}

export default {
  name: 'BooksBase',
  mixins: [sidebarMixin],
  data() {
    return {
      ungroupedBooks: [],
      books: [],
      booksSelected: {},
      mobileBooksSelected: {},
      bookChildVisible: {},
      loading: false,
      updating: false,
      booksFailed: false,
      updateBooksFailed: false
    }
  },
  methods: {
    loadData() {
      if (this.query === '' ||
        this.searchStateUpdates.lastUpdateType === 'books' ||
        this.searchStateUpdates.lastUpdateType === 'resultsPerPage'
      ) {
        return
      }
      this.books = []
      this.booksSelected = {}
      this.bookChildVisible = {}
      this.loading = true
      this.booksFailed = false
      this.$emit('booksFailed', false)
      axios.post(config.SERVER + '/books', makeQuery())
        .then(response => {
          this.ungroupedBooks = response.data
          this.books = orderedGroupBy(response.data, item => item.hebrewBookName[1])
          this.bookChildVisible = _.fromPairs(this.books.map(group => [group.category, false]))
          this.booksSelected = _.fromPairs(response.data.map(book => [book.bookId, true]))
          this.mobileBooksSelected = _.fromPairs(response.data.map(book => [book.bookId, true]))
          this.loading = false
        })
        .catch(() => {
          this.booksFailed = true
          this.$emit('booksFailed', true)
          this.loading = false
        })
    },
    updateData() {
      if (Object.keys(this.booksSelected).length === 0 || this.searchStateUpdates.lastUpdateType === 'query') {
        this.loadData()
        return
      }
      this.updating = true
      this.updateBooksFailed = false
      this.$emit('updateBooksFailed', false)
      axios.post(config.SERVER + '/books', makeQuery())
        .then(response => {
          // sort keys might be different for the same book - they come from some item within the book, so the overall
          // sort will be correct
          const sortKeysToBookId = new Map([
            ...this.ungroupedBooks.map(book => [book.sortKey, book.bookId]),
            ...response.data.map(book => [book.sortKey, book.bookId])
          ])
          const sortKeys = Array.from(sortKeysToBookId.keys())
          sortKeys.sort()
          const sortedBooks = _.uniq(sortKeys.map(key => sortKeysToBookId.get(key)))
          const idToBook = new Map()
          for (const book of this.ungroupedBooks) {
            book.count = 0
            idToBook.set(book.bookId, book)
          }
          for (const book of response.data) {
            idToBook.set(book.bookId, book)
          }
          this.ungroupedBooks = sortedBooks.map(key => idToBook.get(key))
          this.books = orderedGroupBy(this.ungroupedBooks, item => item.hebrewBookName[1])
          // if there is a new category, init bookChildVisible to closed
          for (const group of this.books) {
            if (!(Object.prototype.hasOwnProperty.call(this.bookChildVisible, group.category))) {
              this.$set(this.bookChildVisible, group.category, false)
            }
          }
          // if a new book appeared, it should be checked
          for (const book of response.data) {
            if (!(Object.prototype.hasOwnProperty.call(this.booksSelected, book.bookId))) {
              this.$set(this.booksSelected, book.bookId, true)
            }
          }
          this.updating = false
        })
        .catch(() => {
          this.updateBooksFailed = true
          this.$emit('updateBooksFailed', true)
          this.updating = false
          //this.setServerFailed(true)
        })
    },
    allBooksChecked( /* group */) {
      return Object.values(this.booksSelected).every(value => value)
    },
    partialBooksChecked( /* group */) {
      const bookValues = Object.values(this.booksSelected)
      const booksCount = bookValues.length
      const booksSelectedCount = bookValues.filter(value => value).length
      return booksSelectedCount < booksCount && booksSelectedCount > 0
    },
    bookCheckAll(group) {
      const toBeChecked = !this.allBooksChecked(group)
      Object.keys(this.booksSelected).forEach(bookId => { this.booksSelected[bookId] = toBeChecked })
      this.setState()
    },
    allBooksInGroupChecked(group) {
      return group.every(book => this.booksSelected[book.bookId])
    },
    partialBooksInGroupChecked(group) {
      return group.some(book => this.booksSelected[book.bookId]) && group.some(book => !this.booksSelected[book.bookId])
    },
    setState() {
      this.setBooks(this.booksSelected)
    },
    toggle(id) {
      this.booksSelected[id] = !this.booksSelected[id]
      this.setState()
    }
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    }
  },
  watch: {
    'searchStateUpdates.counter': 'updateData'
  },
  mounted: function () {
    this.loadData()
  }
}
