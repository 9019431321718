/* Understanding the store
 *
 * The store holds all global state. This includes the various search parameters as well as display parameters,
 * such as whether or not to show nikud, since the controls for nikud are in a different component than the one that
 * does the display.
 *
 * To update something in the store, import mutationHelpers from this file, and add them in your component in the
 * list of methods, like so:
 * methods: {
 *    your existing methods,
 *   ...mutationHelpers
 * }
 * Then you can call this.setBooks(newValue) or this.setLexemes(newValue), etc.
 * Do not call this.setQuery directly, though - it should be updated through the router, which will automatically
 * call setQuery.
 *
 * To read something from the store, import stateHelpers from this file, and add them in your component in the
 * list of computed values, like so:
 * computed: {
 *    your existing computed values,
 *   ...stateHelpers
 * }
 * Then you can use this.query or this.booksState, etc.
 *
 * To watch for changes to the query, set a watch on searchStateUpdates.counter:
 * watch: {
 *   'searchStateUpdates.counter': handler
 * }
 * The handler can check this.searchStateUpdates.lastUpdateType to avoid running an update if the last update came from
 * the component itself, e.g. if the last update was to wordForms, there's no need to re-run wordForms.
 */
import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export const NikudAndTeamim = {
  BOTH: 'BOTH',
  NIKUD: 'NIKUD',
  NONE: 'NONE'
}
const INITIAL_QUERY_STATE = {
  query: '',
  booksState: {},
  relatedState: [],
  lexemesState: [],
  wordFormsState: [],
  noResultsPossible: false,
  temporarySortBy: false
}
const searchStateUpdates = {
  lastUpdateType: 'initial',
  counter: 0
}
function updateURL(sortBy) {
  var urlString = window.location.href
  if (urlString.indexOf('order') !== -1) {
    var url = new URL(urlString)
    var queryString = url.search
    var searchParams = new URLSearchParams(queryString)
    if (searchParams.get('order') !== sortBy.replace('order', '').trim()) {
      searchParams.set('order', sortBy.replace('order', '').trim())
      url.search = searchParams.toString()
      urlString = url.toString()
      window.history.replaceState({ path: urlString }, '', urlString)
    }
  } else {
    urlString += (urlString.match(/[?]/g) ? '&' : '?') + 'order=' + sortBy.replace('order', '').trim()
    window.history.replaceState({ path: urlString }, '', urlString)
  }

}
function broadcastUpdate(type) {
  searchStateUpdates.lastUpdateType = type
  searchStateUpdates.counter++
}

function isPWADisplayMode() {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches
  return (navigator.standalone || isStandalone)
}

function getSortBy() {
  const sortBy = window.localStorage.getItem('search-sort-order')
  if (sortBy && isPWADisplayMode()) {
    return sortBy
  }
  return process.env.VUE_APP_SEARCH_TYPE === 'Bible' ? 'tanach order' : 'relevance'
}

function fixSortBy(sortBy) {
  if (sortBy === 'tanach order' && process.env.VUE_APP_SEARCH_TYPE !== 'Bible') {
    if (process.env.VUE_APP_SEARCH_TYPE === 'Talmud') {
      sortBy = 'talmud order'
    } else {
      sortBy = 'corpus order'
    }
  }
  return sortBy
}

export default new Vuex.Store({
  // this is the initial state for the whole app
  state: Object.assign({
    searchStateUpdates,
    showNikudAndTeamim: process.env.VUE_APP_SEARCH_TYPE === 'Bible' ? NikudAndTeamim.BOTH : NikudAndTeamim.NIKUD,
    resultFontSize: 20,
    resultsPerPage: 10,
    sortBy: getSortBy(),
    serverFailed: false
  }, _.cloneDeep(INITIAL_QUERY_STATE)),
  mutations: {
    SET_QUERY(state, query) {
      if (query === state.query) {
        return
      }
      Object.assign(state, _.cloneDeep(INITIAL_QUERY_STATE))
      state.query = query
      broadcastUpdate('query')
    },
    SET_BOOKS(state, books) {
      const bookNames = Object.keys(books)
      state.noResultsPossible = bookNames.length > 0 && !Object.values(books).includes(true)
      state.booksState = _.cloneDeep(books)
      broadcastUpdate('books')
    },
    SET_RELATED(state, related) {
      state.relatedState = _.cloneDeep(related)
      broadcastUpdate('related')
    },
    SET_LEXEMES(state, lexemes) {
      state.noResultsPossible = lexemes.length > 0 && lexemes.some(termData => Object.values(termData.selections).every(flag => !flag))
      state.lexemesState = _.cloneDeep(lexemes)
      broadcastUpdate('lexemes')
    },
    SET_WORD_FORMS(state, wordForms) {
      state.noResultsPossible = wordForms.length > 0 && wordForms.some(termData => Object.values(termData.selections).every(flag => !flag))
      state.wordFormsState = _.cloneDeep(wordForms)
      broadcastUpdate('wordForms')
    },
    SET_SHOW_NIKUD_AND_TEAMIM(state, show) {
      state.showNikudAndTeamim = show
    },
    ZOOM_IN(state) {
      state.resultFontSize += 2
    },
    ZOOM_OUT(state) {
      if (state.resultFontSize > 12) {
        state.resultFontSize -= 2
      }
    },
    SET_RESULT_FONT_SIZE(state, fontSize) {
      state.resultFontSize = fontSize
    },
    SET_RESULTS_PER_PAGE(state, resultsPerPage) {
      state.resultsPerPage = resultsPerPage
      broadcastUpdate('resultsPerPage')
    },
    SET_SORT_BY(state, sortBy) {
      const previous = resolveSortBy(state)
      state.temporarySortBy = false
      sortBy = fixSortBy(sortBy)
      window.localStorage.setItem('search-sort-order', sortBy)
      state.sortBy = sortBy
      updateURL(sortBy)
      if (previous !== sortBy)
        broadcastUpdate('sortBy')
    },
    SET_TEMPORARY_SORT_BY(state, sortBy) {
      sortBy = fixSortBy(sortBy)
      state.temporarySortBy = sortBy
      updateURL(sortBy)
      broadcastUpdate('sortBy')
    },
    SET_SERVER_FAILED(state, val) {
      state.serverFailed = val
    }
  },
  strict: debug
})

export function resolveSortBy(state) {
  return state.temporarySortBy ? state.temporarySortBy : state.sortBy
}

export const stateHelpers = Vuex.mapState({
  query: state => state.query,
  booksState: state => state.booksState,
  relatedState: state => state.relatedState,
  lexemesState: state => state.lexemesState,
  wordFormsState: state => state.wordFormsState,
  showNikudAndTeamim: state => state.showNikudAndTeamim,
  searchStateUpdates: state => state.searchStateUpdates,
  resultFontSize: state => state.resultFontSize,
  resultsPerPage: state => state.resultsPerPage,
  sortBy: state => resolveSortBy(state),
  noResultsPossible: state => state.noResultsPossible
})

export const mutationHelpers = Vuex.mapMutations({
  setBooks: 'SET_BOOKS',
  setQuery: 'SET_QUERY',
  setRelated: 'SET_RELATED',
  setLexemes: 'SET_LEXEMES',
  setWordForms: 'SET_WORD_FORMS',
  setShowNikudAndTeamim: 'SET_SHOW_NIKUD_AND_TEAMIM',
  zoomIn: 'ZOOM_IN',
  zoomOut: 'ZOOM_OUT',
  setResultFontSize: 'SET_RESULT_FONT_SIZE',
  setResultsPerPage: 'SET_RESULTS_PER_PAGE',
  setSortBy: 'SET_SORT_BY',
  setTemporarySortBy: 'SET_TEMPORARY_SORT_BY',
  setServerFailed: 'SET_SERVER_FAILED'
})
