<template>
  <Checkbox class="selectAll" :checked="checked" @change="$emit('change', $event)" :partial="partial">
    <p v-if="!hebrew"  class="f gray check-text">Select All</p>
    <p v-if="hebrew"  class="f gray check-text">בחר הכל</p>
  </Checkbox>
</template>

<script>
import Checkbox from './Checkbox'

export default {
  name: 'SelectAll',
  components: {Checkbox},
  computed: {
    hebrew () {
      // return this.$route.name.includes('hebrew')
      return this.$settings.hebrew
    }
  },
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: ['checked', 'partial']
}
</script>
