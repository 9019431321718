import {SearchType} from '../searchConfig.js'
import {NikudAndTeamim} from '../store'
import {mapState} from 'vuex'
export default {
  name: 'processTextMixin',
  data () {
    return {
      NikudAndTeamim
    }
  },
  computed: {
    ...mapState(['showNikudAndTeamim'])
  },
  methods: {
    preProcessResultText (sentence) {
      if (this.showNikudAndTeamim !== NikudAndTeamim.BOTH) {
        // remove trop, except for maqaf
        sentence = sentence.replace(/[\u0591-\u05af\u05bf\u05c0\u05c3-\u05C6]/g, '')
        if (SearchType.Bible) sentence = sentence.replace(/\u05bd/g, '')
        // maqaf becomes a space
        sentence = sentence.replace(/[\u05be]/g, ' ')
        // remove parsha markers
        sentence = sentence.replace(/ [פס](?=\s|$)/g, '')
      }
      if (SearchType.Bible && this.showNikudAndTeamim === NikudAndTeamim.NONE) {
        sentence = sentence.replace(/[\u05b0-\u05bd\u05c1\u05c2\u05c7]/g, '')
        // maqaf becomes a space
        sentence = sentence.replace(/[\u05be]/g, ' ')
      } else if (!(SearchType.Bible)) {
        if (this.showNikudAndTeamim === NikudAndTeamim.NONE) {
          sentence = sentence.replace(/[\u05b0-\u05bd\u05c1\u05c2\u05c7]/g, '') // 05bd: meteg: LEAVE letter before
        } else {
          sentence = sentence.replace(/.[\u05bd]/g, '') // 05bd = meteg - REMOVE letter before
        }
      }
      return sentence
    }
  }
}
