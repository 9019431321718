
<template>
  <b-modal id="howModal" v-model="show" ok-only @hide="hidePopup" size="lg" footer-class="border-top d-none d-sm-block"
    header-class="d-none d-sm-block" ok-variant="secondary">
    <template slot="modal-header">How it works</template>
    <div class="mobile-modal-top bg-secondary d-block d-sm-none">
      <div class="row">
        <div class="col-8">
          <h3>How it works</h3>
        </div>
        <div @click="hidePopup" class="close-icon col-4 text-right">
          <i class="fas fa-times"></i>
        </div>
      </div>
    </div>
    <div v-if="SearchType.Talmud" class="how-to-inner-content">
      <div v-if="SearchType.Talmud && !isSmallScreen">
        <p>
          Search the Talmud and Mishnah for words and phrases intuitively, with no need to worry about alternate
          spellings and multiple meanings.
          Search results of one or more words will include verses in which the
          searched words appear, with appropriate variations of spelling and word form.
          <br />Results appear in order of relevance, alternatively you can choose to have them presented in the
          customary
          order of the Talmud.
        </p>
        <h3>Manage your results</h3>
        <p>
          Next to the results you will find tools to help you broaden or narrow your search.
          <br />How are these tools used and how do they affect search results?
        </p>
        <h3>Meanings</h3>
        <p>
          For each search word select the desired meaning.
          <br />For example: for the search word <strong class="f-narkis">אריה</strong>
          you can also receive results including
          <strong class="f-narkis">
            הָאַרְיֵה
            ולַאֲרָיֹות
          </strong>
          as well as other forms of <strong>אריה</strong>, from which you can the select the relevant terms.
          Note, if your search term is specific, like <strong>לאריות</strong>, you will receive only that word form.
        </p>
        <h3>Books</h3>
        <p>
          Select the books in which you would like to find your search terms.<br />
          Note: If some filtered results appear highlighted in gray, it means that no verses were found.
          In order to receive results with matching verses, try to change your search word combination.
          Alternatively, add words with similar meanings to your search by using the “include synonyms” function.
        </p>
        <h3>Searching Phrases</h3>
        <p>
          Use quotation marks to search for a phrase or for a cluster of words that are adjacent to each other
        </p>
      </div>
      <div v-if="SearchType.Talmud && isSmallScreen">
        <p>
          Search the Talmud and Mishnah for words and phrases intuitively, with no need to worry about alternate
          spellings and multiple meanings.
          Search results of one or more words will include verses in which the
          searched words appear, with appropriate variations of spelling and word form.
          <br />Results appear in order of relevance, alternatively you can choose to have them presented in the
          customary
          order of the Talmud.
        </p>
        <h3>Manage your results</h3>
        <p>
          Next to the results you will find tools to help you broaden or narrow your search.
          <br />How are these tools used and how do they affect search results?
        </p>
        <h3>Meanings</h3>
        <p>
          For each search word select the desired meaning.
          <br />For example: for the search word <strong>אריה</strong>
          you can also receive results including
          <strong>
            הָאַרְיֵה
            ולַאֲרָיֹות
          </strong>
          as well as other forms of <strong>אריה</strong>, from which you can the select the relevant terms.
          Note, if your search term is specific, like <strong>לאריות</strong>, you will receive only that word form.
        </p>

        <h3>Books</h3>
        <p>
          Select the books in which you would like to find your search terms.<br />
          Note: If some filtered results appear highlighted in gray, it means that no verses were found.
          In order to receive results with matching verses, try to change your search word combination.
          Alternatively, add words with similar meanings to your search by using the “include synonyms” function.
        </p>
        <h3>Searching Phrases</h3>
        <p>
          Use quotation marks to search for a phrase or for a cluster of words that are adjacent to each other
        </p>
      </div>
      <small class="d-block border-top py-3 px-3">
        HEBREW TEXT DATABASE ETCBC4B
        Peursen, Prof. Dr. W.T. van (Eep Talstra Centre for Bible And Computing, VU University Amsterdam); Sikkel, M.
        Sc. C. (Eep Talstra Centre for Bible And Computing, VU University Amsterdam); Roorda, Dr. D. (Data Archiving and
        Networked Services, Royal Netherlands Academy of Arts and Sciences) (2015): Hebrew Text Database ETCBC4b. DANS.
        https://doi.org/10.17026/dans-z6y-skyh
      </small>
      <div class="px-3">
        <small>
          The William Davidson digital version of the Koren Talmud was vocalised by <a
            href="http://dicta.org.il/index.html" target="_blank">Dicta
            - The Israel Institute for Text Analysis</a> and released under
          <a href="https://creativecommons.org/licenses/by-nc/4.0/legalcode" target="_blank">CC BY-NC</a> license.
        </small>
      </div>
    </div>
    <div v-if="SearchType.Bible" class="how-to-inner-content">
      <div class="para">
        <p class="mb-1">The DICTA Bible search engine allows users to easily search for words and phrases in the Bible,
          with no need
          to worry about spelling and word forms. Search results of one or more words will include verses in which the
          searched words appear, with appropriate variations of spelling and word form.
        </p>
        <p class="mb-1">
          The results are sorted in Biblical order. 
          You can choose to sort them by relevance by clicking on the "Sort" menu above the 
          results.
        </p>
        
        <p class="mb-0">We may highlight interesting results that you may not have noticed, 
          these will appear above the results, under the heading 
          "You may be interested in the following results". 
          (only when the results are sorted by biblical order)</p>
      </div>
      <hr />
      <h2 class="heading-h2">Similar searches</h2>
      <p class="para">The "Similar searches" section will appear below the search results, 
        if there are suggestions to expand the search results. 
        The expansion will include the conversion of modern Hebrew to biblical Hebrew, 
        a different combination of search words and more. 
        <br>
        For example: for the search words "שמח תשמח" you will get a suggestion for "שמח תגיל", 
        for the search word "הללויה" you will get a suggestion for "הללו י-ה"</p>
      <h2 class="heading-h2">Manage your results</h2>
      <p class="para">
        Next to the results you will find tools to help you broaden or narrow your search.
        <br />How are these tools used and how do they affect search results?
      </p>
      <h3 class="heading-h3">Meanings</h3>
      <p class="para">
        For each search word select the desired meaning.
        <br />For example: if your search word is
        <strong class="f-narkis">דוד</strong>, you can choose to limit your search to only
        <strong class="f-narkis">דוד</strong>
        as in the name of a person, or
        <strong class="f-narkis">דוד</strong> as in a family member.
        In addition, you can expand your results to include other words with the same meaning.
        <br />For example: for the search word
        <strong class="f-narkis">אריה</strong> you can also receive results including
        <strong class="f-narkis">
          ליש
          לביא
        </strong> and
        <strong class="f-narkis">כפיר</strong>.
      </p>
      <h3 class="heading-h3">Word forms</h3>
      <p class="para">
        Limit the results to only word forms which you select. For example, if your search term is the
        generic term
        <strong class="f-narkis">אריה</strong> you will also receive occurrences of
        <strong class="f-narkis">לַאֲרָיֹות</strong> and
        <strong class="f-narkis">הָאַרְיֵה</strong> as well as other forms of
        <strong class="f-narkis">אריה</strong>, from which you can the select
        the
        relevant terms. Note, if your search term is specific, like
        <strong class="f-narkis">לאריות</strong>, you will receive only
        that word form.
      </p>
      <h3 class="heading-h3">Books</h3>
      <p class="para">Select the books in which you would like to find your search terms.</p>
      <p class="para">
        Note: If some filtered results appear highlighted in gray, it means that no biblical verses were found.
        In order to receive results with matching biblical verses, try to change your search word combination.
        Alternatively, add words with similar meanings to your search by using the “include synonyms” function.
      </p>

      <p class="para">
        For example: For the search combination of <strong class="f-narkis">“ששון חדווה”</strong>, no biblical verses
        were found, so they would appear in the filter highlighted in gray. In order to receive results with biblical
        matches, one could add synonyms found in the “include synonyms” function; for example, adding <strong
          class="f-narkis">“גילה”</strong> and <strong class="f-narkis">“רינה”</strong> would yield six verses.
      </p>
      <h3 class="heading-h3">Morphology and Semantic Details</h3>
      <p class="para">
        Next to each biblical verse, there are three blue dots. Click on the dots to view morphological and semantic
        details for each word in the biblical verse. The morphological details display the grammatical structure of a
        given word. For example: the morphology of
        <strong class="f-narkis">“עולם”</strong> would be: masculine, singular, noun, absolute.
      </p>
      <h3 class="heading-h3">Searching Phrases</h3>
      <p class="para">
        Use quotation marks to search for a phrase or for a cluster of words that are adjacent to each other. For
        example:
        <strong class="f-narkis">שלום בית</strong> will yield 116 results, whereas
        <strong class="f-narkis">“שלום בית”</strong> (with quotation marks) will yield 2 search results.
      </p>
      <h3>Excluding words from search results</h3>
      <p class="para">To remove results that include specific words from the search results, enter the word you want to
        exclude in the search input and place the minus sign in front of it, without a space. For example: if you
        searched for “מנשה” and don’t want results containing “אפרים”, enter the following text in the search input:
        “מנשה -אפרים”.</p>
      <hr />
      <h3>Example search terms and results:</h3>
      <ul class="list-ul">
        <li class="list-li">
          Search with root words
          <br />For example: search
          <strong class="f-narkis">ברא</strong>, and you will also get results for
          <strong class="f-narkis">ויברא</strong>.
        </li>
        <li class="list-li">
          Full spelling/partial spelling
          <br />For example: search
          <strong class="f-narkis">דוד</strong>, and you will also get results for
          <strong class="f-narkis">דויד</strong>.
        </li>
        <li class="list-li">
          Different suffixes, first/second/third person, male/female, singular/plural.
          <br />Second person -
          <strong class="f-narkis">אֹתְכָה/אֹותְךָ</strong>
          <br />Second person, female -
          <strong class="f-narkis">גַּרְתָּה/גַּרְתָּ</strong>
          <br />Third person -
          <strong class="f-narkis">כֻּלֹּה/כֻּלֹּו</strong>
        </li>

        <li class="list-li">
          Additional or missing
          <strong>א</strong>.
          <br />Search for
          <strong class="f-narkis">״ונטמאתם״</strong> , and get results for
          <strong class="f-narkis">״וְנִטְמֵתֶם״</strong>.
        </li>
        <li class="list-li">
          Additional or missing
          <strong class="f-narkis">ה</strong>.
          <br />Search for
          <strong class="f-narkis">״כָּמֹוךָ״</strong> , and get results for
          <strong class="f-narkis">״כָמֹכָה״</strong>.
        </li>
        <li class="list-li">
          Interchangeable letters
          <br />Search
          <strong class="f-narkis">״ימלא״</strong> , and get results for
          <strong class="f-narkis">״ימַלֵּה״</strong>.
          <br />Search
          <strong class="f-narkis">״וארסתיך״</strong>, and get results for
          <strong class="f-narkis">״וְאֵרַשְׂתִּיךְ״</strong>.
        </li>
        <li class="list-li">
          All the different ways the bible refers to G-d
          <br />Search for
          <strong class="f-narkis">״א-להים״</strong> (sic) and get results for that term (spelled fully) only where it
          refers to God.
        </li>
        <li class="list-li">
          Search with numbers
          <br />For example search for
          <strong>״127״</strong>, and get:
          <br />
          <strong class="f-narkis">״וַיִּהְיוּ חַיֵּי שָׂרָה מֵאָה שָׁנָה וְעֶשְׂרִים שָׁנָה וְשֶׁבַע שָׁנִים״</strong>
        </li>
      </ul>
      <hr />
      <small class="heading-h3 d-block px-3">HEBREW TEXT DATABASE ETCBC4B</small>
      <small class="para d-block px-3">
        Peursen, Prof. Dr. W.T. van (Eep Talstra Centre for Bible And Computing, VU University Amsterdam); Sikkel, M.
        Sc. C. (Eep Talstra Centre for Bible And Computing, VU University Amsterdam); Roorda, Dr. D. (Data Archiving and
        Networked Services, Royal Netherlands Academy of Arts and Sciences) (2015): Hebrew Text Database ETCBC4b. DANS.
        <a href="https://doi.org/10.17026/dans-z6y-skyh" target="_blank">https://doi.org/10.17026/dans-z6y-skyh</a>
      </small>
    </div>
  </b-modal>
</template>

<script>
import { SearchType } from '../searchConfig.js'
export default {
  name: 'howPopup',
  props: ['value'],
  computed: {
    SearchType() {
      return SearchType
    },
    show: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  data() {
    return {
      mediaQuery: null,
      isSmallScreen: false
    }
  },
  mounted: function () {
    this.mediaQuery = window.matchMedia('(max-width: 767px)')
    this.isSmallScreen = this.mediaQuery.matches
  },
  methods: {
    hidePopup() {
      this.show = false
      history.replaceState('', document.title, window.location.pathname + window.location.search)
    }
  }
}
</script>
<style scoped lang="scss">
a {
  text-decoration: underline;
}

h3 {
  margin: 0;
  text-decoration: underline;
}

.mobile-modal-top {
  height: 50px;
  padding: 15px;
  position: fixed;
  width: 100%;
  z-index: 1;
  top: 0;
  right: 0;

  h3 {
    text-decoration: none;
    font-size: 20px;
  }
}

@media screen and (min-width: 992px) {
  .how-to-inner-content {
    max-height: 70vh;
    overflow: auto;
  }
}

.list-li {
  padding: 0 0 20px 18px;
}

.list-li:before {
  transform: rotate(180deg);
  left: 0;
}

@media screen and (max-width: 991px) {
  .how-to-inner-content {
    // max-height: 98vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 45px;
    height: calc(100vh - 100px);
  }
}
</style>
