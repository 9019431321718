<template>
  <label class="control control--radio">
    <div class="chek-box-holder">
      <input type="radio" class="control-input" :name="name" :value="value" :checked="checked" @change="$emit('change', $event.target.value)"/>
      <div class="control__indicator radio-indicator"></div>
    </div>
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: 'Radiobox',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: ['checked', 'partial', 'name', 'value']
}
</script>
<style scoped src="../../assets/style/common-sidebar-checks.css"></style>
<style scoped>

</style>
