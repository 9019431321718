import { mutationHelpers, stateHelpers } from '../store'

// accepts either a list with a object containing selections for each term or a single selections list and
// returns just a flat selections list
function flattenList(obj) {
  if (obj.length > 0 && obj[0].selections) {
    const flatObj = {}
    obj.forEach(
      (listitem, index) => Object.keys(listitem.selections)
        .forEach(key => { flatObj[index + '_' + key] = listitem.selections[key] })
    )
    return flatObj
  } else {
    return obj
  }
}
export default {
  name: 'SidebarMixin',
  computed: {
    hebrew() {
      // return this.$route.name.includes('hebrew')
      return this.$settings.hebrew
    },
    ...stateHelpers
  },
  methods: {
    allChecked(list) {
      const flatList = flattenList(list)
      return Object.keys(flatList).every(key => flatList[key])
    },
    partialChecked(list) {
      const flatList = flattenList(list)
      const keys = Object.keys(flatList)
      return keys.some(key => flatList[key]) && keys.some(key => !flatList[key])
    },
    countSelected(list) {
      const flatList = flattenList(list)
      return Object.keys(flatList).filter(key => flatList[key]).length
    },
    ...mutationHelpers
  }
}
