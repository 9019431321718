<template>
  <!-- start wordforms slide -->
  <transition name="slide-mobile">
    <div class="next-slide-txt" id="wordform-slide" v-if="expanded">
      <div class="top-sidebar">
        <a class="back-button f blue" @click="$emit('toggle')"><span v-if="!hebrew">Back</span> <span
          v-if="hebrew">חזור</span></a>
      </div>
      <div class="text-holder">
        <h3 class="heading-h3" v-if="!hebrew"> Word Forms </h3>
        <h3 class="heading-h3" v-if="hebrew"> הטיות </h3>
        <ul class="mobile-accordion" v-for="(word, key) in wordForms" :key="word._id">
          <li class="mobile-accordion-li">
            <a class="mobile-accordion-link"
               :class="wordForms.length === 1 ? 'single' : (wordFormChildVisible[key] ? 'active' : '')"
               v-if="word.wordForms.length > 0 && wordForms.length > 0"
               @click="$set(wordFormChildVisible, key, !wordFormChildVisible[key])">
              <h3 class="heading-h3 m-0" v-if="!hebrew" dir="rtl"><span>{{word.term}}</span> Wordforms for </h3>
              <h3 class="heading-h3 m-0" v-if="hebrew" dir="ltr"> הטיות של <span>{{word.term}}</span></h3>
            </a>
            <transition name="slide-fade">
              <ul v-if="wordFormChildVisible[key] || wordForms.length < 2"
                  class="inner-menu-list mobile-accordion-menu-list">
                <li class="list-item" v-if="word.wordForms.length > 0">
                  <a class="list-link bg-half-white">
                    <Checkbox :checked="allChecked(wordFormsSelected[key].selections)"
                              :partial="partialChecked(wordFormsSelected[key].selections)"
                              @change="toggleOneSetWordForms(wordFormsSelected[key].selections)">
                      <h3 class="heading-h3 text-width" v-if="!hebrew" dir="ltr">Select All</h3>
                      <h3 class="heading-h3 text-width" v-if="hebrew" dir="rtl">בחר הכל</h3>
                    </Checkbox>
                  </a>
                </li>
                <li class="list-item" v-for="wordForm in word.wordForms" :key="wordForm._id">
                  <a class="list-link">
                    <Checkbox v-model="wordFormsSelected[key].selections[wordForm.wordForm]"
                              :class="{'no-results': wordForm.count === 0}"
                              >
                      <h3 class="heading-h3 text-width" v-if="!hebrew" dir="rtl"><span
                        class="text-numbers">({{wordForm.count}})</span><span
                        class="f-narkis">{{wordForm.wordForm}}</span></h3>
                      <h3 class="heading-h3 text-width" v-if="hebrew" dir="rtl"><span class="f-narkis">{{wordForm.wordForm}}</span><span
                        class="text-numbers">({{wordForm.count}})</span></h3>
                    </Checkbox>
                  </a>
                </li>
              </ul>
            </transition>
          </li>
        </ul>
      </div>

      <div class="sidebar-bottom-button-div">
        <button type="button" class="bt-lg sidebar-bottom-button button" @click="setMobileWordForms">
          <h2 class="f white btn-text">{{hebrew ? 'שמירה' : 'Save'}}</h2>
        </button>
      </div>
    </div>
  </transition>
  <!-- end wordforms slide -->
</template>
<script>
import Checkbox from './Checkbox'
// import ExpandableList from './ExpandableList'
import WordformsBase from './WordformsBase'
import _ from 'lodash'

export default {
  name: 'WordformsMobile',
  components: { /* ExpandableList, */ Checkbox },
  props: ['expanded'],
  mixins: [WordformsBase],
  methods: {
    setMobileWordForms () {
      this.$emit('updateSelectedWordFormsCount', this.wordFormsTotal)
      this.updateState()
     // this.$emit('done')
      this.mobileWordFormsSelected = _.cloneDeep(this.wordFormsSelected)
      this.$emit('toggle')
    }
  },
  computed: {
    wordFormsTotal () {
      return this.countSelected(this.mobileWordFormsSelected)
    },
    isExpanded () {
      return this.expanded
    }
  },
  watch: {
    wordFormsTotal () {
      this.$emit('updateSelectedWordFormsCount', this.wordFormsTotal)
    },
    isExpanded (val) {
      if (val) {
        this.wordFormsSelected = _.cloneDeep(this.mobileWordFormsSelected)
      }
    }
  }
}
</script>
<style scoped src="../../assets/style/common-mobile-sidebar.css"></style>
<style scoped>

</style>
