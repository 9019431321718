<template>
  <img class="loader-gif" src="../assets/loader.gif">
</template>
<script>
export default {
  name: 'Loader'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .loader-gif{
    display: block;
    width: 100px;
    margin: -50px 0 0 -50px;
    position: fixed;
    top: 60%;
    left: 61%;
  }
  @media screen and ( max-width: 991px ){
    .loader-gif{
      top: 50%;
      left: 50%;
    }
  }
</style>
