import { render, staticRenderFns } from "./ServerFailedPopup.vue?vue&type=template&id=27c10c36&"
import script from "./ServerFailedPopup.vue?vue&type=script&lang=js&"
export * from "./ServerFailedPopup.vue?vue&type=script&lang=js&"
import style0 from "./ServerFailedPopup.vue?vue&type=style&index=0&id=27c10c36&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports