import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import './plugins/axios'
require('bootstrap')
import './plugins/bootstrap-vue'
import router from './router'
import Vuetify from 'vuetify'
import VueAnalytics from 'vue-analytics'
import store from './store'
import dictaComponents from 'dicta-vue-components'
import VueCookies from 'vue-cookies'
import './registerServiceWorker'

Vue.config.productionTip = false
Vue.use(Vuetify)
Vue.use(VueCookies)
Vue.use(dictaComponents, { hebrew: true, useBodyClass: true })

Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_SEARCH_TYPE === 'Talmud' ? 'UA-103843509-13' : 'UA-103843509-7',
  router,
  debug: {
    enabled: true
  }
})

router.afterEach(to => {
  store.commit('SET_QUERY', to.query.text)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// credit: https://redfin.engineering/how-to-fix-the-refresh-button-when-using-service-workers-a8e27af6df68
if ('serviceWorker' in navigator) {
  let refreshing = false
  console.log('Registering reloading...')
  if (navigator.serviceWorker.controller) {
    navigator.serviceWorker.addEventListener('controllerchange',
      function () {
        if (refreshing) return
        refreshing = true
        window.location.reload()
      }
    )
  }
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('./service-worker.js').then(registration => {
      console.log('SW registered: ', registration)
    }).catch(registrationError => {
      console.log('SW registration failed: ', registrationError)
    })
  })
  // back button to close popups
  window.onpopstate = function () {
    if (window.location.href.indexOf('#') === -1) {
      if (this.document.getElementById('slideSidebarBtn')) {
        document.getElementById('slideSidebarBtn').click()
      }
      if (this.document.getElementById('mobileListCollapseBtn')) {
        document.getElementById('mobileListCollapseBtn').click()
      }
      if (this.document.getElementById('sorted-slide')) {
        document.getElementById('sidebar-btn').click()
      }
      if (this.document.getElementById('wordform-slide')) {
        document.getElementById('sidebar-btn').click()
      }
      if (this.document.getElementById('book-slide')) {
        document.getElementById('sidebar-btn').click()
      }
      if (this.document.getElementById('book-slide')) {
        document.getElementById('sidebar-btn').click()
      }
      if (this.document.getElementById('chapterModal')) {
        document.getElementsByClassName('close-icon')[0].click()
      }
      if (this.document.getElementById('howModal')) {
        document.getElementsByClassName('close-icon')[0].click()
      }
      if (this.document.getElementById('howModalHeb')) {
        document.getElementsByClassName('close-icon')[0].click()
      }
    }
  }
}
