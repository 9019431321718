<template>
  <div class="sidebar" v-show="dataReady">
    <div class="sidebar-desktop d-none d-sm-block">
      <TopFilerTool></TopFilerTool>
      <div class="row no-gutters" :class="{ he: hebrew }">
        <div class="col-12">
          <h3 class="sidebar-heading">{{ hebrew ? 'ניהול תוצאות' : 'Customize your results' }}</h3>
          <ul class="desktop-sidebar-accordion">
            <meanings-desktop @dataReady="dataReady = true" :expanded="expanded === 'meanings'"
              @lexemesFailed="(val) => lexemesFailed = val" @relatedFailed="(val) => relatedFailed = val"
              ref="meaningsDesktopRef" @toggle="toggle('meanings')"></meanings-desktop>
            <wordform-desktop @dataReady="dataReady = true" :expanded="expanded === 'wordForms'"
              ref="wordFormsDesktopRef" @wordformsFailed="(val) => wordformsFailed = val"
              @wordformsFirstFailed="(val) => wordformsFirstFailed = val" @toggle="toggle('wordForms')">
            </wordform-desktop>
            <books-desktop @dataReady="dataReady = true" :expanded="expanded === 'books'" @toggle="toggle('books')"
              @booksFailed="(val) => booksFailed = val" @updateBooksFailed="(val) => updateBooksFailed = val"
              ref="booksDesktopRef"></books-desktop>
            <!--<li >
              <a class="f black link" :class="{ active: relatedVisible}" @click="relateVisible()">Related Searches</a>
              <transition name="slide-fade">
                <div v-if="relatedVisible" class="slide">
                  <ul  class="control-group check">
                    <li  v-for="(word, wordIndex) in synonymData" :key="word._id">
                      <ul>
                        <li class="relatedSearch" v-for="(lexeme, lexemeKey) in word.lexemes" :key="lexeme._id" :class="{relatedActive:isRelatedCheck[wordIndex][lexemeKey]}">
                          <label class="control control&#45;&#45;checkbox">
                            <div class="chek-box-holder">
                              <input type="checkbox" v-model="relatedGroupVisible[wordIndex][lexemeKey]" @click="relatedGroupCheck(lexeme.related, wordIndex, lexemeKey)">
                              <div class="control__indicator"></div>
                            </div>
                            <b class="f gray"> {{lexeme.lexemeDescription.split(' ')[0]}} Related Searches [{{lexeme.related.length}}]</b><a class="mylink"  :class="{ selected:isRelatedSelected[wordIndex][lexemeKey]}" @click="relatedChildVisibility(wordIndex, lexemeKey)"></a>
                          </label>
                          <transition name="slide-fade">
                            <ul v-if="relatedChildVisible[wordIndex][lexemeKey]">
                              <li  v-for="synonym in lexeme.related" :key="synonym._id">
                                <label class="control control&#45;&#45;checkbox">
                                  <div class="chek-box-holder">
                                    <input type="checkbox" v-model="synonymsSelected[wordIndex].selections[synonym.relatedLexeme]" @click="relatedGroupStatus(lexeme.related, wordIndex, synonym.relatedLexeme,lexemeKey)">
                                    <div class="control__indicator"></div>
                                  </div>
                                  <p class="f gray">{{ synonym.relatedDescription }}</p>
                                </label>
                              </li>
                            </ul>
                          </transition>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </transition>
            </li>-->
          </ul>
        </div>
      </div>
    </div>

    <transition name="slide-mobile">
      <div class="search-mobile-sidebar" :class="{ he: hebrew }" v-if="isSmallScreen" v-show="mobileSidebar"
        id="mobile-sidebar" @click="clickOnSideBar($event)">
        <div class="mobile-inner-sidebar" id="innerSidebar">
          <div class="mobile-sidebar-top-header">
            <h2 class="top-header-heading" v-if="!hebrew">
              <img src="../../assets/settings.png" class="customize-icon" />Customize
              your results
            </h2>
            <h2 class="top-header-heading" dir="rtl" v-if="hebrew">התאמה אישית של התוצאות<img
                src="../../assets/settings.png" class="customize-icon" /></h2>
          </div>
          <div class="mobile-sidebar-content-div">
            <ul class="mobile-menu-list">
              <li class="list-item">
                <a class="mobile-menu-link" id="sort-mobile-menu-btn" @click="expanded = 'sortOrder'">
                  <h3 class="heading-h3 margin-bottom">{{ hebrew ? 'מיון' : 'Sorted' }}</h3>
                  <p v-if="sortBy === 'relevance'" class="f blue list-text m-0">
                    <span>{{ hebrew ? 'הרלוונטיות' : 'Relevance' }}</span>
                  </p>
                  <p v-else class="f blue list-text m-0">
                    <span v-if="!hebrew">{{ SearchTypeString }} Order</span><span v-if="hebrew">סדר
                      ה{{ SearchTypeString }}</span>
                  </p>
                </a>
              </li>
            </ul>
            <hr class="divider line">
            <h3 class="heading-h3">{{ hebrew ? 'סינון' : 'Filter' }}</h3>
            <ul class="mobile-menu-list">
              <li class="list-item double-list-item" v-if="$settings.debug || !(SearchType.Talmud)">
                <a class="mobile-menu-link" id="meanings-mobile-menu-btn"
                  @click="expanded = !lexemesFailed && !relatedFailed ? 'meanings' : ''">
                  <h3 class="heading-h3 margin-bottom">{{ hebrew ? 'משמעויות' : 'Meanings and synonyms' }}</h3>
                  <div class="retry text-muted" v-if="relatedFailed || lexemesFailed">
                    <small>{{ hebrew ? 'אופס יש לנו בעיה!' : 'Meanings search failed' }}</small>
                    <b-btn variant="link" class="text-muted bg-transparent border-0 p-0 mx-3"
                      @click="this.$refs.meaningsDesktopRef.loadLexemeData">
                      <i class="fas fa-redo-alt"></i>
                      <span class="text-underline mx-1">{{ hebrew ? 'נסו שוב' : 'Try again' }}</span>
                    </b-btn>
                  </div>
                  <p class="f blue list-text m-0">
                    <span v-if="selectedLexemeTotal > 0">{{ selectedLexemeTotal }} {{ hebrew ? 'משמעויות נבחרו' :
                        'Meanings selected'
                    }}</span>
                    <span v-if="selectedLexemeTotal === 0">{{ hebrew ? 'לא נבחרו משמעויות' : 'No meanings selected' }}
                    </span>
                  </p>
                </a>
              </li>
              <li class="list-item double-list-item">
                <a class="mobile-menu-link" id="wordforms-mobile-menu-btn"
                  @click="expanded = !wordformsFailed && !wordformsFirstFailed ? 'wordForms' : ''">
                  <h3 class="heading-h3 margin-bottom">{{ hebrew ? 'הטיות' : 'Words forms' }}</h3>
                  <div class="retry text-muted" v-if="wordformsFailed || wordformsFirstFailed">
                    <small>{{ hebrew ? 'אופס יש לנו בעיה!' : 'Wordforms search failed' }}</small>
                    <b-btn variant="link" class="text-muted bg-transparent border-0 p-0 mx-3"
                      @click="this.$refs.wordFormsDesktopRef.getData(wordformsFirstFailed)">
                      <i class="fas fa-redo-alt"></i>
                      <span class="text-underline mx-1">{{ hebrew ? 'נסו שוב' : 'Try again' }}</span>
                    </b-btn>
                  </div>
                  <p class="f blue list-text m-0" v-else>
                    <span v-if="selectedWordFormsTotal > 0">{{ selectedWordFormsTotal }} {{ hebrew ? 'הטיות נבחרו' :
                        'Words forms selected'
                    }}</span>
                    <span v-if="selectedWordFormsTotal === 0">{{ hebrew ? 'לא נבחרו הטיות' : 'No Words forms selected'
                    }} </span>
                  </p>
                </a>
              </li>
            </ul>
            <hr class="divider-dots">
            <ul class="mobile-menu-list">
              <li class="list-item">
                <a class="mobile-menu-link" id="books-mobile-menu-btn"
                  @click="expanded = !booksFailed && !updateBooksFailed ? 'books' : ''">
                  <h3 class="heading-h3 margin-bottom">{{ hebrew ? 'ספרים' : 'Books' }}</h3>
                  <div class="retry text-muted" v-if="booksFailed || updateBooksFailed">
                    <small>{{ hebrew ? 'אופס יש לנו בעיה!' : 'Book search failed' }}</small>
                    <b-btn variant="link" class="text-muted bg-transparent border-0 p-0 mx-3"
                      @click="booksFailed ? this.$refs.booksDesktopRef.loadData() : this.$refs.booksDesktopRef.updateData()">
                      <i class="fas fa-redo-alt"></i>
                      <span class="text-underline mx-1">{{ hebrew ? 'נסו שוב' : 'Try again' }}</span>
                    </b-btn>
                  </div>
                  <p class="f blue list-text m-0" v-else>
                    <span v-if="selectedBooksTotal > 0">{{ selectedBooksTotal }}
                      {{ hebrew ? 'ספרים נבחרים' : 'Books selected' }}</span>
                    <span v-if="selectedBooksTotal === 0">{{ hebrew ? 'לא נבחרו ספרים' : 'No books selected' }} </span>
                  </p>
                </a>
              </li>
            </ul>
            <hr class="divider line">
            <ul class="mobile-menu-list">
              <li class="list-item">
                <a class="mobile-menu-link" id="display-mobile-menu-btn" @click="expanded = 'display'">
                  <h3 class="heading-h3 margin-bottom">{{ hebrew ? 'הגדרות תצוגה' : 'Display Details' }}</h3>
                  <p class="f blue list-text m-0">
                    <span v-if="SearchType.Bible">{{ hebrew ? 'ניקוד, טעמים, גודל טקסט' : 'Nikud, Te\'amim, Font size'
                    }}</span>
                    <span v-else>{{ hebrew ? 'ניקוד, גודל טקסט' : 'Nikud, Font size' }}</span>
                  </p>
                </a>
              </li>
            </ul>
            <ul class="mobile-menu-list">
              <li class="list-item">
                <a class="mobile-menu-link" id="download-mobile-menu-btn" @click="expanded = 'download'">
                  <h3 class="heading-h3 margin-bottom">{{ hebrew ? 'הורדת קובץ' : 'Download details' }}</h3>
                  <p class="f blue list-text m-0">
                    <span>HTML, TXT, CSV</span>
                  </p>
                </a>
              </li>
            </ul>
          </div>
          <div class="sidebar-bottom-button-div">
            <button type="button" id="slideSidebarBtn" class="bt-lg sidebar-bottom-button button"
              @click="slideMobileSidebar">
              <h2 class="f white btn-text">{{ hebrew ? 'חזרה' : 'Back' }}</h2>
            </button>
          </div>
        </div>
        <sort-order-mobile :expanded="expanded === 'sortOrder'" @toggle="toggle('sortOrder')"
          @done="slideMobileSidebar"></sort-order-mobile>
        <meanings-mobile :expanded="expanded === 'meanings'" @toggle="toggle('meanings')" @done="slideMobileSidebar"
          @updateSelectedMeaningsCount="count => selectedLexemeTotal = count"></meanings-mobile>
        <wordforms-mobile :expanded="expanded === 'wordForms'" @toggle="toggle('wordForms')" @done="slideMobileSidebar"
          @updateSelectedWordFormsCount="count => selectedWordFormsTotal = count"></wordforms-mobile>
        <books-mobile :expanded="expanded === 'books'" @toggle="toggle('books')" @done="slideMobileSidebar"
          @updateSelectedBooksCount="count => selectedBooksTotal = count"></books-mobile>
        <display-detail-mobile :expanded="expanded === 'display'" @toggle="toggle('display')"
          @done="slideMobileSidebar"></display-detail-mobile>
        <download-detail-mobile :expanded="expanded === 'download'" @toggle="toggle('download')"
          @done="slideMobileSidebar"></download-detail-mobile>
      </div>
    </transition>

  </div>
</template>
<script>
import { mutationHelpers, stateHelpers } from '../../store'
import TopFilerTool from './TopFilterTool'
import MeaningsDesktop from './MeaningsDesktop'
import BooksDesktop from './BooksDesktop'
import WordformDesktop from './WordformsDesktop'
import MeaningsMobile from './MeaningsMobile'
import SortOrderMobile from './SortOrderMobile'
import BooksMobile from './BooksMobile'
import WordformsMobile from './WordformsMobile'
import DisplayDetailMobile from './DisplayDetailMobile'
import DownloadDetailMobile from './DownloadDetailMobile'
import { SearchType } from '../../searchConfig.js'

export default {
  name: 'sidebar',
  components: {
    TopFilerTool,
    WordformsMobile,
    BooksMobile,
    SortOrderMobile,
    MeaningsMobile,
    WordformDesktop,
    BooksDesktop,
    MeaningsDesktop,
    DisplayDetailMobile,
    DownloadDetailMobile
  },
  props: ['queryText', 'mobileSidebar'],
  data() {
    return {
      dataReady: true,
      expanded: 'none',
      isSmallScreen: false,
      mediaQuery: null,
      iOS: false,
      isActive: true,
      visible: false,
      sortedSlide: false,
      meaningsVisible: false,
      booksVisible: false,
      relatedVisible: false,
      selectedLexemeTotal: 0,
      selectedWordFormsTotal: 0,
      selectedBooksTotal: 0,
      expandSearch: [],
      booksFailed: false,
      updateBooksFailed: false,
      lexemesFailed: false,
      relatedFailed: false,
      wordformsFailed: false,
      wordformsFirstFailed: false,
    }
  },
  computed: {
    hebrew() {
      // return this.$route.name.includes('hebrew')
      return this.$settings.hebrew
    },
    SearchType() {
      return SearchType
    },
    SearchTypeString() {
      return SearchType.Bible ? (this.hebrew ? 'תנ"ך' : 'Bible') : (SearchType.Talmud ? (this.hebrew ? 'תלמוד' : 'Talmud') : (this.hebrew ? 'קורפוס' : 'Corpus'))
    },
    ...stateHelpers
  },
  mounted: function () {
    if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i)) {
      this.iOS = true
    }
  },
  beforeMount() {
    // if this would be set up in "mounted", it would render the desktop components before updating isSmallScreen to hide them
    this.mediaQuery = window.matchMedia('(max-width: 991px)')
    this.mediaQuery.addListener(this.setSmallScreen)
    this.isSmallScreen = this.mediaQuery.matches
  },
  beforeDestroy() {
    this.mediaQuery.removeListener(this.setSmallScreen)
  },
  methods: {
    setSmallScreen(evt) {
      this.isSmallScreen = evt.matches
    },
    toggle(group) {
      if (!this.isSmallScreen) {
        window.scroll(0, 0)
      }
      this.expanded = (this.expanded !== group) ? group : 'none'
    },
    slideMobileSidebar() {
      this.expanded = 'none'
      this.$emit('hideSidebar', false)
      history.replaceState('', document.title, window.location.pathname + window.location.search)
    },
    clickOnSideBar(e) {
      if (e.target.id.toString() === 'mobile-sidebar') {
        this.slideMobileSidebar()
      }
    },
    ...mutationHelpers
  }
}
</script>

<style scoped>
.sidebar {
  padding: 0 10px 0 0;
  position: relative;
  height: calc(100% - 5px) !important;
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
  .sidebar {
    padding: 0;
  }
}

.he .sidebar {
  padding: 0 0 0 10px;
}

.desktop-sidebar-accordion {
  width: 100%;
  margin: 0;
  border-top: none;
  padding: 18px 0;
  list-style: none;
  overflow: hidden;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .3s;
}

.slide-fade-enter {
  transform: translateY(10px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.slide-mobile-enter-active {
  transition: all .5s ease;
}

.slide-mobile-leave-active {
  transition: all .3s ease;
}

.slide-mobile-enter

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(-10px);
  opacity: 0;
}

.sidebar-heading {
  border-bottom: 2px solid #C8C8C8;
  padding-bottom: 5px;
  margin-bottom: 0;
}

/*Start Mobile View*/

@media screen and (max-width: 991px) {
  .customize-icon {
    margin-right: 5px;
    margin-top: -5px;
  }

  .he .customize-icon {
    margin-left: 5px;
    margin-top: 4px;
    margin-right: 0;
    float: right;
  }

  .class-mobile .search-mobile-sidebar {
    padding: 0;
    position: fixed;
    top: 0;
    left: auto;
    right: 0;
    bottom: 0;
    /*height: calc(100% - 80px);*/
    height: 100%;
    width: 100%;
    width: 90%;
    min-width: 280px;
    max-width: 100%;
    z-index: 99999999;
    background-color: rgba(0, 0, 0, 0.22);
  }

  .he .class-mobile .search-mobile-sidebar {
    left: 0;
    right: auto;
  }

  .mobile-inner-sidebar {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0 0 0 10%;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
    overflow-y: hidden;
  }

  .he .mobile-inner-sidebar {
    margin: 0 10% 0 0;
  }

  .mobile-sidebar-top-header {
    height: 56px;
    padding: 19px 19px;
    background-color: #fbfbfb;
    border-bottom: solid 1px #c2c2c2;
  }

  .he .top-header-heading {
    font-size: 18px;
  }

  .sidebar-bottom-button-div {
    width: 100%;
    height: 80px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    border: solid 1px #c8c8c8;
    padding: 15px 20px 15px;
    /*z-index: 9999999;*/
  }

  .sidebar-bottom-button {
    border: none;
    width: 100%;
    height: 48px;
    border-radius: 2px;
    background-color: #007ee5;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5);

  }

  .sidebar-bottom-button:focus {
    outline: none;
  }

  .btn-text {
    width: auto;
    text-align: center;
    text-transform: uppercase;
  }

  .bt-lg .btn-text {
    line-height: 45px;
  }

  .mobile-sidebar-content-div {
    padding: 20px 20px 80px 20px;
    height: calc(100vh - 60px);
    overflow-y: auto;
    width: 100%;
  }

  .mobile-menu-list {
    overflow: visible;
    list-style: none;
    margin-bottom: 24px;
    padding: 0;
    border: none;
  }

  .list-item {
    margin: 0 0 -1px;
  }

  .mobile-menu-link {
    display: block;
    height: 64px;
    padding: 9px 14px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
    border: solid 1px #c2c2c2;
    position: relative;
  }

  .mobile-menu-link:after {
    width: 10px;
    height: 18px;
    position: absolute;
    top: 50%;
    right: 15px;
    content: '';
    margin: -9px 0 0;
    background: url("../../assets/ic_next_mobile.png") no-repeat;
  }

  .he .mobile-menu-link:after {
    right: auto;
    left: 15px;
    background: url("../../assets/ic_prev_mobile.png") no-repeat;
  }

  .margin-bottom {
    margin: 0 0 5px;
  }
}

@media screen and (max-width: 768px) {
  .mobile-inner-sidebar {
    width: 100%;
    position: relative;
    margin: 0;
  }

  .he .mobile-inner-sidebar {
    margin: 0;
  }
}

@media screen and (max-height: 820px) {
  /*.sidebar-bottom-button-div {*/
  /*position: static;*/
  /*}*/
}
</style>
