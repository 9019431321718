const hebrewKeyboard = {
    'q': '\u002F', 'w': '\u0027',
    'e': '\u05E7', 'r': '\u05E8', 't': '\u05D0',
    'y': '\u05D8', 'u': '\u05D5', 'i': '\u05DF',
    'o': '\u05DD', 'p': '\u05E4', 'a': '\u05E9',
    's': '\u05D3', 'd': '\u05D2', 'f': '\u05DB',
    'g': '\u05E2', 'h': '\u05D9', 'j': '\u05D7',
    'k': '\u05DC', 'l': '\u05DA', ';': '\u05E3',
    'z': '\u05D6', 'x': '\u05E1', 'c': '\u05D1',
    'v': '\u05D4', 'b': '\u05E0', 'n': '\u05DE',
    'm': '\u05E6', ',': '\u05EA', '.': '\u05E5',
    '/': '\u002E', '\'': ','
}
const specialEnglishKeys = new Set([
    ';|Semicolon', '\'|Quote', ',|Comma', '.|Period', '/|Slash'
])
export default {
    name: 'englishToHebrewTexMixin',
    data() {
        return {
            translateEnglishKeys: true,
            translatingEnglishKeys: false
        }
    },
    methods: {
        stopTranslating() {
            this.translateEnglishKeys = false
            this.translatingEnglishKeys = false
            this.$emit('translatingEnglishKeys', false)
        },
        englishKeyEventToHebrew(keyEvt, input) {
            if (!this.translateEnglishKeys) {
                return
            }
            // a Hebrew key was pressed, don't translate later
            if (/^[א-ת]$/.test(keyEvt.key && this.translatingEnglishKeys)) {
                this.translateEnglishKeys = false
                this.translatingEnglishKeys = false
                return
            }
            // if this is a regular English key that could be a Hebrew letter
            if (!(keyEvt.metaKey || keyEvt.shiftKey || keyEvt.ctrlKey || keyEvt.altKey)
                && (
                    /^[a-z]$/.test(keyEvt.key)
                    // we only translate punctuation while using an English keyboard, but if someone is using Hebrew,
                    // they should be able to type a comma without having it convert to a ת
                    || specialEnglishKeys.has(keyEvt.key + '|' + keyEvt.code)
                )) {
                keyEvt.preventDefault()
                this.translatingEnglishKeys = true
                this.$emit('translatingEnglishKeys', true)
                // someone may be typing in the middle of the input box
                const startpos = input.selectionStart || 0
                const endpos = input.selectionEnd || startpos
                // replace the selection (in the case of a cursor, it's the same as a selection of 0 characters)
                input.value = input.value.substring(0, startpos) + hebrewKeyboard[keyEvt.key.toLowerCase()] + input.value.substring(endpos)
                // put the cursor back where the user is expecting it
                input.setSelectionRange(startpos + 1, startpos + 1)
                // trigger an input event so Vue will update v-model
                input.dispatchEvent(new Event('input', { bubbles: true }))
                // return true in case the caller wants to test to see if this function handled the key
                return true
            }
            return false
        },
    }
}
