<template>
  <div id="home">
    <search-header id="searchHeader" :hideSearch="true" class="d-block d-sm-none"></search-header>
    <div class="container">
      <div class="homepage">
        <!--<div v-if="!disableEnglishUI" class="lang-switch d-block d-sm-none pt-3 mr-auto" @click="changeLanguage">{{ hebrew ? 'English' : 'עברית' }}</div>-->
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="homepage-logo-div">
              <router-link to="/">
                <div v-if="!hebrew" class="pt-4 pt-sm-0 logo-holder home-logo-holder">
                  <span v-if="SearchType.Talmud">
                    <img src="../assets/Logo Talmud_Search_White_Circle.png" class="home-logo-img">
                    <span class="home-logo-title">Talmud search</span>
                  </span>
                  <span v-else-if="SearchType.Bible">
                    <img src="../assets/Logo Bible_Search_White_Circle.png" class="home-logo-img">
                    <span class="home-logo-title">Bible search</span>
                  </span>
                  <span v-else-if="dictaLibraryCorpus">
                    <h2 class="d-inline-block bg-primary rounded-circle text-white text-center ml-2 pt-2">
                      <strong>ד</strong>
                    </h2>
                    <span class="home-logo-title">The Dicta Library</span>
                  </span>
                  <span v-else>
                    <img src="../assets/Logo Bible_Search_White_Circle.png" class="home-logo-img">
                    <span class="home-logo-title">Generic search</span>
                  </span>
                  <em class="mobile-home-logo-title">Dicta</em>
                </div>
                <div v-if="hebrew" class="pt-4 pt-sm-0 logo-holder home-logo-holder">
                  <span v-if="SearchType.Talmud">
                    <img src="../assets/Logo Talmud_Search_White_Circle.png" class="home-logo-img">
                    <span class="home-logo-title">חיפוש בתלמוד</span>
                  </span>
                  <span v-else-if="SearchType.Bible">
                    <img src="../assets/Logo Bible_Search_White_Circle.png" class="home-logo-img">
                    <span class="home-logo-title">חיפוש בתנ"ך</span>
                  </span>
                  <span v-else-if="dictaLibraryCorpus">
                    <h2 class="d-inline-block bg-primary rounded-circle text-white text-center ml-2 pt-2">
                      <strong>ד</strong>
                    </h2>
                    <span class="home-logo-title">הספריה של דיקטה</span>
                  </span>
                  <span v-else>
                    <img src="../assets/Logo Bible_Search_White_Circle.png" class="home-logo-img">
                    <span class="home-logo-title">חיפוש כללי</span>
                  </span>
                  <em class="mobile-home-logo-title">דיקטה</em>
                </div>
              </router-link>
            </div>
            <!--<div class="logo hidden-sm hidden-md hidden-lg">-->
            <!--<router-link to="/">-->
            <!--<img v-if="!hebrew" src="../assets/mobile-logo.png">-->
            <!--<img v-if="hebrew" src="../assets/mobile-logo-he.png">-->
            <!--</router-link>-->
            <!--</div>-->
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <form class="home-search-form" @submit.prevent="search()">
              <input id="search_box" type="search" dir="rtl" v-model="queryText" ref="search_box"
                class="form-control home-search-fControl" @keydown="processKey" autofocus>
              <button id="mobile_search_button"
                class="home-search-form-btn bt button raised hoverable mobile-home-searchbtn"
                v-bind:class="{ devmode: devMode }" type="button" @click="search">
                <div class="anim"></div>
                <img class="home-search-form-icon" src="../assets/mobile_ic_search.png">
              </button>
              <button id="search_button" class="home-search-form-btn bt button raised hoverable desktop-home-searchbtn"
                v-bind:class="{ devmode: devMode }" type="button" @click="search">
                <div class="anim"></div>
                <img class="home-search-form-icon" src="../assets/ic_search_white.png">
              </button>
            </form>
            <small class="py-2 translate-msg d-block position-absolute" :class="hebrew ? 'text-right' : 'text-left'"
              v-if="translatingEnglishKeys">
              {{ hebrew ? 'תרגום המקלדת מאנגלית לעברית פעיל.' : 'Auto translating English keys to Hebrew.' }}
              <a href class="text-primary" @click.prevent="stopTranslating">{{ hebrew ? 'הפסק' : 'Stop' }}</a>
            </small>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-12 col-md-12 col-sm-12 mt-5">
            <a @click="showAbout = true" href="#" class="home-howtouse" v-if="!hebrew"><span
                class="text-primary home-howtouse-title">How to use?</span></a>
            <a @click="showAboutHeb = true" href="#" class="home-howtouse" v-else><span
                class="text-primary home-howtouse-title">איך זה עובד? </span></a>
          </div>
        </div>
      </div>
    </div>
    <how-popup v-model="showAbout"></how-popup>
    <how-popup-hebrew v-model="showAboutHeb"></how-popup-hebrew>
  </div>
</template>
<script>
import HowPopup from './howPopup.vue'
import HowPopupHebrew from './howPopupHebrew.vue'
import SearchHeader from '@/components/searchHeader.vue'
import { SearchType } from '../searchConfig.js'
import config from '../config'
import englishToHebrewTexMixin from '@/mixins/englishToHebrewTexMixin'
import { isThisAppInDevMode } from '../devMode'

export default {
  name: 'Home',
  components: {
    HowPopupHebrew,
    HowPopup,
    SearchHeader
  },
  mixins: [englishToHebrewTexMixin],
  computed: {
    devMode() {
      return isThisAppInDevMode('search')
    },
    SearchType() {
      return SearchType
    },
    dictaLibraryCorpus() {
      return config.corpusType === 'dicta-library'
    },
    disableEnglishUI() {
      return config.DISABLE_ENGLISH_UI
    },
    hebrew() {
      return this.$settings.hebrew
    }
  },
  data() {
    return {
      queryText: '',
      showAbout: false,
      showAboutHeb: false,
      iOS: false
    }
  },
  methods: {
    changeLanguage() {
      this.$settings.hebrew = !this.$settings.hebrew
      this.$cookies.set('DICTA_USE_HEBREW', this.$settings.hebrew, -1, '/', 'dicta.org.il')
      if (this.$settings.hebrew) {
        document.body.classList.add('he')
      } else {
        document.body.classList.remove('he')
      }
    },
    openHowItWorks() {
      if (this.hebrew) {
        this.showAboutHeb = true
      } else {
        this.showAbout = true
      }
    },
    search() {
      if (this.queryText.length > 0) {
        this.$router.push({ name: 'Result', query: { text: this.queryText, order: 'tanach' } })
      }
    },
    processKey(keyEvt) {
      if (keyEvt.key === 'Enter') {
        keyEvt.preventDefault()
        // on mobile, the change event doesn't fire until the input loses focus
        // so queryText may not be updated
        this.queryText = this.$refs.search_box.value
        this.search()
      } else {
        this.englishKeyEventToHebrew(keyEvt, this.$refs.search_box)
      }
    }
  },
  mounted: function () {
    this.iOS = /iPhone|iPad|iPod/i.test(navigator.userAgent)
    if (this.iOS) {
      document.body.classList.add('iphone')
    }
  },
  watch: {
    '$route': 'loadSite'
  }
}
</script>

<style scoped and lang="scss">
.lang-switch {
  text-decoration: underline;
}

#home {
  position: relative;
  z-index: 2;
}

.home-logo-holder {
  display: block;
  height: 100%;
}

.home-logo-img {
  height: 80px;
  margin: 0 12px 0 12px;
  border-radius: 50%;
}

.home-logo-img,
.home-logo-title {
  color: #000;
  font-size: 34px;
  line-height: 36px;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
}

.rounded-circle.text-center {
  width: 45px;
  height: 45px;
  margin-top: 35px;
}

.homepage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(92vh - 130px);
}

.homepage-logo-div {
  width: 388px;
  height: 80px;
  position: static;
  margin: 0 auto 70px;
  text-align: center;

  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.he .homepage-logo-div {
  width: 326px;
}

.home-search-form-btn {
  position: absolute;
  top: 0;
  left: 0;
  xz-index: 100;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  width: 55px;
  height: 52px;
  border-radius: 0;
  background-color: #007ee5;
  border: 0;
}

.home-search-form-btn.devmode {
  background-color: red;
}

.he .home-search-form-btn {
  right: auto;
  left: 0;
}

.home-howtouse-title {
  display: inline-block;
  vertical-align: top;
  font-size: 18px;
  line-height: 21px;
  padding: 0 0 5px;
  text-decoration: underline;
}

.home-howtouse {
  position: static;
}

.home-search-form {
  width: 545px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #C2C2C2
}

.he .home-search-form {
  width: 545px;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #C2C2C2;
}

.home-search-fControl[type="search"] {
  padding: 10px 15px 10px 55px;
  text-align: right;
  position: relative;
  border-radius: 0;
}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

.he .home-search-fControl[type="search"] {
  text-align: right;
  padding: 10px 15px 10px 55px;
}

.home-search-form-icon {
  display: block;
  margin: 0 auto;
}

.mobile-home-searchbtn,
.mobile-home-logo-title {
  display: none;
}

@media screen and (max-width: 991px) {
  .homepage {
    justify-content: start;
  }

  .homepage-logo-div {
    width: 100%;
    height: 150px;
    margin: 0 auto 40px;
  }

  .home-search-form {
    width: 100%;
    margin: 0 auto 30px;
    border-radius: 1px;
  }

  .he .home-search-form {
    width: 100%;
  }

  .home-search-form-btn {
    height: 56px;
  }

  .desktop-home-searchbtn {
    display: none;
  }

  .mobile-home-searchbtn {
    display: inline-block;
    background-color: #ffffff;

  }

  .home-howtouse-title {
    font-size: 16px;
    line-height: 16px;
    padding: 0 0 3px;
  }

  .home-logo-img {
    margin: 0 auto 15px;
    display: block;
  }

  .home-logo-title {
    font-size: 24px;
    line-height: 27px;
  }

  .mobile-home-logo-title {
    font-size: 16px;
    line-height: 19px;
    font-style: normal;
  }
}

@media screen and (max-width: 767px) {
  #home {
    min-height: calc(100vh - 108px);
  }

  .iphone #home {
    min-height: calc(100vh - 123px);
  }
}
</style>
