<template>
    <li class="accordion-li wordform-accordion">
        <a id="word_forms" class="f black link" :class="{active: expanded}" @click="$emit('toggle')"><span v-if="!hebrew">Word Forms</span><span
                v-if="hebrew">הטיות</span></a>
                <p v-if="loading" class="new-label">{{ hebrew ? 'טוען נתונים' : 'Loading'}}...</p>
        <div v-if="!expanded && !wordformsFailed && !wordformsFirstFailed">
            <p id="new-label-wordform" v-if="!hebrew" dir="ltr" class="f gray new-label"> {{countSelected(wordFormsSelected)}} Word forms
                selected </p>
            <p id="new-label-wordform-he" v-if="hebrew" dir="rtl" class="f gray new-label"> {{countSelected(wordFormsSelected)}} הטיות
                נבחרו </p>
        </div>
        <transition name="slide-fade">
            <div  v-if="expanded" class="slide"
                 :class="{addNewLineWord:(wordForms.length !== 1)}">
                <ul class="inner-accordion" v-if="wordForms[0].wordForms.length < 1">
                    <li class="inner-accordion-li inner-accordion-result-alert"><span
                            v-if="!hebrew">No Results</span><span v-if="hebrew">לא נמצאו תוצאות</span></li>
                    <!--<li v-if="wordForms[0].wordForms.length >= 1">-->
                        <!--<Checkbox-->
                                  <!--:checked="allChecked(wordFormsSelected)"-->
                                  <!--:partial="partialChecked(wordFormsSelected)"-->
                                  <!--@change="toggleAllWordForms">-->
                            <!--<p class="f gray"><span v-if="!hebrew">All</span> [{{countSelected(wordFormsSelected)}}] <span v-if="hebrew">הכל</span>-->
                            <!--</p>-->
                        <!--</Checkbox>-->
                    <!--</li>-->
                </ul>
                <ul class="inner-accordion" v-if="wordForms[0].wordForms.length >= 1">
                    <li class="inner-accordion-li inner-li" v-for="(word, key) in wordForms"
                        :key="word._id">
                        <a class="inner-accordion-link" v-if="wordForms.length >  1"
                           :class="{selected:wordFormChildVisible[key]}" @click="$set(wordFormChildVisible, key, !wordFormChildVisible[key])">
                            <span v-if="!hebrew" dir="rtl" class="f black inner-accordion-text"> <span>{{ word.term }}</span> Word forms of</span>
                            <span v-if="hebrew" dir="ltr" class="f black inner-accordion-text"> הטיות של <span>{{ word.term }}</span></span>

                        </a>
                        <div class="slide">
                            <transition name="slide-fade">
                                <div v-if="wordFormChildVisible[key] || wordForms.length === 1"
                                     :class="wordForms.length === 1 ? 'inner-accordion-content-single' : 'inner-accordion-content'"
                                >
                                    <SelectAll v-if="word.wordForms.length > 1"
                                               data-id="w_f_group_checkbox"
                                              :checked="allChecked(wordFormsSelected[key].selections)"
                                              :partial="partialChecked(wordFormsSelected[key].selections)"
                                              @change="toggleOneSetWordForms(wordFormsSelected[key].selections)"
                                              :class="{selectAll: (wordForms.length === 1)} ">
                                        <!--({{countSelected(wordFormsSelected[key].selections)}})-->
                                    </SelectAll>
                                    <ExpandableList
                                        data-id="word_forms_ul"
                                        :class="{single: (wordForms.length === 1 || word.wordForms.length === 1)}"
                                        :list-items="word.wordForms"
                                    >
                                        <template slot-scope="{ listitem }">
                                            <Checkbox data-id="w_f_checkbox"
                                                      v-model="wordFormsSelected[key].selections[listitem.wordForm]"
                                                      @change="updateState()"
                                                      :class="{'no-results': listitem.count === 0}"
                                                      narkis-offset="true"
                                            >
                                              <p :dir="hebrew ? 'ltr' : 'rtl'"
                                                 class="f gray check-text"
                                                 :class="{'no-results': listitem.count === 0}"
                                              >
                                                  <span class="text-numbers">({{listitem.count}})</span>
                                                  <span class="f-narkis">{{listitem.wordForm}}</span>
                                              </p>
                                            </Checkbox>
                                        </template>
                                    </ExpandableList>
                                </div>
                            </transition>
                        </div>
                    </li>
                </ul>
            </div>
        </transition>
        <div class="retry text-muted px-3" v-if="wordformsFailed || wordformsFirstFailed">
      <div>{{hebrew ? 'אופס יש לנו בעיה!' : 'Wordforms search failed'}}</div>
      <b-btn variant="link" class="text-muted bg-transparent border-0 p-0" @click="getData(wordformsFirstFailed)">
        <i class="fas fa-redo-alt"></i>
        <span class="text-underline mx-1" style="font-size:16px;">{{hebrew ? 'נסו שוב' : 'Try again'}}</span>
      </b-btn>
    </div>
    </li>

</template>
<script>
import Checkbox from './Checkbox'
import ExpandableList from './ExpandableList'
import WordformsBase from './WordformsBase'
import SelectAll from './SelectAll'

export default {
  name: 'WordformsDesktop',
  components: {SelectAll, ExpandableList, Checkbox},
  props: ['expanded'],
  mixins: [WordformsBase]
}
</script>
<style scoped src="../../assets/style/common-desktop-sidebar.css"></style>
<style scoped>
  .f-narkis {
    font-size: 18px;
  }
  .inner-accordion-li.inner-li{
      padding: 0;
      margin-top:10px;
      list-style-type: none;
  }
  .single {
    margin-top: 5px;
  }
</style>
