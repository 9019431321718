<template>
  <transition name="slide-mobile">
    <div class="next-slide-txt" id="sorted-slide" v-if="expanded">
      <div class="top-sidebar">
        <a class="back-button f blue" @click="$emit('toggle')"><span v-if="!hebrew">Back</span> <span
          v-if="hebrew">חזור</span></a>
      </div>
      <div class="text-holder">
        <h3 class="heading-h3" v-if="!hebrew">Download Files</h3>
        <h3 class="heading-h3" v-if="hebrew" dir="rtl">הורדת קובץ </h3>
        <div class="list-holder">
          <ul class="inner-menu-list" id="download-type-list">
            <li class="list-item">
              <a class="list-link">
                <Radiobox name="downloadType"
                    value="HTML"
                    @change="downloadType = $event"
                    :checked="downloadType === 'HTML'"
                >
                  <h3 class="heading-h3">HTML</h3>
                </Radiobox>
              </a>
            </li>
            <li class="list-item">
              <a class="list-link">
                <Radiobox name="downloadType"
                    value="TXT"
                    @change="downloadType = $event"
                    :checked="downloadType === 'TXT'"
                >
                  <h3 class="heading-h3">TXT</h3>
                </Radiobox>
              </a>
            </li>
            <li class="list-item">
              <a class="list-link">
                <Radiobox
                    name="downloadType"
                    value="CSV"
                    @change="downloadType = $event"
                    :checked="downloadType === 'CSV'"
                >
                  <h3 class="heading-h3">CSV</h3>
                </Radiobox>
              </a>
            </li>
            <li class="list-item">
              <a class="list-link">
                <Radiobox
                    name="downloadType"
                    value="DOCX"
                    @change="downloadType = $event"
                    :checked="downloadType === 'DOCX'"
                >
                  <h3 class="heading-h3">Word</h3>
                </Radiobox>
              </a>
            </li>
          </ul>
          <!--<Checkbox>-->
          <!--<h3 class="heading-h3 download-detail-check-text" v-if="!hebrew">Do not include the שמות קדושים</h3>-->
          <!--<h3 class="heading-h3 download-detail-check-text" v-if="hebrew">לא לכלול שמות קדושים</h3>-->
          <!--</Checkbox>-->
          <label class="control control--checkbox">
            <div class="chek-box-holder download-check-holder">
              <input type="checkbox" v-model="replaceShemos" class="control-input">
              <div class="control__indicator checkbox-indicator download-check-indicator">

              </div>
              <h3 class="heading-h3 download-detail-check-text" v-if="!hebrew">Do not include the שמות קדושים</h3>
              <h3 class="heading-h3 download-detail-check-text" v-if="hebrew">לא לכלול שמות קדושים</h3>
            </div>
          </label>
        </div>
      </div>
      <div class="sidebar-bottom-button-div">
        <Downloader :download-type="downloadType" :replace-shemos="replaceShemos">
          <button type="submit" id="download-btn" class="bt-lg sidebar-bottom-button button"><h2
            v-if="!hebrew" class="f white btn-text">Download</h2>
            <h2 v-if="hebrew" dir="rtl" class="f white btn-text">הורדה</h2></button>
        </Downloader>
      </div>
    </div>
  </transition>
</template>
<script>
import sidebarMixin from '../../mixins/sidebarMixin'
// import Checkbox from './Checkbox'
import Radiobox from './Radiobox'
import Downloader from './Downloader'

export default {
  name: 'download-detail-mobile',
  components: { Downloader, Radiobox, /* Checkbox */ },
  mixins: [sidebarMixin],
  props: {
    expanded: {
      type: Boolean,
      default: () => { return false }
    },
    DownloadDetailMobile: {
      type: Boolean,
      default: () => { return true }
    }
  },
  data () {
    return {
      downloadType: 'HTML',
      replaceShemos: false
    }
  }
}
</script>
<style scoped src="../../assets/style/common-sidebar-checks.css"></style>
<style scoped src="../../assets/style/common-mobile-sidebar.css"></style>
<style scoped>
  .download-check-indicator{
    float: left;
  }
  .he .download-check-indicator{
    float: right;
  }
  .he .download-check-holder{
    float: right;
    right: 0;
    width: 100%;
  }
  .download-check-holder{
    float: left;
    left: 0;
    width: 100%;
    position: static;
    margin-top: 0;
  }
  .download-detail-check-text{
    padding-left: 40px;
    margin: 0 !important;
    padding-top: 3px;
  }
  .he .download-detail-check-text {
    padding-left: 0;
    padding-right: 40px;
  }
  .list-link{
    padding:0 14px;
  }
  label.control--radio{
    min-height:64px;
    padding:9px 0;
  }
  label.control--radio h3 {
     margin: 13px 0 12px;
  }

</style>
