<template>
  <form method="POST" v-bind:action="serverUrl + '/download'" target="_blank">
    <input type="hidden" name="queryParams" :value="queryParams">
    <slot></slot>
  </form>
</template>

<script>
import {makeQuery} from '../../queries'
import config from '../../config'

export default {
  name: 'Downloader',
  props: ['downloadType', 'replaceShemos'],
  computed: {
    serverUrl () {
      return config.SERVER
    },
    queryParams () {
      return JSON.stringify({
        downloadType: this.downloadType,
        replaceShemos: this.replaceShemos,
        showNikudAndTeamim: this.$store.state.showNikudAndTeamim,
        ...makeQuery()
      })
    }
  }
}
</script>
<style scoped>
</style>
