<template>
  <div>
    <div class="mobile-list-collapse" id="mobile-menu-links">
      <div class="mobile-list-collapse-header row no-gutters" :dir="hebrew ? 'rtl' : 'ltr'">
        <div class="mobile-list-collapse-col col-6">
          <h3 class="m-0 text-white" v-if="mode==='menu'">
            <span v-if="!hebrew">More Links</span><span v-if="hebrew">קישורים נוספים</span>
          </h3>
          <h3 class="m-0 text-white" v-if="mode === 'morphology' || mode === 'lexemes'"
              :dir="hebrew ? 'rtl' : 'ltr'"
              @click="showMobileMenus"
          >
            <span v-if="hebrew">
              <i class="fa fa-angle-right"></i>
              &nbsp;&nbsp;
              <!--<img class="mobile-list-collapse-back-icon" src="../../assets/ic_down_White-right.png"/>-->
              {{
                mode === 'morphology' ?
                        'מורפולוגיה' :
                          'משמעויות'
              }}
            </span>
            <span v-else>
              <i class="fa fa-angle-left"></i>
              &nbsp;&nbsp;
              <!--<img class="mobile-list-collapse-back-icon" src="../../assets/ic_down_White.png"/>-->
              {{
                mode === 'morphology' ? 'Morphology' : 'Meanings'
              }}
            </span>
          </h3>
        </div>
        <div id="mobileListCollapseBtn"
        v-bind:class="[hebrew ? 'text-left' : 'text-right', 'mobile-list-collapse-col col-6  he-mobile-list-collapse-text']"
             @click="hiddenMobileMenu">
          <!--<img src="../../assets/cross-w.png"/>-->
          <i class="fa fa-times text-white"></i>
        </div>
      </div>
      <ul class="list-collapse-accordion">
        <li class="list-collapse-accordion-li">
          <a v-bind:class="expanded ? 'list-collapse-accordion-link expanded' : 'list-collapse-accordion-link'"
             @click="expanded = !expanded">
            <h3 class="list-collapse-accordion-heading f-narkis"
                :class="{expanded: expanded}"
                dir="rtl"
                v-html="sentence"></h3>
          </a>
          <transition name="slide-fade">
            <div v-if="expanded" class="list-collapse-content">
              {{trimPath(label)}}
            </div>
          </transition>
        </li>
      </ul>
      <div v-if="mode === 'menu'" class="mobile-list-collapse-body">
        <div class="mobile-list-menu row no-gutters" @click="showMorphologyMenu" :dir="hebrew ? 'rtl' : 'ltr'">
          <div class="mobile-list-menu-col col-10">
            <h3 class="m-0 text-black"><span v-if="!hebrew">Morphology</span><span
                v-if="hebrew">מורפולוגיה</span></h3>
          </div>
          <div v-bind:class="[hebrew ? 'text-left' : 'text-right', 'mobile-list-menu-col col-2 he-mobile-list-text']">
            <!--<img v-if="!hebrew" src="../../assets/ic_next_mobile.png">-->
            <!--<img v-if="hebrew" src="../../assets/ic_prev_mobile.png">-->
            <i class="fa" :class="hebrew ? 'fa-angle-left' : 'fa-angle-right'"></i>
          </div>
        </div>
        <div class="mobile-list-menu row no-gutters" @click="showLexemeMenu" :dir="hebrew ? 'rtl' : 'ltr'">
          <div class="mobile-list-menu-col col-10">
            <h3 class="m-0 text-black"><span v-if="!hebrew">Meanings</span> <span
                v-if="hebrew">משמעויות</span></h3>
          </div>
          <div v-bind:class="[hebrew ? 'text-left' : 'text-right', 'mobile-list-menu-col col-2 he-mobile-list-text']">
            <!--<img v-if="!hebrew" src="../../assets/ic_next_mobile.png">-->
            <!--<img v-if="hebrew" src="../../assets/ic_prev_mobile.png">-->
            <i class="fa" :class="hebrew ? 'fa-angle-left' : 'fa-angle-right'"></i>
          </div>
        </div>
      </div>
      <div v-if="mode !== 'menu'" class="mobile-list-collapse-body bg-white">
        <div class="list-wrap">
          <div v-for="(wordAnalysis, index) in analysis"
               :key="index"
               class="des-row row no-gutters">

            <div class="des-row-col col-3 text-right">
              <h3 class="f-narkis mb-0">{{ wordAnalysis.word }}֙</h3>
            </div>
            <div class="des-row-col col-9 text-right">
              <h3 class="f blue mb-0" dir="rtl">{{ mode === 'lexemes' ? fixLTR(wordAnalysis.lexeme) : wordAnalysis.morphology }}</h3>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import {trimPath} from './trimPath'

export default {
  name: 'MorphologyAndLexemesMobile',
  props: {
    analysis: {},
    label: {},
    sentence: {},
    sentenceLabel: {}
  },
  data () {
    return {
      expanded: false,
      mode: 'menu'
    }
  },
  computed: {
    hebrew () {
      // return this.$route.name.includes('hebrew')
      return this.$settings.hebrew
    }
  },
  methods: {
    hiddenMobileMenu () {
      this.$emit('hide')
      document.querySelectorAll('body,html').forEach(e => e.classList.remove('noScroll'))
      history.replaceState('', document.title, window.location.pathname + window.location.search)
    },
    showMobileMenus () {
      this.mode = 'menu'
      // $('.mobile-list-collapse').show();
      // document.getElementById('mobile-menu-links').style.display = 'block'
      // document.getElementById('mobile-morphology-link').style.display = 'none'
      // document.getElementById('mobile-lexeme-link').style.display = 'none'
    },
    showMorphologyMenu () {
      this.mode = 'morphology'
      // document.getElementById('mobile-morphology-link').style.display = 'block'
    },
    showLexemeMenu () {
      this.mode = 'lexemes'
      // document.getElementById('mobile-lexeme-link').style.display = 'block'
    },
    fixLTR (text) {
      return text.replace(/([()<>])/g, '\u200e$&\u200e')
    },
    trimPath (text) {
      // FIXME: hack to pass through the sentence label and tack it on to the perek name to convert into a normal reference
      return trimPath(text) + (this.sentenceLabel.length > 0 ? ', ' + this.sentenceLabel.replace(/[()]/g, '') : '')
    }
  },
  mounted () {
    this.showMobileMenus()
    document.querySelectorAll('body,html').forEach(e => e.classList.add('noScroll'))
  }
}
</script>
<style lang="scss">
  @media screen and ( max-width: 991px ) {
    .noScroll {
      .mobile-header,#searchHeader {
        display: none!important;
      }
    }
  }
</style>
<style scoped>
  /*.result-li p {*/
  /*margin: 0;*/
  /*}*/

  /*.result-main {*/
  /*!*height: 90vh;*!*/
  /*}*/
  @media screen and ( max-width: 991px ) {

    .mobile-list-collapse {
      line-height: 17px;
      text-align: left;
      position: fixed;
      display: flex;
      flex-direction: column;
      bottom: 0;
      top: 0;
      width: 100%;
      left: 0;
      right: 0;
      height: 100%;
      background: #F6F6F6;
      z-index: 99999999;
      -webkit-transform: translate3d(0,0,1px);
      transform: translate3d(0,0,1px);

    }
    .he .mobile-list-collapse {
      text-align: right;
    }

    .mobile-list-collapse-back-icon {
      position: relative;
      top: -1px;
      right: 5px;
    }

    .he .mobile-list-collapse-back-icon {
      left: 5px;
      right: auto;
    }

    .mobile-list-collapse-header {
      background: #007EE5;
      padding: 12px 15px;
    }

    .mobile-list-collapse-body {
      padding: 10px 0;
      flex: 1 1 auto;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .mobile-list-menu {
      padding: 20px 15px;
      border-radius: 2px;
      background-color: #ffffff;
      border: solid 1px #e5e5e5;
      margin-bottom: 8px;
      cursor: pointer;
    }

    .des-row {
      padding: 10px 15px;
    }

    .f-narkis {
      font-size: 18px;
    }

    .des-row-col {
      float: right;
    }

    .list-collapse-accordion {
      padding: 10px 0 0;
      width: inherit;
      list-style: none;
    }

    .list-collapse-accordion-li {
      display: block;
      position: relative;
      direction: rtl;
    }

    .list-collapse-content {
      padding: 15px 20px 10px;
      text-align: right;
      font-size: 16px;
    }

    .list-collapse-accordion-heading {
      font-size: 20px;
      font-weight: 100;
      width: 90%;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x: hidden;
    }

    .list-collapse-accordion-heading.expanded {
      white-space: normal;
    }

    .list-collapse-accordion-link{
      display: block;
      position: relative;
      padding: 0 20px;
    }

    .list-collapse-accordion-link:after {
      width: 10px;
      height: 18px;
      position: absolute;
      top: 40%;
      right:auto;
      left: 22px;
      content: '';
      background: url("../../assets/Triangle-down.png") no-repeat;
    }
    .list-collapse-accordion-link.expanded:after{
      background: url("../../assets/Triangle-up.png") no-repeat;
    }
    h3 {
      line-height: 1.35;
    }

    /*.he .list-collapse-accordion-link:after{*/
    /*right:auto;*/
    /*left: 22px;*/
    /*background: url("../../assets/Triangle-down.png") no-repeat;*/
    /*}*/
    /*.he .list-collapse-accordion-link.active:after{*/
    /*background: url("../../assets/Triangle-up.png") no-repeat;*/
    /*}*/
  }

</style>
