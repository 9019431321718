<template>
  <div class="collapse-section collapse show" :class="{'last-analysis': lastAnalysis}">
    <ul class="nav nav-tabs collapse-section-tabs px-0">
      <li class="collapse-tabs-li">
        <a class="collapse-tabs-link" :class="{ active: tab === 'lexemes' }" @click="tab = 'lexemes'">
          <p class="collapse-tabs-text"><span v-if="!hebrew">Meanings</span><span v-if="hebrew">משמעויות</span></p>
        </a>
      </li>
      <li class="collapse-tabs-li">
        <a 
          class="collapse-tabs-link has-tooltip" 
          :class="{ active: tab === 'morph' }" 
          @click="tab = 'morph'"          
          >
          <p class="collapse-tabs-text" v-b-tooltip.hover.bottom :title="hebrew ? 'רכיבי המבנה של המילה' : 'Word Structure'">
            <span v-if="!hebrew">Morphology</span><span v-if="hebrew">מורפולוגיה</span>
          </p>
        </a>
      </li>
    </ul>
    <div class="tab-content collapse-tab-content">
      <div id="lexeme" class="tab-pane fade in active" v-if="tab ==='lexemes'">
        <div class="listing-wrapper">
          <div v-for="(wordAnalysis, index) in analysis" :key="index" class="row no-gutters collapse-content-row">
            <div class="collapse-content-col col-sm-2">
              <p class="index-text">{{ wordAnalysis.word }}</p>
            </div>
            <div class="collapse-content-col col-sm-10">
              <p class="description-text">{{ fixLTR(wordAnalysis.lexeme) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div id="morphology" class="tab-pane in fade active" v-if="tab ==='morph'">
        <div class="listing-wrapper">
          <div v-for="(wordAnalysis, index) in analysis" :key="index" class="row no-gutters collapse-content-row">
            <div class="collapse-content-col col-sm-2">
              <p class="index-text">{{ wordAnalysis.word }}</p>
            </div>
            <div class="collapse-content-col col-sm-10">
              <p class="description-text">{{ wordAnalysis.morphology }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MorphologyAndLexemesDesktop',
  props: {
    analysis: {},
    // for rendering the last one without a bottom margin
    lastAnalysis: {}
  },
  computed: {
    hebrew () {
      // return this.$route.name.includes('hebrew')
      return this.$settings.hebrew
    }
  },
  data () {
    return {
      expanded: false,
      tab: 'lexemes'
    }
  },
  methods: {
    fixLTR (text) {
      return text.replace(/([()<>])/g, '\u200e$&\u200e')
    }
  }
}

</script>

<style scoped>
  .last-analysis.collapse-section {
    margin-bottom: -12px;
  }

  .collapse-section {
    margin: 5px -13px 5px;
    padding: 12px 14px 5px;
    background: #F8F8F8;
  }

  .collapse-section-tabs {
    border-bottom: 1px solid #D8D8D8;
  }

  .collapse-tabs-li {
    float: right;
    border-radius: 2px;
    background-color: transparent;
    border: 0 !important;
    box-shadow: none !important;
    margin-bottom: 0 !important;
    width: 150px;
    text-align: center;
  }

  .collapse-tabs-link {
    padding: 0;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    background: transparent;
    color: #000;
    display: inline-block !important;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    cursor: pointer;
  }

  .collapse-tabs-link:hover,
  .collapse-tabs-link.active {
    background-color: transparent;
    border-bottom: 2px solid;
    color: #000;
  }

  .collapse-tabs-text {
    margin-bottom: 0;
    font-size: 16px;
  }
  .collapse-tab-content {
    padding-top: 15px;
  }

  .collapse-content-col {
    float: right;
    font-size: 16px;
  }

  .collapse-content-row {
    padding: 2px 0;
  }

  .collapse-content-row p {
    margin-bottom: 0;
  }

  .collapse-content-row:hover {
    background: #F3F3F3;
  }

  .description-text {
    font-size: 14px;
    color: #007EE5;
  }

  .index-text {
    font-size: 16px;
    font-family: Roboto, mft_narkisclassic, sans-serif;
    color: #656565;
  }
  .list-btn-wrap{
    margin-right: 6px;
  }
  .he .list-btn-wrap{
    margin-right: 0;
  }
  
</style>
