<template>
  <transition name="slide-mobile">
    <div class="next-slide-txt" id="sorted-slide" v-if="expanded">
      <div class="top-sidebar">
        <a class="back-button f blue" @click="$emit('toggle')"><span v-if="!hebrew">Back</span> <span
          v-if="hebrew">חזור</span></a>
      </div>
      <div class="text-holder">
        <h3 class="heading-h3" v-if="!hebrew">Display details </h3>
        <h3 class="heading-h3" v-if="hebrew" dir="rtl"> הגדרות תצוגה </h3>
        <div class="d-details-list">
          <div class="details-list-row row no-gutters">
            <div class="details-list-col col-4">
              <h3 class="heading-h3 mb-0"><span v-if="!hebrew">Nikud</span> <span v-if="hebrew"
               dir="rtl">ניקוד</span></h3>
            </div>
            <div v-bind:class="[hebrew ? 'text-left' : 'text-right', 'details-list-col col-8 ']">
              <div class="btn-group text-detail">
                    <button type="button" id="no-nikud"  class="btn list-common-btn text-detail-btn"
                            :class="{active: mobileShowNikudAndTeamim === NikudAndTeamim.NONE}"
                            @click="setMobileShowNikudAndTeamim(NikudAndTeamim.NONE)"><p class="btn-text pad-correction">א</p></button>
                    <button type="button" id="nikud-only" class="btn list-common-btn text-detail-btn"
                            :class="{active: mobileShowNikudAndTeamim === NikudAndTeamim.NIKUD}"
                            @click="setMobileShowNikudAndTeamim(NikudAndTeamim.NIKUD)"><p class="btn-text pad-correction">אָ</p></button>
                    <button v-if="SearchType.Bible" type="button" id="nikud-plus-taamim" class="btn list-common-btn text-detail-btn"
                            :class="{active: mobileShowNikudAndTeamim === NikudAndTeamim.BOTH}"
                            @click="setMobileShowNikudAndTeamim(NikudAndTeamim.BOTH)"><p class="btn-text pad-correction">אָ֭</p></button>
              </div>
            </div>
          </div>
          <div class="details-list-row row no-gutters mb-0">
            <div class="details-list-col col-4">
              <h3 class="heading-h3 mb-0"><span v-if="!hebrew">Font size</span><span v-if="hebrew"
                                                                                     dir="rtl">גודל טקסט</span></h3>
            </div>
            <div v-bind:class="[hebrew ? 'text-left' : 'text-right', 'details-list-col col-8 ']">
              <div class="btn-group font-detail">
                    <button type="button" id="decrease-size" class="btn list-common-btn font-detail-btn"
                            :class="{disabled: mobileResultFontSize === 12}"
                            @click="mobileZoomOut()"><img src="../../assets/zoom-out-b.png"/></button>
                    <button type="button" class="btn list-common-btn font-detail-btn"><h3 class="btn-text font-detail-btn-text">{{mobileResultFontSize}}</h3></button>
                    <button type="button" id="increase-size" class="btn list-common-btn font-detail-btn"
                            @click="mobileZoomIn()"><img src="../../assets/zoom-in-b.png"/></button>
              </div>
            </div>
          </div>
        </div>
        <div class="d-details-list">
          <div class="details-list-row row no-gutters mb-0">
            <div class="details-list-col col-4">
              <h3 class="heading-h3 mb-0"><span v-if="!hebrew">Result Pages</span><span v-if="hebrew" dir="rtl">תוצאות בעמוד</span>
              </h3>
            </div>
            <div v-bind:class="[hebrew ? 'text-left' : 'text-right', 'details-list-col col-8 ']">
              <div class="btn-group">
                <button type="button"
                        id="10-per-page"
                        class="btn list-common-btn"
                        :class="{active: mobileResultsPerPage === 10}"
                        @click="setMobileResultsPerPage(10)"><h3 class="btn-text">10</h3></button>
                <button type="button"
                        id="50-per-page"
                        class="btn list-common-btn"
                        :class="{active: mobileResultsPerPage === 50}"
                        @click="setMobileResultsPerPage(50)"><h3 class="btn-text">50</h3></button>
                <button type="button"
                        id="100-per-page"
                        class="btn list-common-btn"
                        :class="{active: mobileResultsPerPage === 100}"
                        @click="setMobileResultsPerPage(100)"><h3 class="btn-text">100</h3></button>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="sidebar-bottom-button-div">
        <button type="button" class="bt-lg sidebar-bottom-button button" id="save-mobile-display" @click="saveMobileDisplay">
          <h2 class="f white btn-text">{{hebrew ? 'שמירה' : 'Save'}}</h2>
        </button>
      </div>
    </div>
  </transition>
</template>
<script>
import sidebarMixin from '../../mixins/sidebarMixin'
import {NikudAndTeamim} from '../../store'
import {SearchType} from '../../searchConfig.js'

export default {
  name: 'display-detail-mobile',
  mixins: [sidebarMixin],
  data () {
    return {
      NikudAndTeamim,
      mobileShowNikudAndTeamim: this.$store.state.showNikudAndTeamim,
      mobileResultsPerPage: this.$store.state.resultsPerPage,
      mobileResultFontSize: this.$store.state.resultFontSize
    }
  },
  methods: {
    setMobileResultsPerPage (num) {
      this.mobileResultsPerPage = num
    },
    setMobileShowNikudAndTeamim (show) {
      this.mobileShowNikudAndTeamim = show
    },
    mobileZoomOut () {
      this.mobileResultFontSize -= 2
    },
    mobileZoomIn () {
      this.mobileResultFontSize += 2
    },
    saveMobileDisplay () {
      this.setResultFontSize(this.mobileResultFontSize)
      this.setShowNikudAndTeamim(this.mobileShowNikudAndTeamim)
      this.setResultsPerPage(this.mobileResultsPerPage)
      this.$emit('toggle')
    }
  },
  props: {
    expanded: {
      type: Boolean,
      default: () => { return false }
    },
    DisplayDetailMobile: {
      type: Boolean,
      default: () => { return true }
    }
  },
  computed: {
    SearchType () {
      return SearchType
    },
    isExpanded () {
      return this.expanded
    }
  },
  watch: {
    isExpanded (val) {
      if (val) {
        this.mobileShowNikudAndTeamim = this.$store.state.showNikudAndTeamim
        this.mobileResultsPerPage = this.$store.state.resultsPerPage
        this.mobileResultFontSize = this.$store.state.resultFontSize
      }
    }
  }
}
</script>
<style scoped src="../../assets/style/common-mobile-sidebar.css"></style>
<style scoped>
  /*Start Random style*/
  .details-list-row {
    padding: 10px 15px;
    position: relative;
    border-radius: 2px;
    -webkit-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
    border: solid 1px #c2c2c2;
    margin: 0 15px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #ffffff;
  }

  .d-details-list {
    padding: 28px 0;
    width: inherit;
    margin: 0 -20px 10px;
    list-style: none;
    overflow: hidden;
    background: #ffffff;
    border-bottom: solid 1px #c8c8c8;
    border-top: solid 1px #c8c8c8;
  }

  .text-detail-btn {
    font-family: Roboto, mft_narkisclassic, sans-serif !important;
    font-size: 32px !important;
  }

  .list-common-btn {
    border-radius: 2px;
    width: 45px;
    height: 40px;
    border: 1px solid #979797;
    background: #ffffff;
    color: #000000;
    font-size: 18px;
    font-style: normal;
    font-stretch: normal;
    font-weight: 400;
    line-height: 21px;
    text-transform: none;
    letter-spacing: 0;
    padding: 0;
    text-align: center;
  }

  .font-detail-btn,
  .font-detail-btn:hover,
  .font-detail-btn:focus,
  .font-detail-btn:active {
    border: 1px solid transparent !important;
    background: transparent !important;
    color: #000000;
  }

  .list-common-btn:hover,
  .list-common-btn:focus,
  .list-common-btn.active {
    border: 1px solid #007ee5;
    background: #007ee5;
    color: #ffffff;
    box-shadow: none;
    outline: none;
  }

  .btn-text {
    margin-bottom: 0;
  }

  .btn-text:hover,
  .btn-text:focus,
  .btn-text:active,
  .active .btn-text {
    color: #ffffff;
  }

  .btn {
    z-index: 0 !important;
  }

  .he .text-right {
    text-align: left;
  }

  .pad-correction {
    padding-bottom: 10px;
  }
  .font-detail-btn-text,
  .font-detail-btn-text:hover,
  .font-detail-btn-text:focus,
  .font-detail-btn-text:active {
    color: #000000 !important;
  }
  /*End Random Style*/
</style>
