<template>
  <!-- start book slide -->
  <transition name="slide-mobile">
    <div class="next-slide-txt" id="book-slide" v-if="expanded">
      <div class="top-sidebar">
        <a class="back-button f blue" @click="$emit('toggle')"><span v-if="!hebrew">Back</span><span
          v-if="hebrew">חזור</span></a>
      </div>
      <div class="text-holder">
        <h3 class="heading-h3" v-if="!hebrew">Books </h3>
        <h3 class="heading-h3" v-if="hebrew" dir="rtl"> ספרים </h3>
        <ul class="mobile-accordion" v-for="bookGroup in books" :key="bookGroup.category">
          <li class="mobile-accordion-li">
            <a v-bind:class="bookChildVisible[bookGroup.category] ? 'mobile-accordion-link active' : 'mobile-accordion-link'"
               @click="bookChildVisible[bookGroup.category] = !bookChildVisible[bookGroup.category]">
              <h3 class="heading-h3 m-0" dir="rtl"> {{bookGroup.category}}</h3>
            </a>
            <transition name="slide-fade">
              <ul v-if="bookChildVisible[bookGroup.category]" class="inner-menu-list mobile-accordion-menu-list">
                <li class="list-item" v-if="bookGroup.data.length > 0">
                  <a class="list-link bg-half-white">
                    <Checkbox :checked="allBooksInGroupChecked(bookGroup.data)"
                              @change="bookGroupCheck(bookGroup.data)"
                              :partial="partialBooksInGroupChecked(bookGroup.data)">
                      <h3 class="heading-h3 text-width" v-if="!hebrew" dir="ltr">Select All</h3>
                      <h3 class="heading-h3 text-width" v-if="hebrew" dir="rtl">בחר הכל</h3>
                    </Checkbox>
                  </a>
                </li>
                <li class="list-item" v-for="book in bookGroup.data" :key="book._id">
                  <a class="list-link">
                    <Checkbox
                        :class="{'no-results': book.count === 0}"
                        @change="toggleMobile(book.bookId)"
                        :checked="booksSelected[book.bookId]">
                      <h3 v-if="!hebrew" dir="rtl" class="heading-h3 text-width"><span class="text-numbers">({{book.count}})</span><span
                        class="f-narkis">{{book.hebrewBookName[2]}}</span></h3>
                      <h3 v-if="hebrew" dir="rtl" class="heading-h3 text-width"><span class="f-narkis"> {{book.hebrewBookName[2]}}</span><span
                        class="text-numbers">({{book.count}})</span></h3>
                    </Checkbox>
                  </a>
                </li>
              </ul>
            </transition>
          </li>
        </ul>
      </div>

      <div class="sidebar-bottom-button-div">
        <button type="button" id="save-mobile-books" class="bt-lg sidebar-bottom-button button" @click="saveBooksMobile">
          <h2 class="f white btn-text">{{hebrew ? 'שמירה' : 'Save'}}</h2>
        </button>
      </div>
    </div>
  </transition>
  <!-- end book slide -->
</template>

<script>
import Checkbox from './Checkbox'
// import ExpandableList from './ExpandableList'
import BooksBase from './BooksBase'
import _ from 'lodash'

export default {
  name: 'BooksMobile',
  components: { /* ExpandableList, */ Checkbox },
  props: ['expanded'],
  mixins: [BooksBase],
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    bookGroupCheck (group) {
      const toBeChecked = !this.allBooksInGroupChecked(group)
      group.forEach(book => { this.booksSelected[book.bookId] = toBeChecked })
    },
    toggleMobile (id) {
      this.booksSelected[id] = !this.booksSelected[id]
    },
    saveBooksMobile () {
      this.setState()
      //this.$emit('done')
      this.$emit('updateSelectedBooksCount', this.bookTotal)
      this.mobileBooksSelected = _.cloneDeep(this.booksSelected)
      this.$emit('toggle')
    }
  },
  computed: {
    bookTotal () {
      return this.countSelected(this.booksSelected)
    },
    mobileBookTotal () {
      return this.countSelected(this.mobileBooksSelected)
    },
    isExpanded () {
      return this.expanded
    }
  },
  watch: {
    bookTotal () {
      this.$emit('updateSelectedBooksCount', this.mobileBookTotal)
    },
    isExpanded (val) {
      if (val) {
        this.booksSelected = _.cloneDeep(this.mobileBooksSelected)
      }
    }
  }
}
</script>
<style scoped src="../../assets/style/common-mobile-sidebar.css"></style>
<style scoped></style>
