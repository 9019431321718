import { render, staticRenderFns } from "./Downloader.vue?vue&type=template&id=5d1914c0&scoped=true&"
import script from "./Downloader.vue?vue&type=script&lang=js&"
export * from "./Downloader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d1914c0",
  null
  
)

export default component.exports