<template>
    <!-- start meaning slide -->
    <transition name="slide-mobile">
      <div class="next-slide-txt" id="meaning-slide" v-if="expanded">
            <div class="top-sidebar">
              <a class="back-button f blue" @click="$emit('toggle')"><span v-if="!hebrew">Back</span><span v-if="hebrew">חזור</span></a>
            </div>
            <div class="text-holder">
              <h3 class="heading-h3"><span v-if="!hebrew">Meanings and synonyms</span><span v-if="hebrew">משמעויות </span></h3>
              <ul class="mobile-accordion" v-for="(word, index) in lexemeData" :key="word._id">
                <li class="mobile-accordion-li">
                  <a class="mobile-accordion-link"
                     :class="lexemeData.length === 1 ? 'single' : (meaningChildVisible[index] ? 'active' : '')"
                     v-if="word.allLexemes.length > 0 && lexemeData.length > 0"
                     @click="$set(meaningChildVisible, index, !meaningChildVisible[index])">
                    <h3 class="heading-h3 m-0" v-if="!hebrew" dir="rtl">{{ word.term }} Meanings for </h3>
                    <h3 class="heading-h3 m-0" v-if="hebrew" dir="ltr">  משמעויות של {{ word.term }}</h3>
                  </a>
                  <transition name="slide-fade">
                    <ul v-if="meaningChildVisible[index] || lexemeData.length < 2" class="inner-menu-list mobile-accordion-menu-list">
                      <li class="list-item" v-if="word.allLexemes.length > 0">
                        <a class="list-link bg-half-white">
                          <Checkbox :checked="allChecked(lexemesSelected[index].selections)"
                                    :partial="partialChecked(lexemesSelected[index].selections)"
                                    @change="toggleLexemeGroup(lexemesSelected[index].selections)">
                            <h3 class="heading-h3 text-width" v-if="!hebrew" dir="ltr">Select All</h3>
                            <h3 class="heading-h3 text-width" v-if="hebrew" dir="rtl">בחר הכל</h3>
                          </Checkbox>
                        </a>
                      </li>
                      <li class="list-item" v-for="lexeme in word.allLexemes" :key="lexeme._id">
                        <a class="list-link">
                          <Checkbox :class="{'no-results': lexeme.count === 0}"
                                    :checked="lexemesSelected[index].selections[lexeme.lexeme]"
                                    @change="val => toggleMobileLexeme(lexemesSelected[index].selections, lexeme.lexeme, val)">
                            <h3 class="heading-h3 text-width" v-if="!hebrew">
                              <span dir="ltr" class="f-narkis">{{ fixLTR(lexeme.description) }}</span>
                              <span class="text-numbers">({{lexeme.count}})</span>
                            </h3>
                            <h3 class="heading-h3 text-width" dir="rtl" v-else>
                              <span dir="rtl" class="f-narkis">{{ fixLTR(lexeme.description) }}</span>
                              <span class="text-numbers">({{lexeme.count}})</span>
                            </h3>

                            <!--<h3 class="heading-h3" v-if="!hebrew" dir="ltr">{{ lexeme.description }}<span class="text-numbers">({{word.allLexemes.length}})</span></h3>-->
                            <!--<h3 class="heading-h3" v-if="hebrew" dir="rtl">{{ lexeme.baseText }}<span class="text-numbers">({{word.allLexemes.length}})</span></h3>-->
                          </Checkbox>
                        </a>
                        <SynonymsMobile :related-lexemes="getRelatedLexemes(word.term, lexeme.lexeme)"
                                        :related-lexemes-selected="synonymsSelected.find(wordData => wordData.term === word.term).selections"
                                        @changeRelated="synonym => toggleMobileSynonym(synonymsSelected.find(wordData => wordData.term === word.term).selections, synonym)"
                        ></SynonymsMobile>
                      </li>
                    </ul>
                  </transition>
                </li>
              </ul>
            </div>

        <div class="sidebar-bottom-button-div">
          <button type="button" class="bt-lg sidebar-bottom-button button" id="save-mobile-meanings" @click="saveMeaningsMobile">
            <h2 class="f white btn-text">{{hebrew ? 'שמירה' : 'Save'}}</h2>
          </button>
        </div>
          </div>
    </transition>
    <!-- end meaning slide -->
</template>

<script>
import Checkbox from './Checkbox'
import MeaningsBase from './MeaningsBase'
import SynonymsMobile from './SynonymsMobile'
import _ from 'lodash'

export default {
  name: 'MeaningsMobile',
  components: {SynonymsMobile, Checkbox},
  props: ['expanded'],
  mixins: [MeaningsBase],
  data () {
    return {}
  },
  computed: {
    selectedMeaningCount () {
      return this.countSelected(this.mobileLexemesSelected)
    },
    isExpanded () {
      return this.expanded
    }
  },
  watch: {
    selectedMeaningCount () {
      this.$emit('updateSelectedMeaningsCount', this.selectedMeaningCount)
    },
    isExpanded (val) {
      if (val) {
        this.lexemesSelected = _.cloneDeep(this.mobileLexemesSelected)
        this.synonymsSelected = _.cloneDeep(this.mobileSynonymsSelected)
      }
    }
  },
  methods: {
    fixLTR (text) {
      return text.replace(/([()<>])/g, '\u200e$&\u200e')
    },
    toggleMobileSynonym (synonymList, synonym) {
      synonymList[synonym] = !synonymList[synonym]
      this.setRelated(this.synonymsSelected)
    },
    toggleMobileLexeme (selections, lexeme, val) {
      selections[lexeme] = val
    },
    saveMeaningsMobile () {
      //this.$emit('done')
      this.setRelated(this.synonymsSelected)
      this.setLexemes(this.lexemesSelected)
      this.mobileLexemesSelected = _.cloneDeep(this.lexemesSelected)
      this.mobileSynonymsSelected = _.cloneDeep(this.synonymsSelected)
      this.$emit('toggle')
    }
  }
}
</script>
<style scoped src="../../assets/style/common-mobile-sidebar.css"></style>
<style scoped>

</style>
