/* eslint-disable */
function isJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

const allDevSettingsJsonString = localStorage.getItem('dev-dicta-settings')
const allDevSettings = (allDevSettingsJsonString && isJsonString(allDevSettingsJsonString))
                        ? JSON.parse(allDevSettingsJsonString)
                        : null

function getLocation(href) {
// adapted from answer by "Rems" to https://stackoverflow.com/questions/736513/how-do-i-parse-a-url-into-hostname-and-path-in-javascript -- with change in comment by "shlensky"
  var match = href.match(/^(?:(https?):\/\/)?(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
  return match && {
      originalUrl: href,
      protocol: match[1] || null,
      // host: match[2],
      hostname: match[3] || null,
      port: (match[4] && match[4] !== '/') ? match[4] : null,
      path: match[5] || null
      // search: match[6],
      // hash: match[7]
  }
}

function getDevServerLocation(appName) {
  const appDevSettings = allDevSettings && allDevSettings[appName]
  let devServerLocation = (appDevSettings && appDevSettings.devServerLocation) || (appDevSettings && appDevSettings.serverLocation) // 2nd's backward compatibility
  if (((devServerLocation && devServerLocation.url) || (devServerLocation && devServerLocation.originalUrl))
      && !((devServerLocation && devServerLocation.protocol))
      && !((devServerLocation && devServerLocation.hostname))
      && !((devServerLocation && devServerLocation.port))
      && !((devServerLocation && devServerLocation.path))) {
    const fullUrl = (devServerLocation && devServerLocation.url) || (devServerLocation && devServerLocation.originalUrl)
    devServerLocation = getLocation(fullUrl)
  }
  return devServerLocation
}

function getDevServer(appName, defaultUrl) { // note that if default has no path, dev's "path" is NOT "used" here
  const devServerLocation = getDevServerLocation(appName)
  if (!devServerLocation) return null
  const defaultLocation = getLocation(defaultUrl)
  return ((devServerLocation && devServerLocation.protocol) || defaultLocation.protocol) + '://'
          + ((devServerLocation && devServerLocation.hostname) || defaultLocation.hostname)
          + ((devServerLocation && devServerLocation.port) || defaultLocation.port || '')
          + (defaultLocation.path ? ((devServerLocation && devServerLocation.path) || defaultLocation.path) : '')
}

function getDevServerRoot(appName) {
  const devServerLocation = getDevServerLocation(appName)
  // if ((devServerLocation && devServerLocation.serverRoot)) return (devServerLocation && devServerLocation.serverRoot) // no known relevant case yet
  return ((devServerLocation && devServerLocation.path) && (devServerLocation && devServerLocation.path) !== '/') ? devServerLocation.path : null
  // note: that line currently only "makes sense" if the ABOVE-used "defaultUrl" has NO path
}

function getDevApiSettings(appName) {
  const appDevSettings = allDevSettings && allDevSettings[appName]
  const apiSettings = (appDevSettings && appDevSettings.devApiSettings) || (appDevSettings && appDevSettings.apiSettings) // 2nd's backward compatibility
  return (apiSettings && typeof apiSettings === 'object') ? apiSettings : null
}

function getDevFrontendSettings(appName) { // each app will need its own "format" for what goes "under" this
  const appDevSettings = allDevSettings && allDevSettings[appName]
  return ((appDevSettings && appDevSettings.devFrontendSettings) && typeof appDevSettings.devFrontendSettings === 'object')
          ? appDevSettings.devFrontendSettings : null
}

function isThisAppInDevMode(appName) {
  const appDevSettings = allDevSettings && allDevSettings[appName]
  return !(!(((appDevSettings && appDevSettings.devServerLocation) && typeof appDevSettings.devServerLocation === 'object')
              || ((appDevSettings && appDevSettings.serverLocation) && typeof appDevSettings.serverLocation === 'object')
              || ((appDevSettings && appDevSettings.devApiSettings) && typeof appDevSettings.devApiSettings === 'object')
              || ((appDevSettings && appDevSettings.apiSettings) && typeof appDevSettings.apiSettings === 'object')
              || ((appDevSettings && appDevSettings.devFrontendSettings) && typeof appDevSettings.devFrontendSettings === 'object')))
}

export {getDevServer, getDevServerRoot, getDevApiSettings, getDevFrontendSettings, isThisAppInDevMode}
