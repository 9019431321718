<template>
  <b-modal
    v-model="show"
    centered
    id="server-failed-modal"
    size="sm"
    hide-header
    footer-class="border-top"
    body-class="py-3 px-4"
    @ok="onOk"
    :ok-title="hebrew ? 'דווחו לנו' : 'Report'"
    :cancel-title="hebrew ? 'סגור' : 'Cancel'"
  >
    <div class="text-center">
      {{hebrew ? 'אופס יש לנו בעיה' : 'Oops. Something went wrong'}}
      <br>
      {{hebrew ? 'נסו שנית, או בקרו באתר מאוחר יותר' : 'Please try again later'}}
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'ServerFailedPopup',
  props: ['value'],
  computed: {
    hebrew () {
      return this.$settings.hebrew
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    onOk () {
      this.$bvModal.show('contact-us')
    }
  }
}
</script>
<style lang="scss">
  #server-failed-modal {
    button {
      width: 50%;
    }
  }
</style>
