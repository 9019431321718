import store, {resolveSortBy} from './store'
export function makeQuery () {
  const state = store.state
  return {
    query: state.query,
    lexemes: state.lexemesState,
    related: state.relatedState,
    books: state.booksState,
    wordForms: state.wordFormsState,
    sort: resolveSortBy(state) === 'relevance' ? '' : 'corpus_order_path'
  }
}
