<template>
  <div id="header" class="border border-bottom border-secondary bg-white">
    <dicta-header :hebrew-supported="true" :english-supported="!disableEnglishUI" />
  </div>
</template>
<script>
import config from '../config'
export default {
  name: 'Header',
  computed: {
    disableEnglishUI () {
      return config.DISABLE_ENGLISH_UI
    }
  }
}
</script>
