import { render, staticRenderFns } from "./SortOrderMobile.vue?vue&type=template&id=381408c6&scoped=true&"
import script from "./SortOrderMobile.vue?vue&type=script&lang=js&"
export * from "./SortOrderMobile.vue?vue&type=script&lang=js&"
import style0 from "../../assets/style/common-mobile-sidebar.css?vue&type=style&index=0&id=381408c6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "381408c6",
  null
  
)

export default component.exports