<template>
  <label  class="control control--checkbox" :class="{singleAll:false}">
    <div class="chek-box-holder" :class="{'narkis-offset': narkisOffset}">
      <input type="checkbox" class="control-input" :checked="checked" @change="$emit('change', $event.target.checked)">
      <!-- class "all" oddly enough means "partial" -->
      <!--add class "disable-check-indicator" for disable status-->
      <div class="control__indicator checkbox-indicator" :class="{checkboxIndicatorAllSelect: partial}"></div>
    </div>
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: ['checked', 'partial', 'narkisOffset']
}
</script>

<style scoped src="../../assets/style/common-sidebar-checks.css"></style>
<style scoped>
  .narkis-offset {
    padding-top: 5px;
  }
</style>
