
<template>
  <b-modal id="howModalHeb" v-model="show" ok-only @hide="hidePopup" size="lg"
    footer-class="border-top d-none d-sm-block" header-class="d-none d-sm-block" ok-title="סגור" ok-variant="secondary">
    <template slot="modal-header">איך זה עובד</template>
    <div class="mobile-modal-top bg-secondary d-block d-sm-none">
      <div class="row">
        <div class="col-8">
          <h3>איך זה עובד</h3>
        </div>
        <div @click="hidePopup" class="close-icon col-4 text-left">
          <i class="fas fa-times"></i>
        </div>
      </div>
    </div>
    <div v-if="SearchType.Talmud" class="how-to-inner-content">
      <div v-if="SearchType.Talmud && !isSmallScreen">
        <p>מנוע החיפוש בתלמוד מבית דיקטה מאפשר חיפוש מילים וביטויים בתלמוד ובמשנה באופן אינטואיטיבי, מבלי להתעסק בהבדלי
          איות והטיה.
          תוצאות חיפוש של מילה אחת או יותר, יכללו פסקאות בהן מופיעות מילות החיפוש עם וריאציות באיות ובהטיה.
          התוצאות מופיעות לפי סדר הרלוונטיות. ניתן לבחור להציגן לפי סדר הופעתן בתלמוד.
        </p>
        <h3>נהלו את תוצאות החיפוש</h3>
        <p>
          לצד התוצאות, תוכלו למצוא כלים בעזרתם תוכלו למקד או להרחיב את החיפוש.
          איך משתמשים בכלים ואיך הם משפיעים על התוצאות?
        </p>
        <h3>הטיות</h3>
        <p>צמצמו את ההטיות של מילת החיפוש, להטיות הרצויות. לדוגמא: עבור חיפוש של המילה אריה, תקבלו תוצאות כמו הָאַרְיֵה
          ולַאֲרָיֹות, ובנוסף גם הטיות אחרות של אריה, שמהם תוכלו לבחור את ההטיות הרלוונטיות עבורכם.
          הערה: אם הזנתם מילת חיפוש ספציפית, למשל לַאֲרָיֹות, תקבלו רק תוצאות המכילות את מילת החיפוש הספציפית.
        </p>
        <h3>ספרים</h3>
        <p>
          הגדירו מאילו ספרים אתם מעוניינים שיוצגו תוצאות.<br />
          שימו לב, עבור התוצאות שמופיעות בפילטר בצבע אפור בהיר לא נמצאו תוצאות. כדי לקבל תוצאות של פסקאות תוכלו לנסות
          לשנות את השילוב של מילות החיפוש או שתוכלו להוסיף מילים עם משמעות דומה באמצעות הפונקציה ״כלול מילים נרדפות״.
        </p>
        <h3>חיפוש ביטויים</h3>
        <p>כדי לחפש ביטוי או צמד מילים שמגיעים בזה אחר, השתמשו במרכאות.</p>
      </div>
      <div v-if="SearchType.Talmud && isSmallScreen">
        <p>מנוע החיפוש בתלמוד מבית דיקטה מאפשר חיפוש מילים וביטויים בתלמוד ובמשנה באופן אינטואיטיבי, מבלי להתעסק בהבדלי
          איות והטיה.
          תוצאות חיפוש של מילה אחת או יותר, יכללו פסקאות בהן מופיעות מילות החיפוש עם וריאציות באיות ובהטיה.
          התוצאות מופיעות לפי סדר הרלוונטיות. ניתן לבחור להציגן לפי סדר הופעתן בתלמוד.</p>
        <h3>נהלו את תוצאות החיפוש</h3>
        <p>לצד התוצאות, תוכלו למצוא כלים בעזרתם תוכלו למקד או להרחיב את החיפוש. כדי לנהל את תוצאות החיפוש, לחצו על
          הצלמית
          איך משתמשים בכלים ואיך הם משפיעים על התוצאות?
        </p>
        <h3>הטיות</h3>
        <p>צמצמו את ההטיות של מילת החיפוש, להטיות הרצויות. לדוגמא: עבור חיפוש של המילה אריה, תקבלו תוצאות כמו הָאַרְיֵה
          ולַאֲרָיֹות, ובנוסף גם הטיות אחרות של אריה, שמהם תוכלו לבחור את ההטיות הרלוונטיות עבורכם.
          הערה: אם הזנתם מילת חיפוש ספציפית, למשל לַאֲרָיֹות, תקבלו רק תוצאות המכילות את מילת החיפוש הספציפית.
        </p>
        <h3>ספרים</h3>
        <p>הגדירו מאילו ספרים אתם מעוניינים שיוצגו תוצאות.<br />
          שימו לב, עבור התוצאות שמופיעות בפילטר בצבע אפור בהיר לא נמצאו תוצאות. כדי לקבל תוצאות של פסקאות תוכלו לנסות
          לשנות את השילוב של מילות החיפוש או שתוכלו להוסיף מילים עם משמעות דומה באמצעות הפונקציה ״כלול מילים נרדפות״.
        </p>
        <h3>חיפוש ביטויים</h3>
        <p>כדי לחפש ביטוי או צמד מילים שמגיעים בזה אחר, השתמשו במרכאות.</p>
      </div>
      <small dir="ltr" class="d-block text-left border-top border-bottom py-3 mb-3 pl-3">
        HEBREW TEXT DATABASE ETCBC4B
        Peursen, Prof. Dr. W.T. van (Eep Talstra Centre for Bible And Computing, VU University Amsterdam); Sikkel, M.
        Sc. C. (Eep Talstra Centre for Bible And Computing, VU University Amsterdam); Roorda, Dr. D. (Data Archiving and
        Networked Services, Royal Netherlands Academy of Arts and Sciences) (2015): Hebrew Text Database ETCBC4b. DANS.
        <a target="_blank" href="https://doi.org/10.17026/dans-z6y-skyh">https://doi.org/10.17026/dans-z6y-skyh</a>
      </small>
      <div>
        הגרסה הדיגיטלית של תלמוד קורן נואה על שמו של וויליאם דייוידסון, יצא לאור בהוצאת קורן, מנוקד על ידי
        <a href="http://dicta.org.il/index-he.html" target="_blank">דיקטה - המרכז הישראלי לניתוח טקסטים</a>
        - ושוחרר תחת רשיון מסוג
        <a href="https://creativecommons.org/licenses/by-nc/4.0/legalcode" target="_blank">CC BY-NC</a>
      </div>
    </div>
    <div v-if="SearchType.Bible" class="how-to-inner-content">
      <h1 class="d-none d-md-block mb-3">איך זה עובד?</h1>
      <div class="para">
        <p class="mb-1">
          מנוע החיפוש בתנ״ך מבית דיקטה מאפשר חיפוש מילים וביטויים בתנ״ך באופן אינטואיטיבי, מבלי להתעסק
          בהבדלי איות והטיה.
        </p>
        <p class="mb-1">
          תוצאות חיפוש של מילה אחת או יותר, יכללו פסוק/פסוקים בהם מופיעות מילות החיפוש עם וריאציות באיות ובהטיה.
        </p>
        <p class="mb-1">
          התוצאות ממוינות לפי סדר התנ״ך. ניתן לבחור למיין אותן לפי רלוונטיות בעזרת לחיצה על התפריט ״מיון״ שנמצא מעל התוצאות.
        </p>
        <p class="mb-1">
          אנו עשויים להדגיש תוצאות מעניינות שאולי לא הבחנתם בהן, אלו יופיעו מעל התוצאות, תחת הכותרת ״ייתכן שיעניינו אתכם התוצאות הבאות״. (רק כאשר התוצאות ממוינות לפי סדר התנ״ך)
        </p>
      </div>

      <hr />
      <h2 class="heading-h2">חיפושים קרובים</h2>
      <p class="para">
        הכותרת ״חיפושים קרובים״ תופיע מתחת לתוצאות החיפוש, במידה ויש הצעות להרחבת תוצאות החיפוש. ההרחבה תכלול המרה של עברית מודרנית לעברית תנכית, קומבינציה שונה של מילות חיפוש ועוד.
        <br>
         לדוגמה: עבור מילות החיפוש ״שמח תשמח״ תקבלו הצעה ל ״שמח תגיל״, עבור מילת החיפוש ״הללויה״ תקבלו הצעה ל ״הללו י-ה״
      </p>
      <h2 class="heading-h2">נהלו את תוצאות החיפוש</h2>
      <p class="para">
        לצד התוצאות, תוכלו למצוא כלים בעזרתם תוכלו למקד או להרחיב את החיפוש.
        <br />איך משתמשים בכלים ואיך הם משפיעים על התוצאות?
      </p>

      <h3 class="heading-h3">משמעויות</h3>
      <p class="para">
        בחרו את המשמעות הרצויה של מילת החיפוש.
        <br />לדוגמה: אם מילת החיפוש היא
        <strong class="f-narkis">דוד</strong>, אפשר לבחור האם הכוונה היא ל דוד במשמעות של שם איש, או דוד במשמעות של בן
        משפחה?
        <br />בנוסף, ניתן להוסיף לתוצאות החיפוש מילים בעלות משמעות זהה למילת החיפוש.
        <br />לדוגמה: עבור מילת החיפוש
        <strong class="f-narkis">אריה</strong>, תוכלו לבקש גם תוצאות של
        <strong class="f-narkis">ליש</strong>,
        <strong class="f-narkis">לביא</strong> ו
        <strong class="f-narkis">כפיר</strong>.
      </p>

      <h3 class="heading-h3">הטיות</h3>
      <p class="para">
        צמצמו את ההטיות של מילת החיפוש, להטיות הרצויות.
        לדוגמא: עבור חיפוש של המילה
        <strong class="f-narkis">אריה,</strong>
        תקבלו תוצאות כמו
        <strong class="f-narkis">הָאַרְיֵה ולַאֲרָיֹות,</strong>
        ובנוסף גם הטיות אחרות של
        <strong class="f-narkis">אריה,</strong>
        שמהם תוכלו לבחור את ההטיות הרלוונטיות עבורכם.
        <br />הערה: אם הזנתם מילת חיפוש ספציפית, למשל
        <strong class="f-narkis">לַאֲרָיֹות,</strong>

        תקבלו רק תוצאות המכילות את מילת החיפוש הספציפית.
      </p>

      <h3 class="heading-h3">ספרים</h3>
      <p class="para">הגדירו מאילו ספרים אתם מעוניינים שיוצגו תוצאות.</p>
      <p class="para">שימו לב, עבור התוצאות שמופיעות בפילטר בצבע אפור בהיר לא נמצאו פסוקים. כדי לקבל תוצאות של פסוקים
        תוכלו לנסות לשנות את השילוב של מילות החיפוש או שתוכלו להוסיף מילים עם משמעות דומה באמצעות הפונקציה ״כלול מילים
        נרדפות״.</p>
      <p class="para">
        לדוגמא: עבור מילות החיפוש
        <strong class="f-narkis">״ששון חדווה״</strong> לא ימצאו פסוקים מתאימים, ולכן הן תופענה בפילטר בצבע אפור בהיר.
        כדי לקבל תוצאות אפשר להוסיף עבורן מילים נרדפות שנמצאות תחת ״כלול מילים נרדפות״, למשל
        <strong class="f-narkis">״גילה״</strong> ו
        <strong class="f-narkis">״רינה״</strong> שעבורן ימצאו 6 פסוקים.
      </p>
      <h3 class="heading-h3">מורפולוגיה ומשמעות</h3>
      <p class="para">
        לצד כל פסוק מופיעות שלוש נקודות כחולות. בלחיצה על הנקודות יופיעו הפירוט המורפולוגי והמשמעות של כל אחת ממילות
        פסוק. הפרוט המורפולוגי מציין את המבנה הדקדוקי של המילה. לדוגמא: המבנה המורפולוגי של
        <strong class="f-narkis">״עולם״</strong> הוא - זכר, יחיד, שם עצם, נפרד.
      </p>
      <h3 class="heading-h3">חיפוש ביטויים</h3>
      <p class="para">
        כדי לחפש ביטוי או צמד מילים שמגיעים בזה אחר, השתמשו במרכאות. לדוגמא: עבור
        <strong class="f-narkis">שלום בית</strong> תקבלו 116 תוצאות. עבור
        <strong class="f-narkis">״שלום בית״</strong> תקבלו 2 תוצאות.
      </p>
      <h3 class="heading-h3">הסרת מילים מתוצאות החיפוש</h3>
      <p class="para">
        כדי להסיר תוצאות הכוללות מילים מסוימות מתוצאות החיפוש יש לציין בתיבת החיפוש את המילה שתרצו להסיר ולהציב לפניה את
        הסימן מינוס, ללא רווח. לדוגמה: אם חיפשתם את המילה ״מנשה״ ואינכם רוצים לכלול בתוצאות תוצאה הכוללת את המילה
        ״אפרים״, הזינו בתיבת החיפוש את המילים כך: מנשה -אפרים.
      </p>
      <hr />

      <h3 class="heading-h3">דוגמאות לחיפושים שונים</h3>

      <ul class="list-ul">
        <li class="list-li">
          חיפוש על פי אותיות השורש
          <br />חפשו
          <strong class="f-narkis">״ברא״</strong> וקבלו
          <strong class="f-narkis">״וַיִּבְרָא״</strong>
        </li>

        <li class="list-li">
          כתיב מלא/חסר
          <br />חפשו
          <strong class="f-narkis">״דָּוִיד״</strong> וקבלו
          <strong class="f-narkis">״דָוִד״</strong>
        </li>

        <li class="list-li">
          סיומות שונות: גוף ראשון/שני/שלישי, זכר/נקבה, יחיד/רבים
          <br />לדוגמה סיומות גוף שני -
          <strong class="f-narkis">אֹתְכָה/אֹותְךָ</strong>
          <br />לדוגמה סיומות גוף שני, נקבה -
          <strong class="f-narkis">גַּרְתָּה/גַּרְתָּ</strong>
          <br />לדוגמה סיומת גוף שלישי -
          <strong class="f-narkis">כֻּלֹּה/כֻּלֹּו</strong>
        </li>

        <li class="list-li">
          <strong>א</strong>׳ חסרה או נוספת
          <br />חפשו
          <strong class="f-narkis">״ונטמאתם״</strong> וקבלו
          <strong class="f-narkis">״וְנִטְמֵתֶם״</strong>
          <br />חפשו
          <strong class="f-narkis">״ורציתי״</strong> וקבלו
          <strong class="f-narkis">״וְרָצִאתִי״</strong>
        </li>

        <li class="list-li">
          ה׳ חסרה או נוספת
          <br />חפשו
          <strong class="f-narkis">״כָּמֹוךָ״</strong> וקבלו
          <strong class="f-narkis">״כָמֹכָה״</strong>
        </li>

        <li class="list-li">
          אותיות מתחלפות
          <br />חפשו
          <strong class="f-narkis">״ימלא״</strong> וקבלו
          <strong class="f-narkis">״ימַלֵּה״</strong>
          <br />חפשו
          <strong class="f-narkis">״וארסתיך״</strong> וקבלו
          <strong class="f-narkis">״וְאֵרַשְׂתִּיךְ״</strong>
        </li>

        <li class="list-li">
          כל הכינויים של ה׳
          <br />חפשו
          <strong class="f-narkis">״א-להים״</strong> (עם מקף) וקבלו תוצאות של אותו הביטוי (באיות מלא) רק במשמעות של
          <strong class="f-narkis">הָאֱלֹהִ֣ים</strong>.
        </li>

        <li class="list-li">
          חיפוש באמצעות ספרות
          <br />חפשו
          <strong>״127״</strong>
          וקבלו
          <strong class="f-narkis">״וַיִּהְיוּ חַיֵּי שָׂרָה מֵאָה שָׁנָה וְעֶשְׂרִים שָׁנָה וְשֶׁבַע שָׁנִים״</strong>
        </li>
      </ul>
      <hr />
      <small class="text-left d-block px-3">HEBREW TEXT DATABASE ETCBC4B</small>
      <small class="para left text-left d-block px-3" style="direction:ltr">
        Peursen, Prof. Dr. W.T. van (Eep Talstra Centre for Bible And Computing, VU University Amsterdam); Sikkel, M.
        Sc. C. (Eep Talstra Centre for Bible And Computing, VU University Amsterdam); Roorda, Dr. D. (Data Archiving and
        Networked Services, Royal Netherlands Academy of Arts and Sciences) (2015): Hebrew Text Database ETCBC4b. DANS.
        <a href="https://doi.org/10.17026/dans-z6y-skyh">https://doi.org/10.17026/dans-z6y-skyh</a>
      </small>
    </div>
  </b-modal>
</template>

<script>
import { SearchType } from '../searchConfig.js'
export default {
  name: 'howPopupHebrew',
  props: ['value'],
  computed: {
    SearchType() {
      return SearchType
    },
    show: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  data() {
    return {
      mediaQuery: null,
      isSmallScreen: false
    }
  },
  mounted: function () {
    this.mediaQuery = window.matchMedia('(max-width: 767px)')
    this.isSmallScreen = this.mediaQuery.matches
  },
  methods: {
    hidePopup() {
      this.show = false
      history.replaceState('', document.title, window.location.pathname + window.location.search)
    }
  }
}
</script>
<style scoped lang="scss">
a {
  text-decoration: underline;
}

h3 {
  margin: 0;
  text-decoration: underline;
}

.mobile-modal-top {
  height: 50px;
  padding: 15px;
  position: fixed;
  width: 100%;
  z-index: 1;
  top: 0;
  right: 0;

  h3 {
    text-decoration: none;
    font-size: 20px;
  }
}

@media screen and (min-width: 992px) {
  .how-to-inner-content {
    max-height: 70vh;
    overflow: auto;
  }
}

.list-li {
  padding: 0 0 20px 18px;
}

.list-li:before {
  transform: rotate(180deg);
  left: 0;
}

@media screen and (max-width: 991px) {
  .how-to-inner-content {
    padding-top: 45px;
    height: calc(100vh - 100px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
</style>
