export function fragment(html, { targetLength= 80, contextLength = 18 } = {}) {
  const words = html.split(' ')
  if (words.length < targetLength) return html
  let matches = []
  words.forEach((word, i) => {
    if (word.startsWith('<b>'))
      matches.push(i)
  })
  // best would be: pick ranges, with each range no smaller than `contextLength`,
  // that cover as many matches as possible, not exceeding `targetLength` words
  // I don't know an algorithm for that, so instead choose by smallest gap
  const distances = matches.map((n, i) => [i === 0 ? n : n - matches[i - 1] - 1, i])
  distances.sort((a,b) => a[0] - b[0])
  const ranges = []
  const addRange = (s, e) => {
    let rangeIndex
    const start = ranges.findIndex(range => range[1] === s)
    const end = ranges.findIndex(range => range[0] === e)
    if (start !== -1) {
      rangeIndex = start
      ranges[start][1] = e
    } else {
      rangeIndex = ranges.length
      ranges.push([s, e])
    }
    if (end !== -1) {
      ranges[rangeIndex][1] = ranges[end][1]
      ranges.splice(end, 1)
    }
    const wordsTaken = ranges.reduce((total, range) => total + Math.max(range[1] - range[0] + 1, contextLength), 0)
    return targetLength - wordsTaken
  }
  while (distances.length) {
    const [dist, index] = distances.shift()
    const end = matches[index]
    // add a whole range if we can reach another match, just this word if we can't
    const rangeStart = (dist >= contextLength) ? end : end - dist - 1
    const wordsLeft = addRange(rangeStart, end)
    if (wordsLeft < 0) break
  }
  for (let range of ranges) {
    const length = range[1] - range[0] + 1
    if (length >= contextLength) continue
    const extra = Math.floor((contextLength - length) / 2)
    range[1] += extra
    range[0] -= extra
  }
  const inRange = i => ranges.some(range => i >= range[0] && i <= range[1])
  let counter = 0
  let out = []
  let last = -1
  for (let word of words) {
    if (inRange(counter)) {
      if (last !== (counter - 1))
        out.push('...')
      out.push(word)
      last = counter
    }
    counter++
  }
  if (last !== (counter - 1))
    out.push('...')
  return out.join(' ')
}
