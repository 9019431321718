<template>
  <div class="py-2 pb-md-3 px-3 mb-4 relevance-wrap" v-if="relevanceResults.length > 0 && !running && total > 10">
    <div>
      <h3 class="mb-2">
        {{ hebrew ? 'ייתכן שיעניינו אתכם התוצאות הבאות' : 'You may be interested in the following results' }}
      </h3>
    </div>
    <ul class="result-list text-right list-unstyled mb-1" dir="rtl">
      <li v-for="(result, index) in relevanceResults" class="mx-0 relevance-li p-0 mb-3 mb-md-1" :key="index">
        <span v-html="relevanceDisplay[index]">
        </span>
        <small class="mx-2">
          <span class="text-muted">({{ trimPath(result.hebrewPath) }})</span>
        </small>
      </li>
    </ul>
    <small class="show-all" @click="setTemporarySortBy('relevance')">
      <i class="fas fa-arrow-left" v-if="hebrew"></i><i class="fas fa-arrow-right" v-else></i>
      <u>
      {{
        hebrew ? 'הצג את כל התוצאות של \'מיון לפי רלוונטיות\'' : 'Sort by relevance'
      }}
      </u>
    </small>
  </div>
</template>
<script>
import { trimPath } from './trimPath.js'
import { fragment } from '@/components/result/fragment'
import processTextMixin from '@/mixins/processTextMixin'
import { mutationHelpers } from '@/store'
import { stateHelpers } from '../../store'

export default {
  name: 'relevance-box',
  props: {
    hebrew: {},
    getChapter: {},
    relevanceResults: {},
    running: {},
    total: {}
  },
  computed: {
    relevanceDisplay() {
      if (!this.relevanceResults) return []
      return this.relevanceResults.map(r =>
        fragment(
          this.preProcessResultText(
            r.highlight.map(h => h.text).join(' ')
          ),
          { targetLength: 15, contextLength: 10 }
        )
      )
    },
    ...stateHelpers
  },
  mixins: [processTextMixin],
  methods: {
    trimPath,
    ...mutationHelpers
  }
}
</script>
<style scoped lang="scss">
.relevance-wrap {
  border-top: dashed 1px #bfbfbf;
  border-bottom: dashed 1px #bfbfbf;
  @media screen and (max-width: 991px) {
 

    small {
    
      span {
        white-space: nowrap;
      }

     
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.show-all {
  cursor: pointer;
  i {
    font-size: 11px;
  }
  &:hover {
    color: var(--primary);
  }
}


.result-list {
  overflow: hidden;
  padding: 0;
  font-weight: normal;

  @media screen and (max-width: 991px) {
    margin-bottom: 12px;
  }
}

.relevance-li {
  >span {
    font-family: "mft_narkisclassic", Roboto, sans-serif;
  }


}

.chapter-link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 991px) {
    text-decoration: underline 1px;
  }
}
</style>
