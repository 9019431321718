import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home'
import Result from '@/components/result/result'
import ApiChildHandler from '../components/ApiChildHandler.vue'
import config from '../config'

Vue.use(VueRouter)
export default new VueRouter({
  mode: 'history',
  routes: [

    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/result',
      name: 'Result',
      component: Result,
      props: (route) => ({query: route.query.text})
    },
    {
      path: '/he',
      name: 'hebrewHome',
      component: Home
    },
    {
      path: '/he/result',
      name: 'hebrewResult',
      component: Result,
      props: (route) => ({query: route.query.text})
    },
    {
      path: config.SERVER_ROOT + '/*',
      name: 'api-child-handler',
      component: ApiChildHandler
    }
  ]
})
