
<template>
 <b-modal
        id="chapterModal"
        :centered="!isSmallScreen"
        size="lg"
        dir="rtl"
        body-class="d-flex flex-column p-3 f-narkis text-right"
        header-class="bg-primary text-white text-right"
        footer-class="border-top d-none d-sm-block"
        ok-variant="secondary"
        :ok-title="hebrew ? 'סגור' : 'Close'"
        ok-only
        @hide="hidePopup"
        @show="scrollIntoView"
        v-model="show"
    >
     <template slot="modal-header">
          <h3
            class="my-0 text-white pl-4 pl-sm-0"
            :class="{expanded: expanded}"
            @click="expanded = !expanded"
          >{{chapter.hebrewTitle}}</h3>
          <small class="d-none d-sm-block">
            <a class="text-white" v-bind:href="''+chapter.sefariaLink+''"  target="_blank" rel="noopener noreferrer">
             {{hebrew ? 'צפו בפירושים ובחידושים [ספריא]' : 'See commentary in Sefaria'}}
            </a></small>
          <span class="d-block d-sm-none text-primary text-left">
            <small class="d-none"><i class="fa fa-share-square text-white" aria-hidden="true"></i></small>
            <a class="sefaria-mobile text-white mx-2" v-bind:href="''+chapter.sefariaLink+''"  target="_blank" rel="noopener noreferrer">
            {{hebrew ? 'ספריא' : 'Sefaria'}}
            </a>
            <div @click="hidePopup" class="close-icon text-white">&times;</div>
        </span>
      </template>
      <div class="chapter-popup-content">
        <ul class="verses-list paragraph list-unstyled">
          <li class="verses-li position-relative"
              dir="rtl"
              v-for="(sentence, key) in chapter.text"
              :class="{'d-block mb-2 mr-n4': !chapter.text[0].label, highlight: chapter.pasuk ? chapter.pasuk === key + 1 : chapter.xmlId === sentence.xmlId }"
              :key="sentence._id"
              ref="pasuk"
          >
            <span class="mb-1">
                <p class="pasuk-letter mb-0 position-relative w-auto" v-if="sentence.label"><small class="">{{ sentence.label }}</small></p>
                <div class="d-inline pasuk-line" v-bind:class="{'talmud-line': !sentence.label}"><p v-html="preProcessResultText(sentence.text)" class="d-inline my-0"></p></div>
            </span>
          </li>
        </ul>
      </div>
  </b-modal>
</template>

<script>
import {SearchType} from '../../searchConfig.js'
import processTextMixin from '@/mixins/processTextMixin'
import {stateHelpers} from '../../store'

export default {
  name: 'chapterPopup',
  props: ['value', 'chapter'],
  data () {
    return {
      expanded: false,
      mediaQuery: null,
      isSmallScreen: false
    }
  },
  mixins: [
    processTextMixin
  ],
  mounted: function () {
    this.mediaQuery = window.matchMedia('(max-width: 767px)')
    this.isSmallScreen = this.mediaQuery.matches
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    SearchType () {
      return SearchType
    },
    hebrew () {
      return this.$settings.hebrew
    },
    ...stateHelpers
  },
  methods: {
    hidePopup () {
      this.expanded = false
      this.show = false
      // history.pushState('', document.title, window.location.pathname + window.location.search)
    },
    scrollIntoView () {
      setTimeout(function () { document.getElementsByClassName('highlight')[0].scrollIntoView({ behavior: 'smooth', block: 'start' }) }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
  @media only screen and (max-width: 991px) {
    .close-icon {
      top: 6px;
      display: inline;
      font-size: 34px;
      line-height: 15px;
      position: relative;
    }
    h3{
      padding: 5px 0;
      font-size:20px;
      font-family: Arimo;
      width: 90%;
      width: 60%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x: hidden;
      &.expanded {
        white-space: normal;
      }
    }
  }
  .sefaria-mobile {
    font-family: Arimo;
    text-decoration: underline;
    i {
      font-size: 10px;
      position: relative;
      top:-1px;
    }
  }
  ul{
    overflow-x:hidden;
  }
  li{
    display: inline;
    font-size: 20px;
  }
  .pasuk-letter{
    right: -32px;
    top: 25px;
    font-family: 'Arimo';
    margin-top: -20px;
  }

  .highlight {
    span{
      background-color: #d6ecff;
      border-radius: 2px;
    }
  }
  @media screen and ( max-width: 767px ) {
    .chapter-popup-content {
      padding-top: 50px;
    }
  }
  @media screen and (max-width: 667px) {
    ul{
      max-height: 80vh;
    }
  }
  @media screen and ( min-width: 768px ) {
    ul{
      max-height: 70vh;
      overflow-y: auto;
    }
  }
</style>
