<template>
  <transition name="slide-mobile">
    <div class="next-slide-txt" id="sorted-slide" v-if="expanded">

      <div class="top-sidebar">
        <a class="back-button f blue" @click="$emit('toggle')"><span v-if="!hebrew">Back</span> <span
            v-if="hebrew">חזור</span></a>
      </div>
      <div class="text-holder">
        <h3 class="heading-h3">{{ hebrew ? 'הצג לפי' : 'Sorted by' }}</h3>
        <div class="list-holder">
          <ul class="inner-menu-list mb-0">
            <li class="list-item double-list-item">
              <a class="list-link">
                <Radiobox :checked="mobileSortBy !== 'relevance'" @change="setMobileSortBy('tanach order')">
                  <h3 class="heading-h3 text-width" v-if="SearchType.Bible">{{ hebrew ? 'סדר התנ״ך' : 'Tanach Order' }}
                  </h3>
                  <h3 class="heading-h3 text-width" v-else-if="SearchType.Talmud">
                    {{ hebrew ? 'סדר התלמוד' : 'Talmud Order' }}</h3>
                  <h3 class="heading-h3 text-width" v-else>{{ hebrew ? 'סדר הקורפוס' : 'Corpus Order' }}</h3>
                </Radiobox>
              </a>
            </li>
            <li class="list-item double-list-item">
              <a class="list-link">
                <Radiobox :checked="mobileSortBy === 'relevance'" @change="setMobileSortBy('relevance')">
                  <h3 class="heading-h3 text-width"> {{ hebrew ? 'רלוונטיות' : 'Relevance' }}</h3>
                </Radiobox>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="sidebar-bottom-button-div">
        <button type="button" class="bt-lg sidebar-bottom-button button" id="save-mobile-sort" @click="saveMobileSort">
          <h2 class="f white btn-text">{{ hebrew ? 'שמירה' : 'Save' }}</h2>
        </button>
      </div>
    </div>
  </transition>
</template>
<script>
import sidebarMixin from '../../mixins/sidebarMixin'
import Radiobox from './Radiobox'
import { SearchType } from '../../searchConfig.js'
import { mapState } from 'vuex'
export default {
  name: 'sort-order-mobile',
  components: { Radiobox },
  mixins: [sidebarMixin, Radiobox],
  props: {
    expanded: {
      type: Boolean,
      default: () => { return false }
    }
  },
  data() {
    return {
      mobileSortBy: this.sortBy
    }
  },
  methods: {
    setMobileSortBy(sortBy) {
      this.mobileSortBy = sortBy
    },
    saveMobileSort() {
      //this.$emit('done')
      this.setSortBy(this.mobileSortBy)
      this.$emit('toggle')
    }
  },
  computed: {
    SearchType() {
      return SearchType
    },
    isExpanded() {
      return this.expanded
    },
    ...mapState(['sortBy'])
  },
  watch: {
    isExpanded(val) {
      if (val) {
        this.mobileSortBy = this.$store.state.sortBy
      }
    }
  }
}
</script>
<style scoped src="../../assets/style/common-mobile-sidebar.css">
</style>
<style scoped>
</style>
