<template>
  <li>
    <div class="result-li-top-section">
      <a href="#" @click="getChapter(result.xmlId, result.unit_type)"
         class="f blue result-li-title">
        <em class="first-word"></em><span class="text-primary">{{ trimPath(result.hebrewPath) }}</span></a>
      <div class="result-li-resultBottom"
           dir="rtl"
           v-for="(sentence, index) in result.highlight"
           :key="sentence._id"
      >
        <div v-if="shouldShowEllipsis(sentence, index)">...</div>
        <div v-if="SearchType.Talmud || sentence.containsMatch"
             class="sentence-holder">
          <span :style="{'font-size': resultFontSize + 'px'}" v-if="sentence.label" class="pasuk-indent reset-line-height">{{ sentence.label }}&nbsp;</span>
          <span :style="{'font-size': resultFontSize + 'px'}" class="reset-line-height"
                v-html="fragment(preProcessResultText(sentence.text))"></span>
          <a v-if="settings || !(SearchType.Talmud)"  class="list-collapse-btn hidden-mobile-view"  @click="toggleShowDetail(index)">
            <img src="../../assets/Points.png" class="collapse-btn-icon"/>
          </a>
          <a v-if="settings || !(SearchType.Talmud)" href="#" class="list-collapse-btn show-mobile-view" @click="toggleShowDetail(index, true)">
            <img src="../../assets/Points.png" class="collapse-btn-icon"/>
          </a>
        </div>
        <!--Start for desktop view-->
        <MorphologyAndLexemesDesktop v-if="showDetail === index && !mobile"
                                     :lastAnalysis="(index + 1) === result.highlight.length"
                                     :analysis="analysisArray[index]"></MorphologyAndLexemesDesktop>
        <!--end desktop view-->
        <MorphologyAndLexemesMobile v-if="showDetail === index && mobile"
                                    :label="result.hebrewPath"
                                    :sentence-label="sentence.label"
                                    :sentence="preProcessResultText(sentence.text)"
                                    :analysis="analysisArray[index]"
                                    @hide="toggleShowDetail(index, true)"
        />
      </div>
      <div v-if="this.$settings.debug">{{result.match_type}}</div>
    </div>
  </li>
</template>
<script>
import MorphologyAndLexemesDesktop from './MorphologyAndLexemesDesktop'
import {stateHelpers} from '../../store'
import MorphologyAndLexemesMobile from './MorphologyAndLexemesMobile'
import {SearchType} from '../../searchConfig.js'
import {trimPath} from './trimPath'
import processTextMixin from '@/mixins/processTextMixin'
import {fragment} from '@/components/result/fragment'

export default {
  name: 'SingleResult',
  components: {MorphologyAndLexemesMobile, MorphologyAndLexemesDesktop},
  props: {
    getChapter: {},
    result: {},
    analysis: {}
  },
  data () {
    return {
      showDetail: -1,
      mobile: false,
      trimPath
    }
  },
  mixins: [
    processTextMixin
  ],
  computed: {
    settings () {
      return this.$settings.debug
    },
    SearchType () {
      return SearchType
    },
    analysisArray () {
      return this.analysis ? this.analysis : []
    },
    lastMatchIndex () {
      return this.result.highlight.map(s => s.containsMatch).lastIndexOf(true)
    },
    ...stateHelpers
  },
  methods: {
    toggleShowDetail (index, mobile) {
      this.mobile = !!mobile
      this.showDetail = this.showDetail === index ? -1 : index
    },
    shouldShowEllipsis (sentence, index) {
      return !(SearchType.Talmud) &&
        !sentence.containsMatch &&
        index > 0 &&
        index - 1 !== this.lastMatchIndex &&
        this.result.highlight[index - 1].containsMatch
    },
    fragment
  }
}
</script>
<style scoped lang="scss">
  .reset-line-height {
    line-height: 1.25;
  }
  .pasuk-indent {
    min-width: 2em;
    display: inline-block;
  }
  .result-li-title {
    display: block;
    font-size: 12px;
    line-height: 15px;
    cursor: pointer;
    margin: -12px -12px 0 -12px;
    padding: 8px 15px;
  }

  .result-li-title:hover {
    text-decoration: underline;
  }

  .result-li-title span:hover{
    text-decoration: underline;
  }

  /*.result-li p {*/
  /*margin: 0;*/
  /*}*/
  .result-li-top-section {
    padding: 12px;
  }

  .result-li-resultBottom {
    padding: 0 2px;
  }

  .sentence-holder {
    font-family: Roboto, 'mft_narkisclassic', sans-serif;
    position: relative;
    padding-left: 30px;
  }

  .list-collapse-btn {
    position: absolute;
    bottom: 5px;
    left: 2px;
    cursor: pointer;
    width: 21px;
    height: 10px;
    border-radius: 2px;
    z-index: 1;
  }

  .list-collapse-btn:hover,
  .list-collapse-btn:focus {
    background: #E3E3E3;
  }

  .collapse-btn-icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    /* translate forces some browsers to render on a pixel. Rendering this icon on a subpixel yields ugly results */
    transform: translate(0, 0);
  }

  /*.result-main {*/
  /*!*height: 90vh;*!*/
  /*}*/
  @media screen and ( max-width: 991px ) {
    .result-li-title {
      border-bottom: 1px solid #E2E2E2;
      background-color: #fbfbfb;
      margin: -12px -12px 15px -12px;
    }

    .result-li-resultBottom .sentence-holder{
      min-height: 51px;
    }

    /*.he .list-collapse-accordion-link:after{*/
    /*right:auto;*/
    /*left: 22px;*/
    /*background: url("../../assets/Triangle-down.png") no-repeat;*/
    /*}*/
    /*.he .list-collapse-accordion-link.active:after{*/
    /*background: url("../../assets/Triangle-up.png") no-repeat;*/
    /*}*/
  }

</style>
