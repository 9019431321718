const fullCorpusName = process.env.VUE_APP_CORPUS
let corpusNumber = fullCorpusName ? fullCorpusName.replace(/[^\d]/g, '') : null
if (!(corpusNumber)) corpusNumber = 'no-corpus-number'
let DNSbaseForBackend = process.env.VUE_APP_DNS_BASE_FOR_BACKEND
if (!(DNSbaseForBackend)) DNSbaseForBackend = 'no-dns-base-for-backend'

export default {
  apiTrafficManager: process.env.VUE_APP_SEARCH_TYPE === 'Bible'
	? 'https://tanach-search-3-4.loadbalancer.dicta.org.il'
	: (process.env.VUE_APP_SEARCH_TYPE === 'Talmud'
		? 'https://talmud-search.loadbalancer.dicta.org.il'
		: 'https://generic-search-' + corpusNumber + '.' + DNSbaseForBackend + '.dicta.org.il'
		// : 'http://18.223.116.76'
	),
  corpusType: (fullCorpusName && fullCorpusName.startsWith('library')) ? 'dicta-library' : 'no-corpus-type'
}
