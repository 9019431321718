<template>
<div>
  <b v-if="relatedLexemes.length > 0"><a class="f gray expand-area-text" @click="expanded = !expanded">
    <span>{{hebrew ? 'כלול מילים נרדפות': 'Include synonyms'}}
      <i v-if="!expanded" class="fa fa-caret-down"></i>
      <i v-if="expanded" class="fa fa-caret-up"></i>
    </span>
  </a></b>
  <transition name="slide-fade">
    <ul v-if="expanded" class="expand-area">
      <li class="expand-item" v-for="related in relatedLexemes" :key="related._id">
        <label class="switch"
               :class="{'no-results': related.count === 0}"
        >
          <input type="checkbox"
                 :checked="relatedLexemesSelected[related.relatedLexeme]"
                 @change="$emit('changeRelated', related.relatedLexeme)"
          >
          <span class="slider round"></span>
          <p v-if="!hebrew" dir="rtl" class="switch-text f-narkis">{{related.relatedBaseText}}</p>
          <p v-if="hebrew" dir="ltr" class="switch-text f-narkis">{{related.relatedBaseText}}</p>
        </label>
      </li>
    </ul>
  </transition>
</div>
</template>

<script>
import sidebarMixin from '../../mixins/sidebarMixin'

export default {
  name: 'Synonyms',
  mixins: [sidebarMixin],
  props: ['relatedLexemes', 'relatedLexemesSelected'],
  data () {
    return {
      expanded: false
    }
  }
}
</script>

<style scoped>
  .switch-text{
    margin: 0;
    color: #333;
    font-size: 18px;
    line-height: 21px;
  }
   .switch {
    position: relative;
    display: block;
    width: 100%;
    margin: 0;
    font-weight: 400;
    padding-left: 45px;
  }
   .he .switch {
    padding-left: auto;
    padding-right: 45px;
  }
  .expand-area{
    margin: 8px 22px 0 0 !important;
    background: #fff;
    list-style: none;
    padding: 0;
    border-radius: 2px;
  }
  .he .expand-area {
    margin: 8px 0 0 22px !important;
  }
   .expand-item {
     margin-bottom: 0 !important;
     padding: 5px 8px;
   }
  .expand-area-text{
    margin: 8px 0 0 0 !important;
    display: block;
    color: #000;
    font-weight: 400;
    cursor: pointer
  }
   .switch input {
     display:none;
   }
   .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 1px;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
    float: right;
    width: 32px;
    height: 16px;
    border-radius: 8px;
    background-color: #e1e1e1;
  }
   .he .slider {
    left: auto;
    right: 1px;
  }
   .slider:before {
    position: absolute;
    content: "";
    width: 16px;
    height: 16px;
    background-color: #b5b5b5;
    left: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
  }

   .he .slider:before {
    left: auto;
    background-color: #b5b5b5;
    right: 0;
  }

   input:checked + .slider {
    background-color: #c3e2fc;
  }
   .he input:checked + .slider {
    background-color: #007ee5;
  }

   input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

   input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
    background-color: #007ee5;
  }
   .he input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(-16px);
    background-color: #c3e2fc;
  }
  /* Rounded sliders */
   .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
</style>
