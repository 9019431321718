<template>
  <div class="top-sidebar-filter">
    <div class="btn-group w-100">
      <div class="btn-group text-select f-narkis">
        <button v-if="SearchType.Bible" type="button" class="btn top-filter-common-btn text-select-btn has-tooltip"
                :class="{active: showNikudAndTeamim === NikudAndTeamim.BOTH}"
                @click="setShowNikudAndTeamim(NikudAndTeamim.BOTH)"
                v-b-tooltip.hover.bottom :title="hebrew ? 'ניקוד וטעמים' : 'With Te\'amim and Nikud'"
                >
          <h3 class="text-select-text">אָ֭</h3>
          
        </button>
        <button type="button" class="btn top-filter-common-btn text-select-btn has-tooltip"
                :class="{active: showNikudAndTeamim === NikudAndTeamim.NIKUD}"
                @click="setShowNikudAndTeamim(NikudAndTeamim.NIKUD)"
                v-b-tooltip.hover.bottom :title="hebrew ? 'עם ניקוד' : 'With Nikud'"
                >
          <h3 class="text-select-text">אָ</h3>
        </button>
        <button type="button" class="btn top-filter-common-btn text-select-btn has-tooltip"
                :class="{active: showNikudAndTeamim === NikudAndTeamim.NONE}"
                @click="setShowNikudAndTeamim(NikudAndTeamim.NONE)"
                v-b-tooltip.hover.bottom :title="hebrew ? 'ללא ניקוד' : 'No Nikud'"
                >
          <h3 class="text-select-text">א</h3>
        </button>
      </div>
      
      <div class="btn-group zoom-select">
        <button @click="zoomIn" type="button" class="btn top-filter-common-btn zoom-select-btn"><i class="zoom-icon fa fa-search-plus" aria-hidden="true"></i></button>
        <button @click="zoomOut" type="button" class="btn top-filter-common-btn zoom-select-btn" :class="{disabled: resultFontSize === 12}"><i class="zoom-icon fa fa-search-minus" aria-hidden="true"></i></button>
      </div>
    
      <div class="btn-group download-dropdown">
        <!--<button type="button" class="btn top-filter-common-btn dropdown-toggle download-toggle has-tooltip" data-toggle="dropdown">-->
         <button type="button" class="btn top-filter-common-btn text-select-btn dropdown-toggle download-toggle has-tooltip" 
          data-toggle="dropdown"
          v-b-tooltip.hover.bottom :title="hebrew ? 'הורדת קובץ תוצאות' : 'Download the results'"
          >
          <span class="d-block text-center"><i class="fa fa-download" aria-hidden="true"></i></span>          
        </button>

        <ul class="dropdown-menu" role="menu" @click="keepOpen">
          <li class="list-item">
            <a href="#" class="list-link">
              <Radiobox
              class="top-filler-check"
              name="downloadType"
              value="HTML"
              @change="downloadType = $event"
              :checked="downloadType === 'HTML'"
              >
                <p class="check-text" v-if="!hebrew">HTML</p>
                <p class="check-text" v-if="hebrew">קובץ HTML</p>
              </Radiobox>
            </a>
          </li>
          <li class="list-item">
            <a href="#" class="list-link">
              <Radiobox
                class="top-filler-check"
                name="downloadType"
                value="TXT"
                @change="downloadType = $event"
                :checked="downloadType === 'TXT'"
              >
                <p class="check-text" v-if="!hebrew">TXT</p>
                <p class="check-text" v-if="hebrew">קובץ TXT</p>
              </Radiobox>
            </a>
          </li>
          <li class="list-item">
            <a href="#" class="list-link">
              <Radiobox
                class="top-filler-check"
                name="downloadType"
                value="CSV"
                @change="downloadType = $event"
                :checked="downloadType === 'CSV'"
              >
                <p class="check-text" v-if="!hebrew">CSV</p>
                <p class="check-text" v-if="hebrew">קובץ CSV</p>
              </Radiobox>
            </a>
          </li>
          <li class="list-item">
            <a href="#" class="list-link">
              <Radiobox
                  class="top-filler-check"
                  name="downloadType"
                  value="DOCX"
                  @change="downloadType = $event"
                  :checked="downloadType === 'DOCX'"
              >
                <p class="check-text" v-if="!hebrew">Word</p>
                <p class="check-text" v-if="hebrew">קובץ Word</p>
              </Radiobox>
            </a>
          </li>
          <hr class="dropdown-divider"/>
          <Checkbox class="top-filler-check" v-model="replaceShemos">
            <p class="check-text" v-if="!hebrew">Do not include the<br/>
              שמות קדושים</p>
            <p class="check-text" v-if="hebrew">לא לכלול שמות קדושים</p>
          </Checkbox>

          <Downloader :download-type="downloadType" :replace-shemos="replaceShemos">
            <button type="submit" ref="download" class="btn top-filter-common-btn download-btn">
              {{hebrew ? 'הורדה' : 'Download'}}
            </button>
          </Downloader>
        </ul>
      </div>
      <div class="btn-group page-dropdpown xposition-absolute" >
        <button type="button" class="btn top-filter-common-btn dropdown-toggle page-toggle" data-toggle="dropdown">
          <i class="fa fa-angle-down" aria-hidden="true"></i>
          <!--<span class="caret"></span>-->
        </button>
        <ul class="dropdown-menu page-dropdpown-menu list-inline" role="menu">
          <p v-if="!hebrew" class="number-of-pages">Results Per Page</p>
          <p v-if="hebrew" class="number-of-pages">תוצאות בעמוד</p>
          <li class="list-item d-inline-block px-1">
            <a href="#" class="list-link">
              <Radiobox
                class="top-filler-check"
                name="resultsPerPage"
                value="10"
                :checked="resultsPerPage === 10"
                @change="setResultsPerPage(10)"
              >
                <p class="check-text">10</p>
              </Radiobox>
            </a>
          </li>
          <li class="list-item d-inline-block px-1">
            <a href="#" class="list-link">
              <Radiobox
                class="top-filler-check"
                name="resultsPerPage"
                value="50"
                :checked="resultsPerPage === 50"
                @change="setResultsPerPage(50)"
              >
                <p class="check-text">50</p>
              </Radiobox>
            </a>
          </li>
          <li class="list-item d-inline-block px-1">
            <a href="#" class="list-link">
              <Radiobox
                class="top-filler-check"
                name="resultsPerPage"
                value="100"
                :checked="resultsPerPage === 100"
                @change="setResultsPerPage(100)"
              >
                <p class="check-text">100</p>
              </Radiobox>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Radiobox from './Radiobox'
import Checkbox from './Checkbox'
import {mutationHelpers, stateHelpers, NikudAndTeamim} from '../../store'
import Downloader from './Downloader'
import {SearchType} from '../../searchConfig.js'

export default {
  name: 'TopFilterTool',
  components: {Downloader, Radiobox, Checkbox},
  computed: {
    SearchType () {
      return SearchType
    },
    hebrew () {
      // return this.$route.name.includes('hebrew')
      return this.$settings.hebrew
    },
    ...stateHelpers
  },
  data () {
    return {
      downloadType: 'HTML',
      replaceShemos: false,
      NikudAndTeamim
    }
  },
  methods: {
    keepOpen (evt) {
      if (evt.target !== this.$refs.download) {
        console.log(evt, this.$refs)
        evt.stopPropagation()
      }
    },
    ...mutationHelpers
  }
}
</script>

<style scoped lang="scss">
   .dropdown-toggle:after{
     border:none;
   }
   .top-filter-common-btn{
    background: #E3E3E3;
    width: 28.5px!important;
  }
  .he .top-filter-common-btn{
    float: right;
  }
   .dropdown-divider{
     margin-bottom: 15px;
     margin-top: 15px;
     border-top: 1px solid #D8D8D8;
   }
   .number-of-pages{
    font-weight: normal;
     margin-bottom: 10px;
     font-size: 13px;
     width: 100%;
   }
   .he .number-of-pages{
     float: right;
   }
  .top-sidebar-filter {
    height: 36px;
    background: #E3E3E3;
    margin-bottom: 20px;
    border-radius: 2px;
  }
  .text-select-btn{
    padding: 0;
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
   .top-filter-common-btn:active,
   .top-filter-common-btn.active
  {
    background: #fff;
    box-shadow:  none;
    border-radius: 2px !important;
    outline:none;
    box-shadow: none!important;
  }
   .top-filter-common-btn:hover{
    background: #F3F3F3;
    box-shadow:  none;
    border-radius: 2px !important;
    outline:none;
  }
  .text-select{
    border-right: 1px solid #D7D7D7;
    height: 100%;
    padding: 6px 6px;
  }
  .he .text-select{
    float: right;
    border-right:0;
    border-left: 1px solid #D7D7D7;
  }
  .text-select-text{
    font-size: 20px;
    line-height: 16px;
    margin: 0;
  }
  .zoom-select{
    border-right: 1px solid #D7D7D7;
    padding: 6px 6px;
  }
  .he .zoom-select{
    float: right;
    border-right: 0;
    border-left: 1px solid #D7D7D7;
  }
   .zoom-icon{
    font-size: 18px;
    position: relative;
    top: 1.3px;
  }
   .zoom-select-btn{
    padding: 0;
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .he .zoom-select-btn{
    padding: 0;
  }
  .page-dropdpown{
    border-left: 1px solid #D7D7D7;
  }
  .he .page-dropdpown{
    border-right: 1px solid #D7D7D7;
  }
   .download-dropdown{
    padding: 6px 6px;
  }
  .he .download-dropdown{
    float: right;
    border-right: 0;
  }
   .download-toggle{
    height: 24px;
    //width: 64px;
    padding: 2px 0 0;
    border: 0;
  }
   /*.download-toggle img{*/
    /*margin-right: 6px;*/
  /*}*/
   .page-dropdpown{
    padding: 6px 5.5px;
  }
   .page-toggle{
    padding: 0;
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0;
  }
   .page-dropdpown-menu{
     height: 70px;
     padding: 8px 10px;
   }
   /*.download-dropdown.show .dropdown-toggle,*/
   /*.page-dropdpown.show .dropdown-toggle{*/
    /*background: #fff;*/
    /*box-shadow:  none;*/
    /*border-radius: 2px !important;*/
  /*}*/
   /*.d-details-list .title{padding: 9px 0;}*/
   .dropdown-menu{
    min-width: 200px;
    padding: 10px 10px;
    -webkit-box-shadow: 2px 2px 3px 0 rgba(0,0,0,.14);
    box-shadow: 2px 2px 3px 0 rgba(0,0,0,.14);
    border-radius: 2px;
    left: -2px !important;
  }
  .he .dropdown-menu{
    text-align: right;
    right: 10px;
    left: auto !important;
  }
   .list-item{
    margin-bottom: 0;
  }
  .list-link{
    padding:0;
  }
   .list-link:hover{
    background: transparent;
    text-decoration: none;
  }
   .download-btn {
    font-size:13px;
    width:70px;
    height:24px;
    padding:0;
    line-height: 1.8;
    border-radius: 2px;
  }
  .he .page-toggle {
    padding: 2px 4px;
  }
  .top-filler-check {
    position: relative !important;
    margin-bottom: 10px !important;
    color: #000 !important;
  }
  @media screen and ( max-width: 1199px ) {
     .page-dropdpown{
      padding: 4px 0;
    }
     .text-select{
      padding: 5px 0 5px 2px;
    }
    .text-select-btn{
      width: 20px;
    }
     .zoom-select-btn {
      padding: 2px 4px;
    }
     .zoom-select{
      padding: 4px 0;
    }
     .download-dropdown{
      padding: 4px 0;
    }
  }
</style>
