import { render, staticRenderFns } from "./chapterPopup.vue?vue&type=template&id=7f449659&scoped=true&"
import script from "./chapterPopup.vue?vue&type=script&lang=js&"
export * from "./chapterPopup.vue?vue&type=script&lang=js&"
import style0 from "./chapterPopup.vue?vue&type=style&index=0&id=7f449659&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f449659",
  null
  
)

export default component.exports