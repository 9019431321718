<template>
  <li class="accordion-li books-accordion add-border">
    <a id="books" class="f black link" :class="{active: expanded}" @click="$emit('toggle')"><span v-if="!hebrew">Books</span><span
      v-if="hebrew">ספרים</span></a>
      <p v-if="loading" class="new-label">{{ hebrew ? 'טוען נתונים' : 'Loading'}}...</p>
    <div v-if="!expanded && !booksFailed">
      <p id="new-label-books" v-if="!hebrew" dir="ltr" class="f gray new-label"> {{countSelected(booksSelected)}} Books
        selected </p>
      <p id="new-label-books-he" v-if="hebrew" dir="rtl" class="f gray new-label"> {{countSelected(booksSelected)}}
        ספרים נבחרו </p>
    </div>
    <transition name="slide-fade">
      <div v-if="expanded" class="slide">
        <ul class="inner-accordion">
          <li class="inner-accordion-li" v-if="expanded">
            <SelectAll
              id="book_section_checkbox"
              :checked="allBooksChecked(books)"
              @change="bookCheckAll(books)"
              :partial="partialBooksChecked(books)"
            >
            </SelectAll>
          </li>
          <li class="inner-accordion-li inner-accordion-result-alert" v-if="JSON.stringify(books) === '{}'"><span v-if="!hebrew">No Results</span><span v-if="hebrew">לא נמצאו תוצאות</span>
          </li>
          <li class="inner-accordion-li" v-for="bookGroup in books" :key="bookGroup.category">
            <a data-id="book_group_heading"
               class="inner-accordion-link"
               :class="{selected: bookChildVisible[bookGroup.category]}"
               @click="bookChildVisible[bookGroup.category] = !bookChildVisible[bookGroup.category]">
              <span v-if="!hebrew" dir="rtl" class="f black inner-accordion-text"> {{bookGroup.category}}</span>
              <span v-if="hebrew" dir="ltr" class="f black inner-accordion-text"> {{bookGroup.category}}</span>
            </a>
            <transition name="slide-fade">
              <div v-if="bookChildVisible[bookGroup.category]"
                   class="inner-accordion-content"
              >
                <SelectAll data-id="book_group_checkbox"
                            :checked="allBooksInGroupChecked(bookGroup.data)"
                            @change="bookGroupCheck(bookGroup.data)"
                            :partial="partialBooksInGroupChecked(bookGroup.data)"
                ></SelectAll>
                <ul class="slide-list">
                  <li v-for="listitem in bookGroup.data" class="slide-li" :key="listitem.bookId">
                    <Checkbox data-id="book_checkbox"
                              :checked="booksSelected[listitem.bookId]"
                              @change="toggle(listitem.bookId)"
                              :class="{'no-results': listitem.count === 0}">
                      <p :dir="hebrew ? 'ltr': 'rtl'"
                         class="f gray check-text"
                         :class="{'no-results': listitem.count === 0}"
                        ><span class="text-numbers">({{updating ? '' : listitem.count}}<i
                          v-if="updating" class="fa fa-circle-o-notch fa-spin"></i>)</span> {{listitem.hebrewBookName[2]}}</p>
                    </Checkbox>
                  </li>
                </ul>
              </div>
            </transition>
          </li>
        </ul>
      </div>
    </transition>    
    <div class="retry text-muted px-3" v-if="booksFailed || updateBooksFailed">
      <div>{{hebrew ? 'אופס יש לנו בעיה!' : 'Book search failed'}}</div>
      <b-btn variant="link" class="text-muted bg-transparent border-0 p-0" @click="booksFailed ? loadData() : updateData()">
        <i class="fas fa-redo-alt"></i>
        <span class="text-underline mx-1" style="font-size:16px;">{{hebrew ? 'נסו שוב' : 'Try again'}}</span>
      </b-btn>
    </div>
  </li>
</template>

<script>
import Checkbox from './Checkbox'
// import ExpandableList from './ExpandableList'
import BooksBase from './BooksBase'
import SelectAll from './SelectAll'

export default {
  name: 'BooksDesktop',
  components: { SelectAll, /* ExpandableList, */ Checkbox },
  props: ['expanded'],
  mixins: [BooksBase],
  methods: {
    bookGroupCheck (group) {
      const toBeChecked = !this.allBooksInGroupChecked(group)
      group.forEach(book => { this.booksSelected[book.bookId] = toBeChecked })
      this.setState()
    }
  }
}
</script>

<style scoped src="../../assets/style/common-desktop-sidebar.css"></style>
<style scoped>
  .slide-list {
    padding: 0;
    list-style: none;
  }
  .slide-li {
    margin-bottom: 15px;
  }
</style>
