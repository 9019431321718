<!-- ExpandableList shows a list with 5 items, and shows a "More" button to expand to show the rest -->
<!-- When you use it, it looks something like this:
<ExpandableList :list-items="yourListGoesHere">
    <template slot-scope="{ listitem }">
        Show some property from your list item: {{ listitem.yourProperty }}
    </template>
</ExpandableList>
-->

<template>
    <ul class="slide-list" ref="ulElement">
        <li class="slide-li" v-for="(listitem, index) in shownItems" :key="index">
            <slot :listitem="listitem"></slot>
        </li>
        <MoreButton v-if="moreAvailable" :expanded="expanded" @toggle="toggle"/>
    </ul>
</template>

<script>
import MoreButton from './MoreButton'

export default {
  name: 'ExpandableList',
  components: {MoreButton},
  props: ['listItems'],
  data: function () {
    return {
      expanded: false
    }
  },
  computed: {
    shownItems () {
      return this.expanded ? this.listItems : this.listItems.slice(0, 5)
    },
    moreAvailable () {
      return this.listItems.length > 5
    }
  },
  methods: {
    toggle () {
      this.expanded = !this.expanded
      if (!this.expanded) {
        // correct scroll position if necessary, since items just disappeared
        const top = this.$refs.ulElement.getBoundingClientRect().top
        if (top < 0) {
          const newPosition = Math.max(window.scrollY + top - 100, 0)
          window.scroll(0, newPosition)
        }
      }
    }
  }
}
</script>

<style scoped>
  .slide-list {
    padding: 0;
    list-style: none;
  }
  .slide-li {
    margin-bottom: 15px;
  }
</style>
